import React, { useCallback, useRef } from 'react';
import cn from 'classnames';

import { convertVwToPx } from 'src/common/utils/helpers';

import { defaultProps, visualCompareDataSelector } from './constants';
import { getClientCoords } from './helpers';

import css from './VisualCompare.scss';

type Props = {
	isEditableMode: boolean;
	spotlightSize?: string;
	visualCompareSrcA: () => React.ReactNode;
	visualCompareSrcB: () => React.ReactNode;
};

export const SpotlightMode = (props: Props) => {
	const {
		visualCompareSrcA,
		isEditableMode,
		visualCompareSrcB,
		spotlightSize = defaultProps.visualCompareSpotlightSize,
	} = props;

	const containerRef = useRef<HTMLDivElement>(null);
	const contentBWrapperRef = useRef<HTMLDivElement>(null);
	const contentBContainerRef = useRef<HTMLDivElement>(null);
	const outPosition = 'max(100vw, 100vh)';

	const onMouseEvent = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
			e.stopPropagation();

			const container = containerRef?.current;
			const contentBWrapper = contentBWrapperRef.current;
			const contentBContainer = contentBContainerRef.current;

			if (!container || !contentBWrapper || !contentBContainer) return;

			const containerRect = container.getBoundingClientRect();
			const targetSpotlightSize = parseSpotlightSize(spotlightSize);

			const onMouseMoveEvent = (event: MouseEvent | TouchEvent) => {
				const { clientX, clientY } = getClientCoords(event);
				let newX = clientX - containerRect.left - targetSpotlightSize / 2;
				let newY = clientY - containerRect.top - targetSpotlightSize / 2;

				newX = Math.max(-targetSpotlightSize, Math.min(newX, containerRect.width));
				newY = Math.max(-targetSpotlightSize, Math.min(newY, containerRect.height));

				contentBContainer.style.setProperty('--mask-position', `${newX}px ${newY}px`);
				contentBContainer.style.setProperty('--mask-size', spotlightSize);
			};

			const onMouseLeaveEvent = () => {
				document.removeEventListener('mousemove', onMouseMoveEvent);
				document.removeEventListener('touchmove', onMouseMoveEvent);
				container.removeEventListener('mouseleave', onMouseLeaveEvent);

				contentBContainer.style.setProperty('--mask-position', outPosition);
			};

			document.addEventListener('mousemove', onMouseMoveEvent);
			document.addEventListener('touchmove', onMouseMoveEvent);
			container.addEventListener('mouseleave', onMouseLeaveEvent);
		},
		[spotlightSize]
	);

	return (
		<div
			className={cn(css.visualCompareInner, css.spotlight)}
			onMouseEnter={isEditableMode ? undefined : onMouseEvent}
			onTouchStart={isEditableMode ? undefined : onMouseEvent}
			ref={containerRef}
		>
			<div className={css.contentWrapper}>
				<div className={css.contentAContainer}>{visualCompareSrcA()}</div>
				<div className={css.contentBWrapper} ref={contentBWrapperRef}>
					<div
						className={css.contentBContainer}
						ref={contentBContainerRef}
						data-selector={visualCompareDataSelector.spotlight}
						style={
							{
								'--mask-position': isEditableMode ? '50% 50%' : outPosition,
								'--mask-size': defaultProps.visualCompareSpotlightSize,
								'--mask-image': defaultProps.visualCompareSpotlightBlur,
							} as React.CSSProperties
						}
					>
						{visualCompareSrcB()}
					</div>
				</div>
			</div>
		</div>
	);
};

function parseSpotlightSize(spotlightSizeString: string): number {
	const value = spotlightSizeString.trim().split(' ')[0];
	const resultValue = convertVwToPx(value);

	if (Number.isFinite(resultValue)) return Math.round(resultValue as number);

	return parseSpotlightSize(defaultProps.visualCompareSpotlightSize);
}
