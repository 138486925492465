export enum VariableType {
	// === common ===
	cardName = 'cardName',
	date = 'date',
	time = 'time',
	datePublished = 'datePublished',
	score = 'score',
	scorePercent = 'scorePercent',
	cardsVisited = 'cardsVisited',
	cardsScored = 'cardsScored', // previously "answers"
	cardsAnswered = 'cardsAnswered',
	totalAnswerCards = 'totalAnswerCards',
	totalScoreCards = 'totalScoreCards',
	correct = 'correct',
	incorrect = 'incorrect',
	answersMax = 'answersMax',
	answersMin = 'answersMin',
	// score received for answers in current card only
	answersScore = 'answersScore',
	// current answer index in the Sortable Trivia card
	answerIndex = 'answerIndex',
	// selected in current card only
	answersSelected = 'answersSelected',
	// amount of answers selected in story
	storyAnswersSelected = 'storyAnswersSelected',
	// === generic ===
	fieldValue = 'field/:cardId/:componentId',
	characterScore = 'character/s/:characterId',
	characterScorePercent = 'character/sp/:characterId',
	urlParam = 'urlParam/:param',
	// === user custom variables ===
	spreadsheetRange = 'sheet/:rangeId',
	// navigation card (actions)
	gptActionOutput = '/action/gpt/:actionId/:outputKey',
	// card answer
	cardAnswer = 'cardAnswer/:cardId',
}

export enum VariableCategory {
	general = 'General',
	numbering = 'Numbering',
	scoring = 'Scoring',
	leadsForm = 'Leads form',
	characters = 'Characters',
	multipleAnswers = 'Multiple Answers',
	urlParams = 'URL parameters',
	spreadsheet = 'Spreadsheet',
	cardAnswers = 'Card Answers',
	custom = 'Custom',
}

type BaseVariable = {
	// Unique variable identifier to be stored in JSON. All logic is based on it. Required. Try not to change it.
	name: string;
	// Full title. Only used for the UI. Can be changed at any time.
	title: string;
	// Full description. Only used for the UI. Can be changed at any time.
	description: string;
	// DOM node text that will be replaced by the actual value when previewed/published.
	// Only used for the UI. Can be changed at any time.
	placeholder: string;
	// Variable type for ordering
	type: VariableType;
	// Variable category for grouping
	category: VariableCategory;
	// [x: string]: any;
};

interface FieldVariable extends BaseVariable {
	type: VariableType.fieldValue;
}

interface CharacterVariable extends BaseVariable {
	type: VariableType.characterScore | VariableType.characterScorePercent;
}

export interface CustomVariable extends BaseVariable {
	value: string; // static assigned value
}

interface SourceVariable extends BaseVariable {
	type: VariableType.spreadsheetRange;
}

export type VariableDataType = BaseVariable | FieldVariable | CharacterVariable | CustomVariable | SourceVariable;

export type Variables = VariableDataType[];
