import React, { Component, useEffect, useRef } from 'react';
import cn from 'classnames';
import type { ToolbarChildrenProps } from '@draft-js-plugins/inline-toolbar/lib/components/Toolbar';
import ColorPicker from 'admin/components/pages/Story/CardEditor/Inspector/PropField/ColorPicker';
import StorySwatches from 'admin/components/pages/Story/CardEditor/Inspector/PropField/StorySwatches';
import css from './Picker.scss';

type Props = {
	color: string;
	toggleColor: (color: string) => void;
	className?: string;
} & ToolbarChildrenProps;

type State = {
	color: string;
};

type PickerToolProps = {
	color: string;
	onChange: (color: string) => void;
	onOverrideContent: ToolbarChildrenProps['onOverrideContent'];
};

const PickerTool = (props: PickerToolProps) => {
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		function onClickOutside(event) {
			if (!ref.current?.contains(event.target)) props.onOverrideContent(undefined);
		}

		document.addEventListener('click', onClickOutside, { capture: true });

		return () => {
			document.removeEventListener('click', onClickOutside, { capture: true });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={cn(css.picker, { [css.minimized]: window.innerHeight < 500 })} ref={ref}>
			<StorySwatches route="client">
				{swatches => (
					<ColorPicker
						rgba={props.color}
						onChange={props.onChange}
						theme={{ root: css.pickerRoot, area: css.pickerArea, inputs: css.pickerInputs }}
						swatches={swatches}
					/>
				)}
			</StorySwatches>
		</div>
	);
};

class Picker extends Component<Props, State> {
	state = {
		color: `rgba(0, 0, 0, 1)`,
	};

	rootRef = React.createRef<HTMLDivElement>();

	get color() {
		return this.props.color || this.state.color;
	}

	handleClick = () => {
		const content = () => (
			<PickerTool
				onChange={this.props.toggleColor}
				color={this.color}
				onOverrideContent={this.props.onOverrideContent}
			/>
		);
		this.props.onOverrideContent(content);
	};

	render() {
		return (
			<div ref={this.rootRef}>
				<button type="button" className={this.props.className} onClick={this.handleClick}>
					<div className={css.color} style={{ background: this.color }} />
				</button>
			</div>
		);
	}
}

export default Picker;
