import React, { useState } from 'react';
import { Flex } from 'antd';
import type { OverlayTriggersProp, ComponentTypes } from 'types/story';
import { COMPONENT_TYPE } from 'common/constants';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import type { InspectorContextType } from 'admin/components/pages/Story/CardEditor/Inspector/InspectorContext';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { MODAL } from 'admin/constants/common';
import Text from 'admin/components/common/Text';
import { useAdminSelector } from 'admin/reducers';
import Button from 'admin/components/common/Button';
import { Select } from 'admin/components/common/Form';
import { useTheme } from 'admin/components/common/Theme';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { selectEditableElement } from 'admin/reducers/card-editor-extra/selectors';
import { getPFCompPropName } from 'admin/components/pages/Story/CardEditor/Inspector/PropField';
import {
	selectEditableCardElements,
	selectEditableStoryElements,
	selectEditableSymbols,
} from 'admin/reducers/card-editor/selectors';
import { componentWalkFull } from 'utils/blocks/component-walk-full';
import { useDidMount } from 'common/components/useDidMount';
import { Icon } from 'admin/components/common/Icon';
import css from './EditOverlayTrigger.scss';

const useComponentsList = () => {
	const symbols = useAdminSelector(selectEditableSymbols);
	const elements = useAdminSelector(selectEditableCardElements)!;
	const storyElements = useAdminSelector(selectEditableStoryElements);
	const [components, setComponents] = useState<Map<string, { label: string; value: string }>>(new Map());

	useDidMount(() => {
		const map: typeof components = new Map();

		const whiteList = new Set<ComponentTypes>([
			COMPONENT_TYPE.TEXT,
			COMPONENT_TYPE.IMG,
			COMPONENT_TYPE.BUTTON,
			COMPONENT_TYPE.BOX,
			COMPONENT_TYPE.SHAPE,
			COMPONENT_TYPE.VIDEO,
			COMPONENT_TYPE.TIMER,
			COMPONENT_TYPE.LOTTIE,
		]);

		componentWalkFull({
			symbols,
			elements,
			storyElements,
			callback: ({ component, path }) => {
				if (whiteList.has(component.type))
					map.set(component._id, { label: component.uiConfig.editorProps.name, value: component._id });
			},
		});

		setComponents(map);
	});

	return components;
};

export type EditOverlaysTriggerModalData = {
	onSave: InspectorContextType['onUpdate'];
};

type ModalProps = ModalManagerProvidedProps<MODAL.EDIT_OVERLAY_TRIGGERS>;

const ModalContent: React.FC<ModalProps> = props => {
	const element = useAdminSelector(selectEditableElement);
	const currentTriggers = element.uiConfig.componentProps.overlayTriggers;

	const { theme } = useTheme();
	const componentsList = useComponentsList();

	const [showTriggers, setShowTriggers] = useState<OverlayTriggersProp['show']>(currentTriggers?.show ?? []);
	const [hideTriggers, setHideTriggers] = useState<OverlayTriggersProp['hide']>(currentTriggers?.hide ?? []);

	const onAddClick = (type: 'show' | 'hide', value: string) => {
		const action = type === 'show' ? setShowTriggers : setHideTriggers;
		action(prev => [value, ...prev]);
	};

	const renderGroup = (type: 'show' | 'hide') => {
		const isShow = type === 'show';
		const onDelete = isShow ? setShowTriggers : setHideTriggers;
		const items = (isShow ? showTriggers : hideTriggers).filter(item => componentsList.has(item));

		return (
			<Flex vertical flex="1">
				<Flex justify="space-between" align="end" gap="middle">
					<Select
						className={css.select}
						label={{ children: `Trigger to set ${isShow ? 'visible' : 'hidden'}`, theme }}
						options={Array.from(componentsList.values()).filter(opt => items.indexOf(opt.value) === -1)}
						theme={theme}
						eventListeners={{ onChange: _value => onAddClick(type, _value) }}
						menuPlacement="top"
						maxMenuHeight={200}
						isSearchable
					/>
				</Flex>

				{items.length > 0 && (
					<div className={css.selectedList}>
						{items.map((item, idx) => (
							<Flex
								key={`overlay-trigger-${item}-${type}`}
								justify="space-between"
								gap="middle"
								className={css.selectedItem}
							>
								<Text ellipsis size="label">
									{`${idx + 1}. ${componentsList.get(item)?.label}`}
								</Text>
								<Button
									view="label-only"
									shape="circle"
									size="tiny"
									onClick={() => onDelete(arr => arr.filter(_item => _item !== item))}
								>
									<Icon type="x-rounded" color="var(--ra-color-error)" width={16} />
								</Button>
							</Flex>
						))}
					</div>
				)}
			</Flex>
		);
	};

	const handleSave = () => {
		// filter to drop triggers that refers to component that already does not exist
		const filter = (items: string[]) => items.filter(item => componentsList.has(item));

		(props.data satisfies EditOverlaysTriggerModalData).onSave({
			name: getPFCompPropName(UNIFORM_PROPS.overlayTriggers, 'uniform')({ path: element.path }),
			value: { show: filter(showTriggers), hide: filter(hideTriggers) } satisfies OverlayTriggersProp,
		});

		props.close();
	};

	return (
		<>
			<ModalBody>
				<Flex gap="large" justify="stretch">
					{renderGroup('show')}
					{renderGroup('hide')}
				</Flex>
			</ModalBody>
			<ModalFooter>
				<Button view="primary" onClick={handleSave}>
					Save
				</Button>
				<Button view="secondary-gray" onClick={props.close}>
					Discard
				</Button>
			</ModalFooter>
		</>
	);
};

const EditOverlayTriggerModal: React.FC<ModalProps> = props => {
	return (
		<Modal theme="dark" maskColor="black" open={props.open} destroyOnClose width={640} transitionName="">
			<ModalContent {...props} />
		</Modal>
	);
};

export default EditOverlayTriggerModal;
