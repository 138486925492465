/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import cn from 'classnames';

import type { BBCommonProps } from 'types/story';
import { VIDEO_PARAMS, replaceUrlHostname } from 'utils/assets';
import ChangeIcon from 'common/components/Icon/Change';
import VideoPlayer from 'common/components/VideoPlayer';
import { IFRAME_ACTIONS, transmitTo } from 'common/utils/iframe-tunnel';
import { useClientSelector } from 'client/reducers';
import { selectStorycardsDomain, selectStoryId } from 'client/reducers/story/selectors';
import { SelectionToolbarPortal } from 'client/components/common/SelectionHint/Toolbar';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { defaultProps } from './constants';

import css from './Video.scss';

type Props = BBCommonProps;

const Video = (props: Props) => {
	const storycardsDomain = useClientSelector(selectStorycardsDomain);
	const storyId = useClientSelector(selectStoryId);

	const { editableModeProps: EMP, stateAttrs, isEditableMode, uiConfig } = props;
	const {
		videoSrc,
		videoPoster,
		muted = defaultProps.muted,
		loop = defaultProps.loop,
		controls = defaultProps.controls,
		autoplay = defaultProps.autoplay,
		autopause = defaultProps.autopause,
		soundControl = defaultProps.soundControl,
		thumbnailsControl,
		castControl,
		fullscreenControl = defaultProps.fullscreenControl,
		playbackSpeedControl = defaultProps.playbackSpeedControl,
		qualityControl,
		pipModeControl = defaultProps.pipModeControl,
		skipControl = defaultProps.skipControl,
	} = uiConfig.componentProps.other;
	const isHidden = uiConfig.componentProps.styles.display === 'none';
	// const src = videoSrc === 'none' || !videoSrc ? '' : prependUrlProtocol(videoSrc, 'https');
	const { src: videoSrcUrl, transcodingId } = processVideoSrc(videoSrc);
	const src = replaceUrlHostname(videoSrcUrl?.toString(), storycardsDomain);
	const poster = replaceUrlHostname(videoPoster, storycardsDomain);

	return (
		<div
			{...uiConfig.nodeProps}
			{...stateAttrs}
			{...props.eventListeners}
			{...EMP?.nodeProps}
			style={uiConfig.nodeProps?.style}
			className={cn(css.video, uiConfig.nodeProps.className, EMP?.nodeProps?.className)}
			ref={props.containerRef as React.RefObject<HTMLDivElement>}
		>
			{!isHidden && src ? (
				<VideoPlayer
					key={`${props._id}-${src}-${poster}`} // key is important to update preview on src/poster change
					title={uiConfig.componentProps.title}
					isEditableMode={isEditableMode}
					storyId={storyId}
					transcodingId={transcodingId}
					id={props._id}
					src={src}
					poster={poster}
					controls={isEditableMode ? false : controls}
					muted={isEditableMode ? true : muted}
					loop={isEditableMode ? false : loop}
					autoplay={isEditableMode ? false : autoplay}
					autopause={isEditableMode ? false : Boolean(autopause)}
					soundControl={isEditableMode ? false : Boolean(soundControl)}
					thumbnailsControl={isEditableMode ? false : Boolean(thumbnailsControl)}
					castControl={isEditableMode ? false : Boolean(castControl)}
					fullscreenControl={isEditableMode ? false : Boolean(fullscreenControl)}
					playbackSpeedControl={isEditableMode ? false : Boolean(playbackSpeedControl)}
					qualityControl={isEditableMode ? false : Boolean(qualityControl)}
					pipModeControl={isEditableMode ? false : Boolean(pipModeControl)}
					skipControl={isEditableMode ? false : Boolean(skipControl)}
					playsinline
				/>
			) : (
				<div className={css.empty} data-text="No video" />
			)}

			{props.isEditableMode && !isHidden && (
				<SelectionToolbarPortal nodeID={uiConfig.nodeProps.id}>
					<button
						title="Repalce video source"
						type="button"
						onClick={() => {
							// Flow: send msg to admin -> read msg -> emit inspector event -> call event listener ->
							// -> call file upload
							transmitTo({
								id: 'Video',
								target: 'admin',
								action: IFRAME_ACTIONS.UPLOAD_MEDIA,
							});
						}}
					>
						<ChangeIcon width={11} />
					</button>
				</SelectionToolbarPortal>
			)}
		</div>
	);
};

function processVideoSrc(src?: string) {
	try {
		const result = src ? new URL(src) : null;
		const transcodingId = result?.searchParams.get(VIDEO_PARAMS.transcodingId);

		result?.searchParams.delete(VIDEO_PARAMS.transcodingId);

		return { src: result?.toString(), transcodingId };
	} catch {
		return {
			src: null,
			transcodingId: null,
		};
	}
}

export default withCardTransitionContext(Video);
