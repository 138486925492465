import React, { useState, useRef, useCallback } from 'react';
import { Dropdown, MenuProps, DropdownProps } from 'antd';
import type { ItemType, MenuItemGroupType, MenuItemType } from 'antd/es/menu/interface';
import cn from 'classnames';

import { getClientFrame } from 'common/utils/iframe-tunnel';
import { useClickOutside } from 'common/components/useClickOutside';
import { useTheme, THEME } from 'admin/components/common/Theme';
import { Icon } from 'admin/components/common/Icon';
import css from './Dropdown.scss';

interface Props extends DropdownProps {
	menu: MenuProps;
	preventCloseOnItemClick?: boolean;
	disableAnimation?: boolean;
	caret?: boolean;
	dropdownRef?: React.RefObject<HTMLDivElement>;
	searchable?: boolean;
	onItemClick?: (item: MenuItemType) => void;
}

const DropdownComponent: React.FC<Props> = ({
	children,
	overlayClassName,
	preventCloseOnItemClick,
	caret = false,
	className,
	searchable,
	onItemClick,
	dropdownRef,
	...props
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const { theme } = useTheme();
	const [open, setOpen] = useState(false);

	const getMenuParams = (filter: string): GetMenuParams => ({
		filterFn: item => {
			const labelLower = (isOfType<MenuItemType>(item) && item.label?.toString().toLowerCase()) || '';

			return filter.split(' ').some(word => labelLower.includes(word));
		},
		iterateeFn: item => {
			if (item)
				return {
					...item,
					...(isOfType<MenuItemType>(item) && {
						onClick: info => {
							item.onClick?.(info);
							onItemClick?.(item);
						},
					}),
				};
			return item;
		},
	});
	const [menuItems, setMenuItems] = React.useState(() => getMenu(props.menu.items ?? [], getMenuParams('')));
	const [isPending, startTransition] = React.useTransition();
	const searchableProps: DropdownProps = searchable
		? {
				menu: { ...props.menu, items: menuItems },
				dropdownRender: menus => {
					const filterItems = (filter: string) => {
						startTransition(() => {
							setMenuItems(getMenu(props.menu.items ?? [], getMenuParams(filter)));
						});
					};

					return (
						<div className={css.menu} ref={dropdownRef}>
							<form
								className={css.searchForm}
								onSubmit={e => {
									e.preventDefault();
									filterItems(e.currentTarget.search.value);
								}}
							>
								<input
									type="text"
									name="search"
									placeholder="Search by action name"
									className={css.searchInput}
									onChange={e => filterItems(e.currentTarget.value)}
									ref={node => setTimeout(() => node?.focus(), 100)}
								/>
								<button type="submit" className={css.searchSubmit}>
									<Icon type="search" />
								</button>
							</form>
							<div className={css.divider} />
							<div className={cn(css.menuItems, { [css.pending]: isPending })}>
								{menuItems.length ? menus : <div className={css.noResults}>No results found</div>}
							</div>
						</div>
					);
				},
			}
		: {};
	const onOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
		const shouldOpen = info.source === 'trigger' || nextOpen;
		const shouldClose = info.source === 'menu' || !nextOpen;

		if (shouldOpen || (shouldClose && !preventCloseOnItemClick)) {
			setOpen(nextOpen);
		}
	};

	const handleClickOutside = useCallback((event: MouseEvent) => {
		if (event.currentTarget === getClientFrame().contentDocument) {
			setOpen(false);
		}
	}, []);

	useClickOutside(ref, handleClickOutside, {
		extraDoc: getClientFrame().contentDocument,
	});

	return (
		<Dropdown
			overlayClassName={cn(css.dropdownO, overlayClassName, {
				[css.noAnimation]: props.disableAnimation,
				[css.dark]: theme === THEME.DARK,
				[css.light]: theme === THEME.LIGHT,
			})}
			className={cn(className, { [css.caret]: caret })}
			{...props}
			{...searchableProps}
			open={open}
			onOpenChange={onOpenChange}
		>
			<div ref={ref}>{children}</div>
		</Dropdown>
	);
};

type GetMenuParams = { filterFn?: (item: ItemType) => boolean; iterateeFn?: (item: ItemType) => ItemType };

const getMenu = (items: NonNullable<MenuProps['items']>, params?: GetMenuParams) => {
	const { filterFn = () => true, iterateeFn = item => item } = params ?? ({} as NonNullable<typeof params>);
	const defaultFilterFn = (item: ItemType) => {
		return !(isOfType<MenuItemType>(item) && item.disabled);
	};
	const filteredItems = items.reduce(
		(acc, item) => {
			if (isOfType<MenuItemGroupType>(item) && item.children) {
				const children = getMenu(item.children, params);

				if (children.length) {
					acc.push({ ...item, children });
				}

				return acc;
			}

			if (defaultFilterFn(item) && filterFn(item)) {
				acc.push(iterateeFn(item));
			}

			return acc;
		},
		[] as NonNullable<MenuProps['items']>
	);

	return filteredItems;
};

const isOfType = <T extends ItemType>(item: ItemType): item is T => {
	switch (true) {
		// MenuItemGroupType:
		case !!item && 'type' in item && item.type === 'group':
			return true;
		// MenuDividerType:
		case !!item && 'type' in item && item.type === 'divider':
			return true;
		// MenuItemType:
		case !!item && 'type' in item === false && 'children' in item === false:
			return true;
		// SubMenuType:
		case !!item && 'type' in item === false && 'children' in item === true:
			return true;

		default:
			return false;
	}
};

export default DropdownComponent;
