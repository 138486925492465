import React from 'react';
import { Field, arrayPush, arrayRemove, change, getFormValues } from 'redux-form';
import cn from 'classnames';

import { CardData } from 'types/story';
import { getAvailableVariables } from 'common/utils/variables/variables';
import { FORM_MODEL } from 'admin/constants/common';
import { Select, TextField } from 'admin/components/common/Form';
import { Context as ThemeContext } from 'admin/components/common/Theme';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';
import { useAdminDispatch, useAdminSelector } from 'admin/reducers';
import { selectEditableStory } from 'admin/reducers/story-editor/selectors';
import t from 'utils/translate';
import { TogglePure } from 'src/routes/admin/components/common/Form/Toggle';
import { EditableText } from './EditableText';
import { ChildrenFormProp, fields } from '../ActionForm';
import { createOutputVariable } from './helpers';
import { MODEL } from './constants';
import css from './GptTextResponseAction.scss';

type Props = {
	card: CardData;
	outputs: NonNullable<ChildrenFormProp['formData']['outputs']>;
};

const GptTextResponseEvents = (props: Props) => {
	const { card, outputs } = props;
	const dispatch = useAdminDispatch();
	const story = useAdminSelector(selectEditableStory)!;
	const [variables] = React.useState(() => getAvailableVariables({ story, card }));
	const formValues = useAdminSelector(getFormValues(FORM_MODEL.EDIT_CARD_ACTION)) as ChildrenFormProp['formData'];
	const [isSystemPrefsVisible, setIsSystemPrefsVisible] = React.useState(formValues?.system !== '');
	const context = React.useContext(ThemeContext);
	const modelOptions = [MODEL.GPT_4, MODEL.GEMINI].map(value => ({ value, label: value }));

	const onAddOutputBtnClick = () => {
		const path = fields.outputsVariable(0).split('.0.')[0];
		const newRequest: Props['outputs'][number] = createOutputVariable();

		dispatch(arrayPush(FORM_MODEL.EDIT_CARD_ACTION, path, newRequest));
	};

	const onRemoveResponseBtnClick = (index: number) => {
		const path = fields.outputsVariable(index).split(`.${index}.`)[0];
		dispatch(arrayRemove(FORM_MODEL.EDIT_CARD_ACTION, path, index));
	};

	const onSystemToggleChange = (checked: boolean) => {
		if (checked === false) {
			dispatch(change(FORM_MODEL.EDIT_CARD_ACTION, fields.system, ''));
		}

		setIsSystemPrefsVisible(checked);
	};

	return (
		<div className={cn(css.gptTextResponseAction)}>
			<div className={css.inputWrapper}>
				<Text className={css.label} size="label" weight={Text.weight.bold} text="Who am I?" />
				<EditableText variables={variables} name={fields.template} placeholder="Who am I?" autofocus />
			</div>
			<div className={css.inputWrapper}>
				<Field
					name={fields.model}
					component={Select}
					menuPosition="fixed"
					label={{ children: 'Model' }}
					options={modelOptions}
					theme={context?.theme}
				/>
			</div>
			<div className={css.inputWrapper}>
				<TogglePure
					className={css.labelWithToggle}
					label={{ children: 'System' }}
					switchProps={{ onChange: onSystemToggleChange }}
					checked={isSystemPrefsVisible}
				/>
				{isSystemPrefsVisible && (
					<EditableText variables={variables} name={fields.system} placeholder="System preferences" />
				)}
			</div>

			<div className={css.divider} />
			<Text className={css.heading} size="body" weight={Text.weight.bold} text="Response variables" />
			<div className={css.outputs}>
				{outputs.map((output, index) => {
					const shouldRenderTexts = index === outputs.length - 1;
					const shouldRenderRemoveBtn = outputs.length > 1;

					return (
						<div className={css.outputsItem} key={output.id}>
							<Field
								name={fields.outputsVariable(index)}
								component={TextField}
								label="Variable name"
								isRequired
								props={{
									theme: context?.theme,
									isLabelUppercase: false,
									placeholder: t('story.settings.actions.outputs.variable.placeholder'),
								}}
							/>
							{shouldRenderTexts && (
								<Text
									className={css.text}
									size="description"
									weight={Text.weight.normal}
									text={t('story.settings.actions.outputs.variable.description')}
								/>
							)}
							<div className={css.inputWrapper}>
								<Text
									className={css.label}
									size="label"
									weight={Text.weight.bold}
									text="Response definition"
								/>
								<EditableText
									variables={variables}
									name={fields.outputsDefinition(index)}
									placeholder="Response definition"
								/>
							</div>
							{shouldRenderTexts && (
								<Text
									className={css.text}
									size="description"
									weight={Text.weight.normal}
									text={t('story.settings.actions.outputs.definition.description')}
								/>
							)}
							{shouldRenderRemoveBtn && (
								<Button
									className={css.removeBtn}
									title="Remove"
									view="empty"
									color="danger-ds"
									shape="circle"
									size="tiny"
									onClick={() => onRemoveResponseBtnClick(index)}
								>
									<Icon className={css.eventRemoveIcon} type="minus-rounded" />
								</Button>
							)}
						</div>
					);
				})}
			</div>
			<Button className={css.addBtn} view="secondary-gray" onClick={onAddOutputBtnClick}>
				<div className={css.addBtnLabel}>
					<Icon className={css.addBtnLabelIcon} type="plus-rounded" />
					<span>Add Another Response</span>
				</div>
			</Button>
		</div>
	);
};

export { GptTextResponseEvents };
export default GptTextResponseEvents;
