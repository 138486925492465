import React from 'react';

import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Grid, Column } from 'admin/components/common/Grid';
import { ModalBody, ModalFooter } from 'admin/components/common/Modal';
import t from 'utils/translate';
import { Context, THEME } from 'admin/components/common/Theme';
import { CardData } from 'src/types/story';
import GptTextResponseAction from './GptTextResponseAction';
import { ChildrenFormProp } from './ActionForm';
import { ModalType } from './modal-type';
import css from './EditCardModal.scss';

type Props = {
	editType: ModalType;
	onDiscard?: () => void;
	form: ChildrenFormProp;
	card: CardData;
};

const Content: React.FC<Props> = ({ editType, onDiscard, form: { invalid, formData, change }, card }) => {
	const context = React.useContext(Context);
	const columnProps: { colStart: '1' | '3'; colSpan: '8' | '12' } =
		context?.theme === THEME.DARK ? { colStart: '1', colSpan: '12' } : { colStart: '3', colSpan: '8' };

	return (
		<>
			<ModalBody className={css.body}>
				<Grid columns="12" columnGap={Grid.gap.small} rowGap={Grid.gap.medium} className={css.bodyGrid}>
					{editType === ModalType.default ? (
						<Column {...columnProps}>
							<Text>Default edit action form</Text>
						</Column>
					) : editType === ModalType.editGptTextResponseNav ? (
						<Column {...columnProps}>
							<GptTextResponseAction card={card} outputs={formData.outputs} />
						</Column>
					) : null}
				</Grid>
			</ModalBody>
			<ModalFooter>
				<Column justifyContent="flex-start">
					<Button type="submit" theme={context?.theme} view="primary" smallText disabled={invalid}>
						{t('story.editCardModal.save')}
					</Button>
					<Button theme={context?.theme} view="empty" smallText onClick={onDiscard}>
						{t('story.editCardModal.discard')}
					</Button>
				</Column>
			</ModalFooter>
		</>
	);
};

export { Content };
