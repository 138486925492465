import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';

import t from 'utils/translate';
import { updateTeam } from 'admin/actions';
import { FORM_MODEL, MODAL } from 'admin/constants/common';
import type { AdminReducerState } from 'admin/reducers';
import { selectActiveOrganizationId, selectTeams } from 'admin/reducers/user/selectors';

import Text from 'admin/components/common/Text';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import Button from 'admin/components/common/Button';
import { TextField } from 'admin/components/common/Form';
import { TEAM_MAX_LEN } from 'admin/components/common/Form/validations';

import css from './RenameTeamModal.scss';

export type RenameTeamModalData = { teamId: string; teamName?: string };

type OwnProps = ConnectedProps<typeof connector> & ModalManagerProvidedProps<MODAL.RENAME_TEAM>;

type Values = { name: string };

const _t = (path: string, params?: Record<string, string | number>) =>
	t(`settings.pages.team.renameTeamModal.${path}`, params);

const RenameTeamModal: React.FC<OwnProps & InjectedFormProps<Values, OwnProps>> = props => {
	const { open, close, handleSubmit, pristine, submitting, data, organizationId } = props;

	const onFormSubmit = async (values: Values) => {
		await props.updateTeam({ teamId: data.teamId, organizationId, data: values });
		close();
	};

	const renderContent = () => {
		return (
			<ModalBody>
				<Field
					name="name"
					autoComplete="off"
					component={TextField}
					label={_t('fields.name.label')}
					isLabelUppercase={false}
					limit={{ max: TEAM_MAX_LEN }}
					isRequired
				/>
			</ModalBody>
		);
	};

	const renderFooter = () => {
		return (
			<ModalFooter>
				<Button
					loading={submitting}
					disabled={pristine || submitting}
					view="primary"
					type="submit"
					className={css.actionBtn}
				>
					{_t('submitBtnLabel')}
				</Button>

				<Button view="secondary" onClick={close}>
					{_t('cancelBtnLabel')}
				</Button>
			</ModalFooter>
		);
	};

	return (
		<Modal
			width={parseInt(css.modalWidth, 10)}
			open={open}
			destroyOnClose
			className={css.modal}
			title={<Text size={Text.size.subtitle} weight={Text.weight.semibold} compact text={_t('title')} />}
		>
			<form onSubmit={handleSubmit(onFormSubmit)}>
				{renderContent()}
				{renderFooter()}
			</form>
		</Modal>
	);
};

const RenameTeamForm = reduxForm<Values, OwnProps>({
	destroyOnUnmount: true,
	validate: (values, props) => {
		const errors: Partial<Values> = {};
		const isSameName =
			props.teams.findIndex(team => {
				if (team.id === props.data.teamId) return false;
				return team.name.toLowerCase() === values.name?.trim().toLowerCase();
			}) >= 0;

		if (!values.name) {
			errors.name = _t('fields.name.errors.required');
		}

		if (isSameName && !props.pristine) {
			errors.name = _t('fields.name.errors.alreadyExist');
		}

		return errors;
	},
	form: FORM_MODEL.RENAME_TEAM,
	enableReinitialize: true,
})(RenameTeamModal);

const mapState = (state: AdminReducerState, ownProps: ModalManagerProvidedProps<MODAL.RENAME_TEAM>) => {
	return {
		teams: selectTeams(state),
		organizationId: selectActiveOrganizationId(state),
		initialValues: { name: ownProps.data.teamName },
	};
};

const mapDispatchToProps = {
	updateTeam,
};

const connector = connect(mapState, mapDispatchToProps);

export default connector(RenameTeamForm);
