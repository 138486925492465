import { PARAMS } from 'common/constants';

export const ANY = '*';
export const ROOT = '/';
export const ADMIN_PREVIEW_PAGE = `${ROOT}preview.html`;
export const ERROR_PAGE = `${ROOT}404.html`;

const { STORIES_DOMAIN, STORY_BASE_PATH, STORY_JSON_PATH, STORY_METADATA_PATH } = window;

export const STORY_PATH = {
	domain: `/${STORIES_DOMAIN}`,
	base: `/${
		window.location.hostname === STORIES_DOMAIN
			? STORY_BASE_PATH
			: typeof STORY_BASE_PATH === 'string'
				? STORY_BASE_PATH.split('/')[1]
				: ''
	}`,
	story: `/${STORY_JSON_PATH}`,
	metadata: `/${STORY_METADATA_PATH}`,
};

export const CARD_PATH_SEGMENT = 'c';

export const CARD_PATH = `${STORY_PATH.base}/${CARD_PATH_SEGMENT}/:${PARAMS.CARD_ID}/`;
