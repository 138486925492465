import React, { useCallback, useEffect, useRef, ReactNode } from 'react';
import cn from 'classnames';

import { visualCompareDataSelector } from './constants';
import { getClientCoords } from './helpers';

import css from './VisualCompare.scss';

type Props = {
	isEditableMode: boolean;
	visualCompareSrcA: () => ReactNode;
	visualCompareSrcB: () => ReactNode;
};

export const DividerMode = (props: Props) => {
	const { visualCompareSrcA, visualCompareSrcB, isEditableMode } = props;

	const containerRef = useRef<HTMLDivElement>(null);
	const sliderPointRef = useRef<HTMLDivElement>(null);
	const dividerLineRef = useRef<HTMLDivElement>(null);
	const contentBWrapperRef = useRef<HTMLDivElement>(null);
	const contentBContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const container = containerRef?.current;
		const resize = new ResizeObserver(entries => {
			const entry = entries[0];
			const cr = entry.contentRect;

			const contentBWrapper = contentBWrapperRef.current;
			const contentBContainer = contentBContainerRef.current;
			const dividerLine = dividerLineRef.current;
			const sliderPoint = sliderPointRef.current;

			if (!contentBWrapper || !contentBContainer || !dividerLine || !sliderPoint) return;

			contentBWrapper.style.width = `${cr.width / 2}px`;
			contentBContainer.style.width = `${cr.width}px`;
			dividerLine.style.left = `${cr.width / 2}px`;
			sliderPoint.style.left = `${cr.width / 2}px`;
		});

		if (container) {
			// Observe one or multiple elements
			resize.observe(container);
		}

		return () => {
			if (container) {
				// Observe one or multiple elements
				resize.unobserve(container);
			}
		};
	}, []);

	const onMouseDownEvent = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
			e.stopPropagation();

			const container = containerRef?.current;
			const contentBWrapper = contentBWrapperRef.current;
			const dividerLine = dividerLineRef.current;
			const sliderPoint = sliderPointRef.current;
			const { clientX } = getClientCoords(e);

			if (!container || !contentBWrapper || !dividerLine || !sliderPoint) return;

			const startX = clientX - parseInt(contentBWrapper.style.width, 10);

			const onMouseMoveEvent = (event: MouseEvent | TouchEvent) => {
				if (!container) return;

				const containerRect = container.getBoundingClientRect();
				const { clientX: nativeClientX } = getClientCoords(event);
				let newX = nativeClientX - startX;

				newX = Math.max(0, Math.min(newX, containerRect.width));

				contentBWrapper.style.width = `${newX}px`;
				dividerLine.style.left = `${newX}px`;
				sliderPoint.style.left = `${newX}px`;
			};

			const onMouseUpEvent = () => {
				document.removeEventListener('mousemove', onMouseMoveEvent);
				document.removeEventListener('mouseup', onMouseUpEvent);

				document.removeEventListener('touchmove', onMouseMoveEvent);
				document.removeEventListener('touchend', onMouseUpEvent);
			};

			document.addEventListener('mousemove', onMouseMoveEvent);
			document.addEventListener('mouseup', onMouseUpEvent);

			document.addEventListener('touchmove', onMouseMoveEvent);
			document.addEventListener('touchend', onMouseUpEvent);
		},
		[]
	);

	return (
		<div className={css.visualCompareInner} ref={containerRef}>
			<div className={css.contentWrapper}>
				<div className={css.contentAContainer}>{visualCompareSrcA()}</div>
				<div className={css.contentBWrapper} ref={contentBWrapperRef}>
					<div className={css.contentBContainer} ref={contentBContainerRef}>
						{visualCompareSrcB()}
					</div>
				</div>
				<div
					className={css.dividerLine}
					ref={dividerLineRef}
					data-selector={visualCompareDataSelector.lineSize}
					style={{ position: 'absolute' }}
				>
					<div className={css.dividerLineBg} data-selector={visualCompareDataSelector.lineFill} />
				</div>
			</div>
			<div
				className={cn(css.sliderPoint, isEditableMode && css.noInteractive)}
				onMouseDown={!isEditableMode ? onMouseDownEvent : undefined}
				onTouchStart={!isEditableMode ? onMouseDownEvent : undefined}
				ref={sliderPointRef}
				data-selector={visualCompareDataSelector.handleSize}
			>
				<div
					className={css.sliderPointBg}
					data-selector={visualCompareDataSelector.handleFill}
					style={{ position: 'absolute' }}
				/>
			</div>
		</div>
	);
};
