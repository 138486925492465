/**
 * Parses a string representing a CSS unit and returns a tuple containing the numeric value and its unit.
 *
 * @param {string} str - The CSS value to parse.
 * @returns {[number, string]} A tuple where the first element is the numeric value and the second is the unit.
 *
 * @example
 * parseUnit('14vw'); // [14, 'vw']
 * parseUnit('10px 12px'); // [10, 'px']
 * parseUnit('15.86vw 15.86vw'); // [15.86, 'vw']
 * parseUnit('repeat(10, 1fr)'); // [1, 'fr']
 * parseUnit('100%'); // [100, '%']
 * parseUnit('-12.5rem'); // [-12.5, 'rem']
 * parseUnit('auto'); // [NaN, '']
 */
export function parseUnit(str: string): [number, string] {
	const match = str.trim().match(/^([-+]?[0-9]*\.?[0-9]+)([a-z%]*)/i);
	if (!match) return [NaN, ''];
	return [parseFloat(match[1]), match[2] || ''];
}
