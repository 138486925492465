import type { FC, ReactNode } from 'react';
import type { StorySymbols, BBModel } from 'types/story';
import { ListCollectionItemsInput, CmsFilterOperator } from 'types/cms';
import { useClientDispatch, useClientSelector } from 'client/reducers';
import { selectStoryId } from 'client/reducers/story/selectors';
import { CmsReferencesManager } from 'utils/cms/cms-references-manager';
import { useQueries } from '@tanstack/react-query';
import { listCollectionItemsQueryKey, listCollectionItemsQueryFn } from 'client/queries/cms/list-collection-items';
import cms from 'utils/cms';
import parseLocation from 'client/utils/parse-location';

const filterMapper = (itemId: string) => {
	const cmsDataId = parseLocation.getCmsDataId();

	if (itemId === cms.DATA_DYNAMIC_CARD && cmsDataId !== null) {
		return { field: 'id', operator: CmsFilterOperator.Equal, value: cmsDataId } as const;
	}
	if (itemId === cms.DATA_ANY || itemId === cms.DATA_DYNAMIC_CARD) {
		return { field: 'position', operator: CmsFilterOperator.Equal, value: 0 } as const;
	}
	return { field: 'id', operator: CmsFilterOperator.Equal, value: itemId } as const;
};

interface Props {
	elements: BBModel[];
	symbols?: StorySymbols;
	renderChildren: () => ReactNode;
}

const CmsItemsFetcher: FC<Props> = props => {
	const dispatch = useClientDispatch();
	const storyId = useClientSelector(selectStoryId)!;

	// Fetch collections and items for card
	const cmsReferencesManager = new CmsReferencesManager();
	cmsReferencesManager.collectComponentReferences(props.elements, props.symbols);
	const cmsReferences = cmsReferencesManager.getReferences();

	const queries = useQueries({
		queries: Object.entries(cmsReferences).map(([collectionId, items]) => {
			const queryParams: ListCollectionItemsInput = {
				collectionId,
				filter: {
					operator: 'OR',
					conditions: items.map(filterMapper),
				},
			};

			return {
				queryKey: listCollectionItemsQueryKey(queryParams),
				queryFn: () => listCollectionItemsQueryFn({ storyId, dispatch, ...queryParams }),
			};
		}),
	});

	return queries.some(query => query.isLoading) ? null : props.renderChildren();
};

export default CmsItemsFetcher;
