import React from 'react';
import cn from 'classnames';
import { NavLink } from 'admin/components/common/NavLink';
import Text from 'admin/components/common/Text';
import FullScreenMenu from 'admin/components/common/FullScreenMenu';
import t from 'utils/translate';
import * as routes from 'admin/constants/routes';
import css from './Navigation.scss';

type Link = {
	name: string;
	disabled?: boolean;
	path: string;
	type: 'internal' | 'external';
};

type Props = {
	links: {
		[key: string]: Link;
	};
	className?: string;
};

const Navigation: React.FC<Props> = ({ links, className = '' }) => {
	const items = Object.values(links).map(link => ({
		name: (
			<NavLink
				className={css.navLink}
				activeClassName={css.navLinkActive}
				to={link.path}
				key={link.path}
				text={link.name}
				type={link.type}
				textProps={FullScreenMenu.Item.preset.LARGE}
			/>
		),
		className: cn({ [css.disabled]: link.disabled }),
	}));

	return (
		<div className={className}>
			<nav className={css.nav}>
				<FullScreenMenu
					items={items}
					label={<Text size={Text.size.paragraph} className={css.navLabel} text="Menu" />}
					menuClassName={css.navMenu}
					labelBelow
					placement={FullScreenMenu.placement.VERTICAL}
				/>
			</nav>
		</div>
	);
};

export const links: {
	[key: string]: {
		[key: string]: Link;
	};
} = {
	main: {
		stories: { type: 'internal', name: t('header.stories'), path: routes.STORIES_PAGE },
		videos: { type: 'internal', name: t('header.videos'), path: routes.VIDEOS_PAGE },
		collections: { type: 'internal', name: t('header.cms'), path: routes.COLLECTIONS_PAGE },
		settings: { type: 'internal', name: t('header.settings'), path: routes.SETTINGS_PAGE },
		learn: {
			type: 'external',
			name: t('header.learn'),
			path: 'https://support.storycards.com',
		},
	},
	none: {},
};

export default Navigation;
