import React from 'react';

import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { MODAL } from 'admin/constants/common';
import t from 'utils/translate';

import css from './RemoveMemberFromWorkspaceModal.scss';

export type RemoveMemberFromWorkspaceModalData = { title: string; text: string; onOk: () => Promise<void> };

type Props = ModalManagerProvidedProps<MODAL.REMOVE_MEMBER_FROM_WORKSPACE>;

const RemoveMemberFromWorkspaceModal = (props: Props) => {
	const {
		open,
		data: { title, text, onOk },
		close,
	} = props;

	const onOkClick = React.useCallback(async () => {
		if (onOk) await onOk();
		close();
	}, [onOk, close]);

	return (
		<Modal
			width={parseInt(css.modalWidth, 10)}
			open={open}
			destroyOnClose
			className={css.modal}
			title={<Text size={Text.size.subtitle} weight={Text.weight.semibold} compact text={title} />}
		>
			<ModalBody>
				<Text size={Text.size.paragraph} text={text} />
			</ModalBody>

			<ModalFooter>
				<Button view="primary" onClick={close} className={css.actionBtn}>
					{t('membersComponent.removeFromWorkspaceModal.discard')}
				</Button>
				<Button view="secondary-danger" onClick={onOkClick} className={css.actionBtn}>
					{t('membersComponent.removeFromWorkspaceModal.confirm')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default RemoveMemberFromWorkspaceModal;
