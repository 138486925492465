import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { merge } from 'lodash';
import { useHistory } from 'react-router-dom';
import { destroy as formDestroy } from 'redux-form';

import t from 'common/utils/translate';
import { generatePath } from 'common/utils/route';
import Loader from 'common/components/Loader';
import AIStatusLoader from 'admin/components/common/Loading/AIStatus';
import { UpdateLatestStoryParams } from 'admin/actions/story/update-latest-story';
import { selectFeatureFlags, selectUserName, selectActiveOrganization } from 'admin/reducers/user/selectors';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import { getStoryTemplate } from 'admin/actions/story/templates';
import { generateStoryContent, patchAIStoryGenerator } from 'admin/actions/story/ai/generate-story-content';
import { injectGeneratedContent } from 'admin/actions/story/ai/inject-generated-story-content';
import { CSSTransition } from 'admin/components/common/Transition';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import FloatAboveModel from 'client/components/common/BuildingBlocks/FloatAbove/model';
import FloatBelowModel from 'client/components/common/BuildingBlocks/FloatBelow/model';
import { COMPONENT_STATES, DEFAULT_MEDIA_QUERY_PLATFORMS } from 'common/constants';
import { platformsConfig, segmentAnalytics } from 'admin/utils';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Modal } from 'admin/components/common/Modal';
import { STORY_FLOW_PAGE } from 'admin/constants/routes';
import { Icon } from 'admin/components/common/Icon';
import { FORM_MODEL, MODAL } from 'admin/constants/common';
import { createStory, updateLatestStory, updateStoryUrl } from 'admin/actions';
import Layout from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/Layout';
import TemplateClone from './TemplateClone';
import TemplateName from './TemplateName';
import TemplateType from './TemplateType';
import TemplateContent from './TemplateContent';
import Form, {
	CreateStoryFromScratchParams,
	CreateStoryFromTemplateParams,
	StoryContentType,
	FormValues,
} from './Form';
import TemplateGallery from './TemplateGallery';
import TemplatePreview from './TemplatePreview';
import { createStoryFormValuesSelector } from './helpers';

enum VIEW {
	// embed or standalone
	TEMPLATE_TYPE,
	// static or AI generated + language
	TEMPLATE_CONTENT,
	// list of templates
	TEMPLATE_GALLERY,
	// view selected template
	TEMPLATE_PREVIEW,
	// story name or topic to generate content with AI
	TEMPLATE_NAME,
	// clone story by url, superuser only
	TEMPLATE_CLONE,
}

const CreateStoryModal: React.FC<ModalManagerProvidedProps<MODAL.CREATE_STORY>> = ({ close, ...props }) => {
	// app state
	const dispatch = useAdminDispatch();
	const routerHistory = useHistory();
	const featureFlags = useAdminSelector(selectFeatureFlags);
	const username = useAdminSelector(selectUserName) ?? '';
	const formValues = useAdminSelector(createStoryFormValuesSelector) as FormValues;
	const activeOrganization = useAdminSelector(selectActiveOrganization)!;
	const orgIntegrations = activeOrganization.metadata?.integrations;
	const orgFonts = activeOrganization.metadata?.fonts;
	const isCreateAIStoryAllowed = featureFlags['create-ai-story'];
	const isStoryTemplatesAllowed = featureFlags['story-templates'];

	// component state
	const [view, setView] = useState(VIEW.TEMPLATE_TYPE);
	const [selectedTemplate, setSelectedTemplate] = useState<CreateStoryFromTemplateParams['template'] | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingStatus, setLoadingStatus] = useState<string | undefined>();
	const viewHistoryRef = useRef<VIEW[]>([]);

	useEffect(() => {
		document.querySelector('html')?.classList.add('no-scroll');
		return () => {
			document.querySelector('html')?.classList.remove('no-scroll');
		};
	}, []);

	useEffect(
		function resetSelectedTemplate() {
			if (selectedTemplate !== null && view !== VIEW.TEMPLATE_PREVIEW && view !== VIEW.TEMPLATE_NAME) {
				setSelectedTemplate(null);
			}
		},
		[selectedTemplate, view]
	);

	useEffect(() => {
		if (viewHistoryRef.current[viewHistoryRef.current.length - 1] !== view) {
			viewHistoryRef.current.push(view);
		}
	}, [view]);

	const actions = useMemo(
		() => ({
			toFirstView() {
				setView(VIEW.TEMPLATE_TYPE);
			},
			onDiscard() {
				close();
				dispatch(formDestroy(FORM_MODEL.CREATE_STORY));
			},
			onClickOutside() {
				actions.onDiscard();
			},
			onPreviewClick(templateId: number) {
				setSelectedTemplate(templateId);
				setView(VIEW.TEMPLATE_PREVIEW);
			},
		}),
		[dispatch, close]
	);

	const onSubmit = async (params: CreateStoryFromTemplateParams | CreateStoryFromScratchParams) => {
		setIsLoading(true);

		const template = 'template' in params ? params.template : undefined;
		const type = 'type' in params ? params.type : formValues.type;
		const { name, lang: language, defaultPlatform } = formValues;
		const tags: string[] = [];
		const storyData: UpdateLatestStoryParams['storyData'] = {
			appVersion: process.env.VERSION!,
			steps: [],
			mediaQuery: { defaultPlatform, config: platformsConfig[defaultPlatform] },
			elements: [FloatAboveModel(), FloatBelowModel()],
			symbols: {},
		};
		const storySettings: UpdateLatestStoryParams['settings'] = {
			...(orgIntegrations ? { integrations: orgIntegrations } : null),
			...(orgFonts ? { fonts: orgFonts } : null),
		};

		// create from template
		if (template !== undefined) {
			const templateData =
				typeof template === 'object'
					? template
					: await dispatch(getStoryTemplate({ templateId: template })).then(res => {
							return res.success && res.result ? res.result : null;
						});
			if (templateData) {
				// if (templateData.tags) tags.push(...templateData.tags);

				const tData = templateData.story.published.data;
				storyData.steps = tData.steps;
				storyData.mediaQuery = tData.mediaQuery;
				storyData.symbols = tData.symbols;
				storyData.elements = tData.elements;

				const tSettings = templateData.story.published.settings;
				storySettings.integrations = merge(storySettings.integrations, tSettings.integrations);
				storySettings.fonts = merge(storySettings.fonts, tSettings.fonts);
				if (tSettings.seo) storySettings.seo = tSettings.seo;
				if (tSettings.screenshot) storySettings.screenshot = tSettings.screenshot;
				if (tSettings.cards) storySettings.cards = tSettings.cards;
				if (tSettings.cardOrder) storySettings.cardOrder = tSettings.cardOrder;
				if (tSettings.characters) storySettings.characters = tSettings.characters;
				if (tSettings.restrictions) storySettings.restrictions = tSettings.restrictions;
				if (tSettings.share) storySettings.share = tSettings.share;
				if (tSettings.variables) storySettings.variables = tSettings.variables;
				storySettings.templateId = templateData.id;

				// storyData = { ...templateData.story.published.data, appVersion: storyData.appVersion };
				// storySettings = merge(storySettings, omit(templateData.story.published.settings, ['metadata']));

				segmentAnalytics.track({
					event: 'story.use_template',
					properties: {
						id: templateData.id,
						name: templateData.title,
						isPublic: templateData.organizationId === null,
						organizationId: activeOrganization.id,
					},
				});
			} else {
				actions.onDiscard();
				return;
			}
		} else {
			segmentAnalytics.track({
				event: 'story.from_scratch',
				properties: {
					organizationId: activeOrganization.id,
				},
			});
		}

		let newStory = {
			name,
			type,
			language,
			tags,
			storyData,
			storySettings,
			// optional. generated by `injectGeneratedContent`
			slug: '',
		};

		const isAIStoryTemplate = formValues.contentType === StoryContentType.generic && template !== undefined;

		// update `newStory` with AI generated content
		if (isAIStoryTemplate) {
			const generatedContent = await dispatch(
				generateStoryContent({
					userPrompt: newStory.name,
					storyTemplateId: typeof template === 'number' ? template : template.id,
					onStatusChange: setLoadingStatus,
				})
			);

			if (generatedContent.success && generatedContent.result) {
				// mark story as generated with AI
				newStory.storySettings.generator = generatedContent.result.generator;

				// inject generated content
				newStory = injectGeneratedContent({
					story: newStory,
					content: generatedContent.result.prompts,
					state: COMPONENT_STATES.DEFAULT,
					platform: DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP,
				});
			}
		}

		// create new story
		const createStoryResponse = await dispatch(
			createStory({
				name: newStory.name,
				type: newStory.type,
				language: newStory.language,
				tags: newStory.tags,
			})
		);

		if (!createStoryResponse.success || !createStoryResponse.result) {
			actions.onDiscard();
			return;
		}

		/**
		 * This ensures that each story generated from AI template is properly registered with its corresponding
		 * generator, facilitating improved data management and historical tracking of story generation events.
		 */
		if (isAIStoryTemplate && newStory.storySettings.generator?.id) {
			dispatch(
				patchAIStoryGenerator({
					storyId: createStoryResponse.result.storyId,
					generatorId: newStory.storySettings.generator.id,
					jobId: newStory.storySettings.generator.jobId,
				})
			);
		}

		try {
			if (newStory.slug) {
				await dispatch(
					updateStoryUrl({
						storyId: createStoryResponse.result.storyId,
						clientStoryId: `${activeOrganization.id}/${newStory.slug}`,
					})
				);
			}
			// fill story with a data
			await dispatch(
				updateLatestStory({
					storyId: createStoryResponse.result.storyId,
					storyData: newStory.storyData,
					settings: newStory.storySettings,
				})
			);
		} catch {
			actions.onDiscard();
		}

		onSubmitSuccess(createStoryResponse.result.storyId);
	};

	const onSubmitSuccess = (storyId: string) => {
		actions.onDiscard();
		routerHistory.push(generatePath(STORY_FLOW_PAGE, { storyId }));
	};

	return (
		<Modal
			open={props.open}
			fullScreen
			title={null}
			destroyOnClose
			transitionName="" // disable animation
			// onCancel={props.data.onCancel}
		>
			<CSSTransition in={isLoading} classNames="fadeInOut" timeout={{ enter: 0, exit: 150 }}>
				{formValues?.contentType === StoryContentType.generic ? (
					<AIStatusLoader status={loadingStatus} title="Tailoring a Magical Story" view="story" />
				) : (
					<Loader color="currentColor" bgColor="var(--ra-color-white-dark)" size="large" fullScreen />
				)}
			</CSSTransition>
			<Layout.Layout>
				<Layout.Header>
					<Button
						view="label-only"
						onClick={() => {
							viewHistoryRef.current.pop();
							setView(viewHistoryRef.current[viewHistoryRef.current.length - 1] ?? VIEW.TEMPLATE_TYPE);
						}}
						textWeight={Text.weight.normal}
						className={cn({ invisible: view === VIEW.TEMPLATE_TYPE })}
					>
						<Icon type="chevron-left" width={22} color="currentColor" />
						{view === VIEW.TEMPLATE_PREVIEW
							? t('createStoryModal.allTemplates')
							: t('createStoryModal.back')}
					</Button>
					<Button view="label-only" onClick={actions.onDiscard} textWeight={Text.weight.normal}>
						{t('createStoryModal.cancel')}
					</Button>
				</Layout.Header>

				{/* Views */}
				{view === VIEW.TEMPLATE_TYPE && (
					<Layout.CenteredContent>
						<Form onClickOutside={actions.onClickOutside}>
							{providedFormProps => (
								<TemplateType
									dispatch={providedFormProps.dispatch}
									onOk={type => {
										if (type === 'clone') setView(VIEW.TEMPLATE_CLONE);
										else if (type !== 'widget' && isCreateAIStoryAllowed)
											setView(VIEW.TEMPLATE_CONTENT);
										else if (isStoryTemplatesAllowed) setView(VIEW.TEMPLATE_GALLERY);
										else setView(VIEW.TEMPLATE_NAME);
									}}
									form={providedFormProps.form}
									values={providedFormProps.formValues}
								/>
							)}
						</Form>
					</Layout.CenteredContent>
				)}
				{view === VIEW.TEMPLATE_CONTENT && (
					<Layout.CenteredContent>
						<Form>
							{providedFormProps => (
								<TemplateContent
									dispatch={providedFormProps.dispatch}
									onOk={() => {
										setView(isStoryTemplatesAllowed ? VIEW.TEMPLATE_GALLERY : VIEW.TEMPLATE_NAME);
									}}
									form={providedFormProps.form}
									values={providedFormProps.formValues}
								/>
							)}
						</Form>
					</Layout.CenteredContent>
				)}
				{(view === VIEW.TEMPLATE_GALLERY || view === VIEW.TEMPLATE_PREVIEW) && (
					<TemplateGallery
						hidden={view === VIEW.TEMPLATE_PREVIEW}
						language={formValues.lang}
						storyType={formValues.type}
						onPreviewClick={actions.onPreviewClick}
						onOk={params => {
							if (params) setSelectedTemplate(params.template);
							setView(VIEW.TEMPLATE_NAME);
						}}
					/>
				)}
				{view === VIEW.TEMPLATE_PREVIEW && selectedTemplate !== null && (
					<TemplatePreview
						templateId={typeof selectedTemplate === 'number' ? selectedTemplate : selectedTemplate.id}
						onOk={params => {
							setSelectedTemplate(params.template);
							setView(VIEW.TEMPLATE_NAME);
						}}
					/>
				)}
				{view === VIEW.TEMPLATE_NAME && (
					<Layout.CenteredContent>
						<Form>
							{providedFormProps => (
								<TemplateName
									username={username}
									onOk={() =>
										onSubmit(
											selectedTemplate === null
												? { type: formValues.type }
												: { template: selectedTemplate }
										)
									}
									dispatch={providedFormProps.dispatch}
									syncErrors={providedFormProps.formErrors}
									form={providedFormProps.form}
									isGenericContent={formValues.contentType === StoryContentType.generic}
								/>
							)}
						</Form>
					</Layout.CenteredContent>
				)}
				{view === VIEW.TEMPLATE_CLONE && (
					<Layout.CenteredContent>
						<Form>
							{providedFormProps => (
								<TemplateClone
									onOk={onSubmitSuccess}
									dispatch={providedFormProps.dispatch}
									syncErrors={providedFormProps.formErrors}
									form={providedFormProps.form}
									url={providedFormProps.formValues.clone}
								/>
							)}
						</Form>
					</Layout.CenteredContent>
				)}
			</Layout.Layout>
		</Modal>
	);
};

export default CreateStoryModal;
