import { useEffect, useState, useMemo } from 'react';
import { getAIUsage, OrganizationAIUsageResult, ImageUsage, TextUsage } from 'admin/actions/organization/get-ai-usage';
import { useAdminDispatch, useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId, selectActiveOrganization } from 'admin/reducers/user/selectors';

const cache: Record<string, { res: Promise<IBackendBody<OrganizationAIUsageResult>>; timestamp: number }> = {};

export function useAIUsage() {
	const dispatch = useAdminDispatch();
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const plan = useAdminSelector(selectActiveOrganization)?.plan;
	const [data, setData] = useState<OrganizationAIUsageResult>();
	const [isFetched, setIsFetched] = useState(false);

	useEffect(() => {
		async function fetchData() {
			const now = Date.now();
			const cacheEntry = cache[organizationId];

			try {
				if (cacheEntry && now - cacheEntry.timestamp < 30_000) {
					const { result } = await cacheEntry.res;
					setData(result);
					setIsFetched(true);
				} else {
					const res = dispatch(getAIUsage({ organizationId }));
					cache[organizationId] = { res, timestamp: now };

					setIsFetched(false);

					const { result } = await res;
					setData(result);
					setIsFetched(true);
				}
			} catch {
				setData(undefined);
				setIsFetched(true);
			}
		}

		if (organizationId) fetchData();
	}, [dispatch, organizationId]);

	const formattedData = useMemo(() => {
		const mapping = { ai_text: 'text', ai_images: 'images' };
		return data?.monthly.reduce(
			(acc, item) => {
				const type = mapping[item.type] || item.type;
				acc[type].total += item.usage;
				acc[type].items.push(item);
				acc.total = acc.text.total + acc.images.total;
				return acc;
			},
			{ total: 0, images: { total: 0, items: [] as ImageUsage[] }, text: { total: 0, items: [] as TextUsage[] } }
		);
	}, [data]);

	if (!formattedData) {
		return { isFetched };
	}

	return { isFetched, formattedData, max: plan?.AIUsageLimit };
}

export default useAIUsage;
