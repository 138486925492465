import type { CmsCollectionDataItem } from 'types/cms';
import type { BBModel, StorySymbols } from 'types/story';
import cms from 'utils/cms';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { componentWalkFull } from 'utils/blocks/component-walk-full';
import { parseComponentProps } from 'utils/blocks/parse-component-props';

/**
 * Collects references to CMS data items from components.
 * Used to help fetch data for components that depend on CMS data.
 */
export class CmsReferencesManager {
	// Map<collectionId, Set<collectionDataId>
	private collectionRefs = new Map<string, Set<string>>();

	public clear() {
		this.collectionRefs.clear();
	}

	public getReferences(): { [collectionId: string]: CmsCollectionDataItem['id'][] } {
		return Array.from(this.collectionRefs.keys()).reduce((acc, collectionId) => {
			acc[collectionId] = Array.from(this.collectionRefs.get(collectionId) ?? []);
			return acc;
		}, {});
	}

	collectComponentReferences(elements: BBModel[], symbols: StorySymbols = {}) {
		componentWalkFull({
			elements,
			symbols,
			callback: ({ component }) => {
				parseComponentProps(component, ({ prop, value }) => {
					this.collectPropertyReferences(prop, value, component);
				});
			},
		});
	}

	collectPropertyReferences(property: string, value: unknown, component: BBModel) {
		if (!value || typeof value !== 'string') {
			return;
		}

		if (property === UNIFORM_PROPS.collectionId) {
			if (!this.collectionRefs.has(value)) {
				this.collectionRefs.set(value, new Set());
				return;
			}
		}

		const ref = cms.parseReference(value, false);

		if (!ref) {
			return;
		}

		if (!this.collectionRefs.has(ref.collectionId)) {
			this.collectionRefs.set(ref.collectionId, new Set());
		}

		this.collectionRefs.get(ref.collectionId)?.add(ref.dataId);
	}
}
