export type CmsCollectionType = {
	id: string;
	organizationId: string;
	dataSource: 'http_api' | 'internal_tabular';
	name: string;
	fields: CmsCollectionFieldItem[];
};

export type CmsCollectionFieldItem = {
	id: string;
	key: string;
	collectionId: string;
	name: string;
	type: CmsFieldType;
	updatedAt: string;
	createdAt: string;
};

export enum CmsFieldType {
	Text = 'text',
	Image = 'image',
	// Array = 'array',         // not implemented yet
	// Object = 'Object',       // not implemented yet
	Boolean = 'boolean',
	Number = 'number',
	Float = 'float',
	Timestamp = 'timestamp',
	RichText = 'rich_text',
	URL = 'url',
	// Document = 'document',   // not implemented yet
	Video = 'video',
	// Audio = 'audio',         // not implemented yet
	// Color = 'color',         // not implemented yet
}

export type CmsCollectionDataItem = {
	id: string;
	position: number;
	created_at: string;
	updated_at: string;
	// custom user fields. see CmsCollectionFieldItem['key']
	[field: string]: CmsCollectionDataValue;
};

export type CmsCollectionDataValue = string | number | boolean | undefined | null;

export type CmsItemsRecord = { [itemId: string]: CmsCollectionDataItem };

export type CmsModel = {
	collections: CmsCollectionType[] | null;
	items: { [collectionId: string]: CmsItemsRecord | null };
};

export enum CollectionPaginationType {
	none = 'none',
	buttons = 'buttons',
	numbers = 'numbers',
}

export type CollectionPaginationTriggersProp = {
	// component id to trigger pagination
	prev: string;
	// component id to trigger pagination
	next: string;
	// TODO: add "first" and "last" triggers
	// first: string;
	// last: string;
};

export enum CmsFilterOperator {
	Contains = '*',
	Equal = '=',
	NotEqual = '!=',
	GreaterThan = '>',
	GreaterThanOrEqual = '>=',
	LessThan = '<',
	LessThanOrEqual = '<=',
}

/**
 * @param field       - collection field name to filter collection by, e.g. 'title'
 * @param componentId - component id, that is bound to the repeatable one to filter by
 * @param condition   - condition to filter by
 * @param value       - value to filter by
 */
export type CmsFilter =
	| {
			type: 'search';
			field: string;
			componentId: string;
			operator: CmsFilterOperator;
	  }
	| {
			type: 'filter';
			field: string;
			componentId: string;
			operator: CmsFilterOperator;
			value: string;
	  }
	| {
			type: 'default';
			field: string;
			operator: CmsFilterOperator;
			value: string;
	  };

// AND - return ones that match all conditions
// OR - return ones that match any condition
type LogicalOperator = 'AND' | 'OR';
type FilterField = keyof CmsCollectionDataItem;
type FilterValue = string | number | string[] | number[];

interface BaseFilter {
	field: FilterField;
	operator: CmsFilterOperator;
	value: FilterValue;
}

interface LogicalFilter {
	operator: LogicalOperator;
	conditions: Filter[];
}

interface FindFilter {
	// FIND - return ones that match the condition
	type: 'FIND';
	field: FilterField;
	value: FilterValue;
}

type Filter = BaseFilter | LogicalFilter | FindFilter;

export interface ListCollectionItemsInput {
	collectionId: string;
	page?: number;
	pageSize?: number;
	orderBy?: keyof CmsCollectionDataItem;
	orderDir?: 'asc' | 'desc';
	filter?: Filter;
}
