import { cloneDeep, isNumber as _isNumber } from 'lodash';
import debug from 'debug';
import type { ClipboardEvent, MutableRefObject, ForwardedRef } from 'react';
import type { IOrganizationIntegrations } from 'src/types';

export const appLog = debug('app');
export const adminLog = debug('admin');
export const clientLog = debug('client');
// debug.disable();
// debug.enable('app:*');

/**
 * Move item in array.
 * Usually helpful for DnD.
 */
function arrayMove<T>(arr: T[], fromIndex: number, toIndex: number) {
	const element = arr[fromIndex];
	arr.splice(fromIndex, 1);
	arr.splice(toIndex, 0, element);
}

// Returns true for exact number value.
const isNumber = <T extends number>(num: T) => _isNumber(num) && !Number.isNaN(num);

// Prepend protocol to url string
function prependUrlProtocol(url: string, protocol = 'http'): string {
	let _url = url.startsWith('//') ? url.replace('//', '') : url;

	if (!/^https?:\/\//i.test(_url)) {
		_url = `${protocol}://${_url}`;
	}

	return _url;
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

function shortNameByEmail(email: string): string {
	if (typeof email !== 'string') {
		return '';
	}

	const emailSplited = email.split('@')[0];
	const firstLetters = emailSplited.split('.');
	const shortName = firstLetters.length === 1 ? firstLetters[0][0] : `${firstLetters[0][0]}${firstLetters[1][0]}`;

	return shortName;
}

// Cross-browser trigger focus event on element
function triggerFocus(element?: HTMLElement | null) {
	if (!element) return;

	const eventType = 'onfocusin' in element ? 'focusin' : 'focus';
	const bubbles = 'onfocusin' in element;
	let event;

	if ('createEvent' in document) {
		event = document.createEvent('Event');
		event.initEvent(eventType, bubbles, true);
	} else if ('Event' in window) {
		event = new Event(eventType, { bubbles, cancelable: true });
	}

	element.focus();
	element.dispatchEvent(event);
}

/**
 * @param event {MouseEvent}
 * @param config {Object}
 * @param config.by {String}
 * @param config.withScroll {Boolean}
 * @param config.scrollContainer {Element}
 * @return {{x: number, y: number}}
 */
function getMousePosition(event, config = {}) {
	const _config = {
		by: 'page',
		withScroll: true,
		scrollContainer: document.documentElement,
		...config,
	};

	const mainScrollContainer = _config.scrollContainer;
	const { scrollLeft, scrollTop } = mainScrollContainer;

	const x: number = event[`${_config.by}X`] + (_config.withScroll ? scrollLeft : 0);
	const y: number = event[`${_config.by}Y`] + (_config.withScroll ? scrollTop : 0);

	return { x, y };
}

const int = v => parseInt(v || 0, 10);

const convertPxToVw = (value, digits = 2, windowWidth = window.innerWidth) => {
	const _value = parseFloat(value);
	if (Number.isNaN(_value)) return '';
	return `${((_value / windowWidth) * 100).toFixed(digits)}vw`;
};

const convertVwToPx = (value, digits = 2, windowWidth = window.innerWidth) => {
	const _value = parseFloat(value);
	if (Number.isNaN(_value)) return '';
	return +((_value * windowWidth) / 100).toFixed(digits);
};

// Removes all ranges from the selection
function clearSelection() {
	if (window.getSelection) {
		// @ts-expect-error ts-migrate FIXME
		window.getSelection().removeAllRanges();
		// @ts-expect-error ts-migrate FIXME
	} else if (document.selection) {
		// @ts-expect-error ts-migrate FIXME
		document.selection.empty();
	}
}

function selectNodeContent(node: Node) {
	const range = document.createRange();
	range.selectNodeContents(node);
	const sel = window.getSelection();
	sel?.removeAllRanges();
	sel?.addRange(range);
}

/**
 * Is command(MacOS) or control (Win) key pressed
 * @param event {SyncEvent<MouseEvent>}
 * @returns {boolean}
 */
const isCMD = event => (window.navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey);

/**
 * @link {https://muffinman.io/blog/react-rerender-in-component-did-mount}
 * @param fn
 */
function defer(fn: Function) {
	requestAnimationFrame(() => {
		requestAnimationFrame(() => {
			fn();
		});
	});
}

const isObjWithEmptyFields = (obj: ValuesType<IOrganizationIntegrations> | undefined | null) => {
	if (!obj) {
		return true;
	}

	return Object.values(obj).every(value => value === null || value === undefined || value === '');
};

// simple memoize fn
function memoize<T extends (...args: any[]) => any>(fn: T) {
	const memo = new Map<Parameters<T>[0], ReturnType<T>>();
	return (value: Parameters<T>[0]) => {
		if (!memo.has(value)) memo.set(value, fn(value));
		return {
			get: () => memo.get(value)!,
			clear: () => memo.delete(value),
			clearAll: () => memo.clear(),
		};
	};
}

function getPlainTextFromHtml(html: string) {
	const span = document.createElement('span');
	span.innerHTML = html;
	return span.textContent || span.innerText || '';
}

const pastePlainText = (event: ClipboardEvent, processText?: (text: string) => string) => {
	// cancel paste
	event.preventDefault();
	// get text representation of clipboard
	const text = ((event as any).originalEvent || event).clipboardData.getData('text/plain');
	// insert text manually
	const command = document.queryCommandSupported('insertText') ? 'insertText' : 'paste';
	document.execCommand(command, false, processText ? processText(text) : text);
};

/**
 * Removes invisible or control characters from a given string.
 */
const clearInvisibleCharacters = (str: string = '') =>
	str.replace(/[\u007F-\u009F\u200B-\u200F\u202A-\u202E\u2060-\u2064\uFEFF]/g, '');

class Counter<T extends string> {
	private record = {} as Record<T, number>;

	private readonly startFrom: number = 0;

	constructor(props?: { startFrom: number }) {
		if (props) this.startFrom = props.startFrom;
	}

	increment(key: T) {
		this.record[key] = this.getCount(key) + 1;
	}

	getCount(key: T) {
		return this.record[key] ?? this.startFrom;
	}
}

class TimeoutController {
	private timerId: NodeJS.Timeout | null = null;

	constructor(private readonly ms: number) {
		this.ms = ms;
	}

	startTimer(): Promise<void> {
		return new Promise(resolve => {
			this.timerId = setTimeout(() => {
				this.timerId = null;
				resolve();
			}, this.ms);
		});
	}

	isBusy(): boolean {
		return this.timerId !== null;
	}
}

/**
 * Deep clone object.
 *
 * Memoization may be achieved by `memoize(deepClone<Type>)`
 */
const deepClone = <T>(obj: T, strategy: 'json' | 'structuredClone' = 'structuredClone') => {
	try {
		return strategy === 'json' ? (JSON.parse(JSON.stringify(obj)) as T) : structuredClone(obj);
	} catch {
		return cloneDeep(obj);
	}
};

const attachRef =
	<T extends HTMLElement>(ref: Array<MutableRefObject<T | null> | ForwardedRef<T>>) =>
	(node: T | null) => {
		ref.forEach(draftRef => {
			if (typeof draftRef === 'function') draftRef(node);
			else if (draftRef) draftRef.current = node;
		});
	};

export {
	isObjWithEmptyFields,
	arrayMove,
	isNumber,
	prependUrlProtocol,
	sleep,
	shortNameByEmail,
	triggerFocus,
	getMousePosition,
	int,
	convertPxToVw,
	convertVwToPx,
	clearSelection,
	selectNodeContent,
	isCMD,
	defer,
	memoize,
	pastePlainText,
	Counter,
	TimeoutController,
	clearInvisibleCharacters,
	getPlainTextFromHtml,
	deepClone,
	attachRef,
};
