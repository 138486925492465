import produce from 'immer';
import { pick } from 'lodash';
import { ActionTypes as UndoActionTypes } from 'redux-undo';
import { actionTypes as FormActionTypes } from 'redux-form';
import { FORM_MODEL } from 'admin/constants/common';
import {
	GET_STORY,
	UPDATE_EDITABLE_CARD_DATA,
	UPDATE_EDITABLE_STORY,
	UPDATE_LATEST_STORY,
	UPDATE_PUBLISHED_STORY,
} from 'admin/constants/actions';
import type { onMessageParams } from 'admin/middleware/state-sync-middleware';
import { StoryFacade } from 'utils/facades/story-facade';
import { getMergeOfEditableStoryWithCardEditor } from 'admin/actions/card-editor/card-editor-sync';
import { storyEditorChange, storyEditorFormChange } from 'admin/actions/story/update-editable-story';

export default {
	channel: 'storycards.admin.story',
	whiteList: [
		/**
		 * Invoked: - get-story.ts
		 * Result:  - update "story-editor-reducer"
		 */
		GET_STORY.FULFILLED,
		/**
		 * Invoked: - update-editable-story.ts + story-editor-sync.ts;
		 *          - card-editor-sync.ts
		 * Result:  - update "story-editor-reducer"
		 */
		UPDATE_EDITABLE_STORY.UPDATE,
		/**
		 * Invoked: - update-editable-card-data.ts + card-editor-sync.ts
		 * Result:  - update "card-editor" reducer
		 *          - [optional] update "story-editor" reducer
		 */
		UPDATE_EDITABLE_CARD_DATA,
		/**
		 * Invoked: - update-latest-story.ts
		 * Result: - update "story-editor-reducer"
		 */
		UPDATE_LATEST_STORY.FULFILLED,
		/**
		 * Invoked: - update-published-story.ts
		 * Result: - update "story-editor-reducer"
		 */
		UPDATE_PUBLISHED_STORY.FULFILLED,
		/**
		 * Invoked: - actions "card-editor-undo-redo.ts"
		 * Result:  - update "card-editor" reducer
		 */
		UndoActionTypes.UNDO,
		UndoActionTypes.REDO,
	],
	blackList: [FormActionTypes.REGISTER_FIELD, FormActionTypes.UNREGISTER_FIELD],
	predicate: action => action.meta?.form === FORM_MODEL.EDIT_STORY,
	onMessage: (params: onMessageParams) => {
		const editableStoryId = params.state.storyEditor.story?.id;
		const editableCardId = params.state.cardEditor?.present?.data?._id;

		const senderStoryId = params.sender?.storyId;
		const senderCardId = params.sender?.cardId;

		const isSameStory =
			senderStoryId !== undefined && editableStoryId !== undefined && senderStoryId === editableStoryId;

		const action = {
			...produce(params.action, draftAction => {
				delete draftAction?.meta?.autoSync; // ignore auto save for subscribers
			}),
			__stateSync: true, // mark sync action
		};

		if (!isSameStory) {
			return;
		}

		// match same card editor
		if (senderCardId !== undefined && senderCardId === editableCardId) {
			params.dispatch(action);
			return;
		}

		// match different card editor
		if (senderCardId !== undefined && editableCardId !== undefined && senderCardId !== editableCardId) {
			// update card editor
			params.dispatch({
				type: UPDATE_EDITABLE_CARD_DATA,
				payload: pick(params.sender?.cardEditor, ['storyElements', 'storySettings', 'symbols']),
				__stateSync: true,
			});

			// update editable story
			updateStoryReducer({ ...params, action });
		}

		// match from Editor to Flow
		if (senderCardId !== undefined && editableCardId === undefined) {
			// update editable story
			updateStoryReducer({ ...params, action });
		}

		// match from Flow to Editor
		if (senderCardId === undefined && editableCardId !== undefined) {
			// update card editor with the same card by id from latest story object
			if (action.type === UPDATE_EDITABLE_STORY.UPDATE && params.sender?.latestStory) {
				const { latestStory } = params.sender;
				const { card: currentCard } = StoryFacade.findCardInSteps(editableCardId, latestStory.data.steps);

				if (currentCard) {
					params.dispatch({
						__stateSync: true,
						type: UPDATE_EDITABLE_CARD_DATA,
						payload: {
							data: currentCard,
							storySettings: latestStory.settings,
							storyElements: latestStory.data.elements,
							symbols: latestStory.data.symbols,
						},
					});
				}
			}

			params.dispatch(action);
		}

		// match Flow to Flow
		if (senderCardId === undefined && editableCardId === undefined) {
			params.dispatch(action);
		}
	},
};

function updateStoryReducer(params: onMessageParams) {
	if (!params.state.storyEditor.story) {
		return;
	}

	if (
		params.action.type === UPDATE_LATEST_STORY.FULFILLED ||
		params.action.type === UPDATE_PUBLISHED_STORY.FULFILLED
	) {
		// These two actions does not contain any useful payload,
		// but the story-editor reducer updates timestamps and `opCounter` in response to their invocation
		params.dispatch({ ...params.action, __stateSync: true });
		return;
	}

	const cardEditor = params.sender?.cardEditor;
	if (cardEditor) {
		const version = params.state.version.current;
		const story = getMergeOfEditableStoryWithCardEditor(params.state.storyEditor.story, cardEditor, version);
		const path = `storyVersions.${version}`;
		const data = story.storyVersions[version];

		// update form.storyEditor
		params.dispatch({ ...storyEditorFormChange({ path, data }), __stateSync: true });
		// update storyEditor
		params.dispatch({ ...storyEditorChange({ path, data }), __stateSync: true });
	}
}
