import { useEffect, useCallback, useState, useMemo } from 'react';
import { getWidgetViews, OrganizationWidgetViewsResult } from 'admin/actions/organization/get-widget-views';
import { useAdminDispatch, useAdminSelector } from 'admin/reducers';
import { selectActiveOrganizationId, selectActiveOrganization } from 'admin/reducers/user/selectors';
import { IUserOrganizationPlan } from 'src/types';

const cache: Record<string, { res: Promise<IBackendBody<OrganizationWidgetViewsResult>>; timestamp: number }> = {};

interface Params {
	lazy: boolean;
}

type NoramlResult = Partial<ReturnType<typeof formatResult>> & { isFetched: boolean };
type LazyResult = [() => Promise<ReturnType<typeof formatResult>>, NoramlResult];
type Result<L> = L extends true ? LazyResult : NoramlResult;

export function useWidgetUsage<P extends Params>(params: P): Result<P['lazy']> {
	const dispatch = useAdminDispatch();
	const organizationId = useAdminSelector(selectActiveOrganizationId);
	const plan = useAdminSelector(selectActiveOrganization)?.plan;
	const [data, setData] = useState<OrganizationWidgetViewsResult>();
	const [isFetched, setIsFetched] = useState(false);

	const getUsage = useCallback(async () => {
		const now = Date.now();
		const cacheEntry = cache[organizationId];
		let result: OrganizationWidgetViewsResult | undefined;

		try {
			if (cacheEntry && now - cacheEntry.timestamp < 30_000) {
				result = (await cacheEntry.res).result;
			} else {
				const res = dispatch(getWidgetViews({ organizationId }));
				cache[organizationId] = { res, timestamp: now };

				setIsFetched(false);
				result = (await res).result;
			}
		} catch {
			setData(undefined);
			setIsFetched(true);
		}

		setData(result);
		setIsFetched(true);

		return formatResult(result, plan);
	}, [dispatch, plan, organizationId]);

	useEffect(() => {
		if (!params.lazy) {
			getUsage();
		}
	}, [getUsage, params.lazy]);

	const usage = useMemo(() => {
		return { ...formatResult(data, plan), isFetched };
	}, [data, plan, isFetched]);

	return (params.lazy ? [getUsage, usage] : usage) as Result<P['lazy']>;
}

const formatResult = (r: OrganizationWidgetViewsResult | undefined, p: IUserOrganizationPlan | undefined | null) => {
	if (!r || !p) return null;

	return {
		views: r.views,
		max: p.maxWidgetPageviews,
	};
};

export default useWidgetUsage;
