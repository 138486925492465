import React, { useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { capitalize } from 'lodash';
import { getFormValues } from 'redux-form';
import { useHistory } from 'react-router-dom';

import type { CardData, CardTemplate, StoryModel, StoryStep } from 'types/story';
import { CARD_TYPE, CARD_DEFAULT_TEMPLATE_NAME, IS_DEVEL } from 'common/constants';
import { generatePath } from 'utils/route';
import t from 'utils/translate';
import { sleep } from 'common/utils/helpers';
import { StoryFacade } from 'common/utils/facades/story-facade';
import { CardFacade } from 'common/utils/facades/card-facade';
import {
	selectActiveOrganizationId,
	isSuperUserSelector,
	selectActiveOrganization,
} from 'admin/reducers/user/selectors';
import CARD_FACADES from 'common/utils/facades/card-facades';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import Tooltip, { ThirdPartyCookiesTooltipContent } from 'admin/components/common/Tooltip';
import { Modal } from 'admin/components/common/Modal';
import Text from 'admin/components/common/Text';
import { FORM_MODEL, MODAL } from 'admin/constants/common';
import { STORY_CARD_EDITOR_PAGE } from 'admin/constants/routes';
import { getCardTemplates, getCardTemplate } from 'admin/actions/story/templates';
import { updateEditableStory } from 'admin/actions/story/update-editable-story';
import { GET_CARD_TEMPLATES } from 'admin/constants/actions';
import { selectLoadingStateByName } from 'admin/reducers/loading/selectors';
import { CSSTransition } from 'admin/components/common/Transition';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import AdminError from 'admin/components/common/ErrorBoundary/AdminError';
import { selectEditableStoryId } from 'admin/reducers/story-editor/selectors';
import { segmentAnalytics } from 'admin/utils';
import Loader from 'common/components/Loader';
import Button from 'admin/components/common/Button';
import { Icon } from 'admin/components/common/Icon';
import Layout from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/Layout';

import { TemplateGallery } from './TemplateGallery';
import { TemplatePreview } from './TemplatePreview';
import { TemplateSetup } from './TemplateSetup';
import Form, { Fields } from './Form';
import { getCardType, DEFAULT_LAST_CARD_TYPE, addCardTemplatesToStory } from './utils';

export type CreateCardModalData = { stepIndex: number; step: StoryStep; onCancel: () => void };

type Props = ModalManagerProvidedProps<MODAL.CREATE_CARD>;

const cardFormValuesSelector = getFormValues(FORM_MODEL.CREATE_CARD);

const storyFormValuesSelector = getFormValues(FORM_MODEL.EDIT_STORY);

enum VIEW {
	TEMPLATE_GALLERY,
	TEMPLATE_PREVIEW,
	TEMPLATE_SETUP,
}

const CreateCardModal: React.FC<Props> = ({ close: closeModal, ...props }) => {
	// app state
	const dispatch = useAdminDispatch();
	const routerHistory = useHistory();
	const storyId = useAdminSelector(selectEditableStoryId)!;
	const formValues = useAdminSelector(cardFormValuesSelector) as Fields;
	const story = useAdminSelector(storyFormValuesSelector) as StoryModel;
	const version = useAdminSelector(state => state.version.current);
	const activeOrganization = useAdminSelector(selectActiveOrganizationId);
	const organizationName = useAdminSelector(selectActiveOrganization)!.name;
	const isSuperUser = useAdminSelector(isSuperUserSelector);
	const isFetchingTemplates = useAdminSelector(state => selectLoadingStateByName(state, GET_CARD_TEMPLATES.PENDING));

	// constants
	const StoryFacadeInstance = new StoryFacade(story, version);
	const storyTemplateId = StoryFacadeInstance.settings.templateId;
	const { mediaQuery } = StoryFacadeInstance;
	const canUseThirdPartyCookies = StoryFacadeInstance.canUseThirdPartyCookies();
	const isWidget = story.type === 'widget';
	const { current: ALLOWED_TYPES } = useRef(
		isWidget
			? [CARD_TYPE.INFO]
			: Object.values(CARD_TYPE).filter(type => {
					const exclude = {
						[CARD_TYPE.NAVIGATION]: true,
						[CARD_TYPE.SANDBOX]: !isSuperUser && !IS_DEVEL,
					};
					return !exclude[type];
				})
	);
	const options = useMemo(() => {
		return ALLOWED_TYPES.map(cardType => {
			const label = capitalize(CardFacade.getCardNameByType(cardType));
			const disabled = !canUseThirdPartyCookies && cardType === CARD_TYPE.REGISTRATION;
			return {
				disabled,
				value: cardType,
				label: disabled ? (
					<Tooltip
						isStatic
						placement="right"
						stylePreset="dark-1"
						destroyTooltipOnHide
						content={ThirdPartyCookiesTooltipContent}
					>
						<span>{label}</span>
					</Tooltip>
				) : (
					label
				),
			};
		});
	}, [ALLOWED_TYPES, canUseThirdPartyCookies]);

	// component state
	const [view, setView] = useState(
		typeof storyTemplateId === 'number' ? VIEW.TEMPLATE_PREVIEW : VIEW.TEMPLATE_GALLERY
	);
	const [lastCardType, setLastCardType] = useState<CardData['type']>(DEFAULT_LAST_CARD_TYPE);
	const [isNewCardFromScratch, setIsNewCardFromScratch] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState<CardTemplate[] | null>(null);

	const actions = useMemo(
		() => ({
			toFirstView() {
				setView(VIEW.TEMPLATE_GALLERY);
				setIsNewCardFromScratch(false);
				setSelectedTemplate(null);
			},
			toLastView() {
				setView(VIEW.TEMPLATE_SETUP);
			},
			toNextView() {
				setView(prevState => prevState + 1);
			},
			toPrevView() {
				setView(prevState => prevState - 1);
			},
			dispatchGetTemplates() {
				return dispatch(getCardTemplates());
			},
			async dispatchGetTemplate(templateId: string) {
				const { success, result: template } = await dispatch(getCardTemplate({ templateId }));

				if (!success || !template) {
					throw new AdminError('Failed to fetch template');
				}

				return template;
			},
			onStartFromScratchBtnClick(cardType: CardData['type']) {
				setIsNewCardFromScratch(true);
				setLastCardType(cardType);
				actions.toLastView();
			},
			async onGalleryTemplateAddClick(templateId: CardTemplate['id']) {
				const template = await actions.dispatchGetTemplate(templateId);
				setSelectedTemplate([template]);
				setLastCardType(getCardType(template));
				actions.toLastView();
			},
			async onGalleryTemplateViewClick(templateId: string) {
				const template = await actions.dispatchGetTemplate(templateId);
				const cardType = getCardType(template);
				setSelectedTemplate([template]);
				setLastCardType(cardType);

				segmentAnalytics.track({
					event: 'card.view_template',
					properties: {
						organizationId: activeOrganization,
						cardType,
						templateName: template?.name,
						templateId: template?.id,
						category: `type:${cardType}`,
					},
				});

				actions.toNextView();
			},
			onPreviewUseClick(template: CardTemplate[]) {
				setSelectedTemplate(template);
				setLastCardType(getCardType(template[0]));
				actions.toLastView();
			},
			onSubmitSuccess(params: { isOpenCreatedCard: boolean; cardId: string }) {
				closeModal();

				if (params.isOpenCreatedCard) {
					// navigate to editor page
					const route = generatePath(STORY_CARD_EDITOR_PAGE, { storyId, cardId: params.cardId });
					routerHistory.push(route);
				}
			},
		}),
		[activeOrganization, dispatch, closeModal, routerHistory, storyId]
	);

	const createCard = async (templates: typeof selectedTemplate): Promise<CardData | null> => {
		const noStep = !props.data.step;
		const isTemplateArray = Array.isArray(templates);
		const isInvalidTemplate =
			(isTemplateArray && templates.length === 0) || (templates === null && !isNewCardFromScratch);

		if (noStep || isInvalidTemplate) {
			return null;
		}

		const cardTypes = isTemplateArray ? templates.map(getCardType).join(',') : formValues.cardType;
		segmentAnalytics.track({
			event: isNewCardFromScratch ? 'card.from_scratch' : 'card.use_template',
			properties: isNewCardFromScratch
				? {
						organizationId: activeOrganization,
						cardType: cardTypes,
					}
				: {
						organizationId: activeOrganization,
						cardType: cardTypes,
						templateName: isTemplateArray ? templates.map(o => o.name).join(',') : undefined,
						templateId: isTemplateArray ? templates.map(o => o.id).join(',') : undefined,
						category: `type:${cardTypes}`,
					},
		});

		const cardTemplates: CardTemplate[] = [];

		if (isNewCardFromScratch) {
			const template = CARD_FACADES[formValues.cardType].template[CARD_DEFAULT_TEMPLATE_NAME]({
				type: formValues.cardType,
				name: formValues.name,
			});
			cardTemplates.push(template);
		} else {
			cardTemplates.push(...templates!);
			// give a custom name to single template
			if (cardTemplates.length === 1) cardTemplates[0].editor.card.name = formValues.name;
		}

		const result = addCardTemplatesToStory({
			cardTemplates,
			story,
			version,
			mediaQuery,
			isNewCardFromScratch,
			currentStep: {
				step: props.data.step,
				index: props.data.stepIndex,
			},
		});

		// Store updated story
		dispatch(
			updateEditableStory({
				data: result.story,
				updateForm: true,
				isVersionPath: true,
			})
		);

		await sleep(500);

		// return first to open it in card editor automatically
		return result.cards[0];
	};

	const handleCreateCard = async (cardTemplates: typeof selectedTemplate) => {
		try {
			const card = await createCard(cardTemplates);
			if (card) {
				await actions.onSubmitSuccess({
					isOpenCreatedCard: isNewCardFromScratch || cardTemplates?.length === 1,
					cardId: card._id,
				});
			}
		} catch (e) {
			throw new AdminError((e as Error).message);
		}
	};

	return (
		<Modal
			open={props.open}
			fullScreen
			title={null}
			destroyOnClose
			// transitionName="" // disable animation
			onCancel={props.data.onCancel}
		>
			<CSSTransition in={isFetchingTemplates} classNames="fadeInOut" timeout={{ enter: 0, exit: 150 }}>
				<Loader fullFrame color="var(--ra-color-black-mid)" />
			</CSSTransition>

			<Layout.Layout>
				<Layout.Header>
					<Button
						view="label-only"
						onClick={actions.toFirstView}
						textWeight={Text.weight.normal}
						className={cn({ invisible: view === VIEW.TEMPLATE_GALLERY })}
					>
						<Icon type="chevron-left" width={22} color="currentColor" />
						{view === VIEW.TEMPLATE_PREVIEW
							? t('story.createCardModal.allTemplates')
							: t('story.createCardModal.back')}
					</Button>
					<Text
						compact
						size={Text.size.subheading}
						text={
							view === VIEW.TEMPLATE_GALLERY || view === VIEW.TEMPLATE_PREVIEW
								? t('story.createCardModal.chooseCardTemplate')
								: isNewCardFromScratch
									? t('story.createCardModal.startFromScratchTitle')
									: t('story.createCardModal.chooseCardName')
						}
					/>
					<Button
						view="label-only"
						textWeight={Text.weight.normal}
						onClick={() => {
							props.data.onCancel();
							closeModal();
						}}
					>
						{t('story.createCardModal.close')}
					</Button>
				</Layout.Header>

				{(view === VIEW.TEMPLATE_GALLERY || view === VIEW.TEMPLATE_PREVIEW) && (
					<TemplateGallery
						hidden={view === VIEW.TEMPLATE_PREVIEW}
						canUseThirdPartyCookies={canUseThirdPartyCookies}
						onStartFromScratchBtnClick={actions.onStartFromScratchBtnClick}
						onTemplateOpen={actions.onGalleryTemplateViewClick}
						onTemplateAddClick={actions.onGalleryTemplateAddClick}
						getTemplates={actions.dispatchGetTemplates}
						organizationName={organizationName}
						organizationId={activeOrganization}
						lastCardType={lastCardType}
						allowedTypes={ALLOWED_TYPES}
					/>
				)}

				{view === VIEW.TEMPLATE_PREVIEW && (
					<TemplatePreview
						onUseClick={async template => {
							if (template.length === 1) {
								// give a name to single template first
								actions.onPreviewUseClick(template);
							} else {
								// submit with original templates name after `selectedTemplate` state updated
								await handleCreateCard(template);
							}
						}}
						mediaQuery={mediaQuery}
						template={selectedTemplate?.[0]}
						storyTemplateId={storyTemplateId}
						goToGallery={actions.toPrevView}
					/>
				)}

				{view === VIEW.TEMPLATE_SETUP && props.data.step && props.data.stepIndex !== undefined && (
					<Layout.CenteredContent>
						<Form
							lastType={lastCardType}
							onSubmit={() => handleCreateCard(selectedTemplate)}
							options={options}
						>
							{({ submitting }) => (
								<TemplateSetup
									submitting={submitting}
									options={isWidget ? undefined : options}
									isFromScratch={isNewCardFromScratch}
									totalCards={props.data.step.cards.length}
									stepIndex={props.data.stepIndex}
								/>
							)}
						</Form>
					</Layout.CenteredContent>
				)}
			</Layout.Layout>
		</Modal>
	);
};

export default CreateCardModal;
