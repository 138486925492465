import { sleep } from 'utils/helpers';
import { postAIStory, getAIStory, postAICard, patchAIStory } from 'admin/resources';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';
import { selectEditableCardId } from 'admin/reducers/card-editor/selectors';
import { selectEditableStoryId } from 'admin/reducers/story-editor/selectors';
import { createThunkAction, handleAPIv2Error } from 'admin/actions/helpers';
import { GET_GENERATED_STORY_CONTENT, GET_GENERATED_CARD_CONTENT } from 'admin/constants/actions';
import * as T from './types';
// import mock from './mock.json';

export const generateStoryContent = createThunkAction<T.GenerateStoryContentResult, T.GenerateStoryContentParams>({
	type: GET_GENERATED_STORY_CONTENT,
	payloadCreator: async ({ onStatusChange, ...payload }, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		const postResponse = await postAIStory.params({ organizationId }).send(payload);
		const body = postResponse.body as { id: string; jobId: string };

		// uncomment to use mock data. works with english AI #5 template, mock data can be replaced to another template
		// const body = { id: mock.id, jobId: mock.jobs[0].id };

		const response = await pollServer({
			organizationId,
			generatorId: body.id,
			jobId: body.jobId,
			onStatusChange,
			attempt: 1,
			interval: 10000,
		});

		const job = response.result?.jobs.find(j => j.id === body.jobId);

		return {
			success: response.success,
			result: job
				? {
						prompts: job.prompts,
						generator: { id: job.generatorId, jobId: job.id },
					}
				: null,
		};
	},
	onError: ({ error }) => {
		handleAPIv2Error(error);
	},
	alertDuration: 15_000,
});

export const patchAIStoryGenerator = createThunkAction<
	unknown,
	{ storyId: string; generatorId: string; jobId: string }
>({
	payloadCreator: async ({ storyId, jobId, generatorId }, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		await patchAIStory.params({ organizationId, generatorId }).send({ storyId, jobId });
		return { success: true };
	},
	onError: ({ error }) => {
		handleAPIv2Error(error);
	},
});

export const generateCardContent = createThunkAction<T.PromptsResponseList, T.GenerateCardContentParams>({
	type: GET_GENERATED_CARD_CONTENT,
	payloadCreator: async ({ onStatusChange, ...payload }, { getState }) => {
		const state = getState();
		const cardId = selectEditableCardId(state);
		const storyId = selectEditableStoryId(state);
		const organizationId = selectActiveOrganizationId(state);

		if (!cardId || !storyId) {
			return { success: false };
		}

		const postResponse = await postAICard.params({ storyId, cardId }).send(payload);
		const body = postResponse.body as { id: string; jobId: string };
		const response = await pollServer({
			organizationId,
			generatorId: body.id,
			jobId: body.jobId,
			onStatusChange,
			attempt: 1,
			interval: 5000,
		});

		const job = response.result?.jobs.find(j => j.id === body.jobId);

		return {
			success: response.success,
			result: job?.prompts,
		};
	},
	onError: ({ error }) => {
		handleAPIv2Error(error);
	},
	alertDuration: 15_000,
});

type PollServerParams = {
	attempt: number;
	generatorId: string;
	jobId: string;
	organizationId: string;
	onStatusChange: T.GenerateStoryContentParams['onStatusChange'];
	interval: number;
};

type PollServerResponse = Promise<{ success: boolean; result: T.GenerateStoryContentResponse | null }>;

async function pollServer(params: PollServerParams): PollServerResponse {
	const FAILED_STATUS_MSG = 'Something went wrong, retrying.';
	const DEFAULT_STATUS_MSG = 'Sending a request to the Universe...';

	try {
		const { body }: { body: T.GenerateStoryContentResponse } = await getAIStory
			.params({ organizationId: params.organizationId, generatorId: params.generatorId })
			.send({ jobId: params.jobId });

		// uncomment to use mock data. works with english AI #5 template, mock data can be replaced to another template
		// const body: T.GenerateStoryContentResponse = mock;

		const job = body.jobs.find(j => j.id === params.jobId);
		const statusMessages: string[] = job?.statuses ? [...job.statuses] : [];
		const currentStatus = statusMessages[statusMessages.length - 1];
		const failedStatusesLen = statusMessages.filter(m => m === FAILED_STATUS_MSG).length;
		const isFailed = failedStatusesLen % 2 === 0 && currentStatus === FAILED_STATUS_MSG;

		params.onStatusChange(currentStatus ?? DEFAULT_STATUS_MSG);

		if (isFailed) {
			return { success: false, result: null };
		}

		if (job?.status === T.GenerateStoryStatus.completed) {
			return { success: true, result: body };
		}

		await sleep(params.interval);
		return pollServer({ ...params, attempt: params.attempt + 1 });
	} catch {
		await sleep(params.interval);
		return pollServer(params);
	}
}
