import React, { useContext, useState } from 'react';
import { Steps, ConfigProvider, ThemeConfig } from 'antd';
import type { CmsModel } from 'types/cms';
import cmsUtils from 'common/utils/cms';
import { MODAL } from 'admin/constants/common';
import { useAdminSelector } from 'admin/reducers';
import Button from 'admin/components/common/Button';
import { useListCollectionItems } from 'admin/queries/cms/list-collection-items';
import { Select } from 'admin/components/common/Form';
import { ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { selectCollections, selectItems } from 'admin/reducers/cms/selectors';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { Context as ThemeContext } from 'admin/components/common/Theme';
import { Column, Grid } from 'admin/components/common/Grid';
import { getCollectionFieldOptions } from '../utils';
import css from './CmsPropBindModalData.scss';

enum StepIndex {
	PROPERTY,
	COLLECTION,
	DATA,
}

interface GetCollectionDataIdOptionsInput {
	collectionId: string;
	items: CmsModel['items'];
	includeDynamicCard?: boolean;
}

const getCollectionDataIdOptions = ({ items, collectionId, includeDynamicCard }: GetCollectionDataIdOptionsInput) => {
	const collectionData = items?.[collectionId];
	const commonOptions = [
		...(includeDynamicCard ? [{ label: 'By dynamic card Id', value: cmsUtils.DATA_DYNAMIC_CARD }] : []),
		{ label: 'Auto', value: cmsUtils.DATA_ANY },
	];
	if (!collectionData) return commonOptions;
	return [...commonOptions, ...Object.values(collectionData).map((d, i) => ({ value: d.id, label: `${i}` }))];
};

const antdTheme: ThemeConfig = {
	token: {
		colorText: '#fff',
		colorTextDescription: 'rgba(255, 255, 255, 0.45)',
		colorTextDisabled: 'blue',
		colorTextQuaternary: 'red',
		colorBorderSecondary: 'rgba(255, 255, 255, 0.18)',
	},
};

type ContentProps = ModalManagerProvidedProps<MODAL.CMS_PROP_BIND_MODAL>;

const ModalContent: React.FC<ContentProps> = props => {
	const { theme } = useContext(ThemeContext);
	const cmsCollections = useAdminSelector(selectCollections);
	const cmsItems = useAdminSelector(selectItems);
	const [currentStep, setCurrentStep] = useState(props.data.view === 'edit' ? StepIndex.DATA : StepIndex.PROPERTY);
	const [property, setProperty] = useState(props.data.properties[0].value);
	const [collectionId, setCollectionId] = useState(props.data.collectionId);
	console.info('=== data', props.data);

	// Fetch collection items for the selected collection
	useListCollectionItems({ collectionId: collectionId ?? '', pageSize: cmsUtils.defaultProps.collectionPageSize });

	const dataKeyOptions = getCollectionFieldOptions(
		cmsCollections?.find(c => c.id === collectionId)?.fields ?? [],
		props.data.properties.find(p => p.value === property)?.fieldType ?? [],
		['position']
	);
	const defaultDataKey = props.data.view === 'edit' ? props.data.dataKey : dataKeyOptions[0]?.value;
	const [dataKey, setDataKey] = useState(dataKeyOptions.find(opt => opt.value === defaultDataKey)?.value);

	const collectionDataIdOptions = getCollectionDataIdOptions({
		collectionId: collectionId ?? '',
		items: cmsItems,
		includeDynamicCard: props.data.dynamicCardCollectionId !== undefined,
	});
	const defaultDataId =
		props.data.view === 'edit'
			? props.data.dataId
			: props.data.dynamicCardCollectionId
				? cmsUtils.DATA_DYNAMIC_CARD
				: cmsUtils.DATA_ANY;
	const [dataId, setDataId] = useState(defaultDataId);

	const onInputChange = (value: string, callback: (v: string) => void) => callback(value);

	const collectionOptions = React.useMemo(() => {
		const getLabelPostfix = (id: string) => {
			if (id === props.data.repeatableCollectionId) return ' (Repeated from)';
			if (id === props.data.dynamicCardCollectionId) return ' (Dynamic card)';
			return '';
		};
		return (
			cmsCollections?.map(c => ({
				value: c.id,
				label: `${c.name}${getLabelPostfix(c.id)}`,
			})) ?? []
		);
	}, [cmsCollections, props.data.repeatableCollectionId, props.data.dynamicCardCollectionId]);

	const steps = [
		{
			title: 'Component property',
			description: props.data.properties.find(p => p.value === property)?.label,
			isValid: !!property,
			content: (
				<Select
					options={props.data.properties}
					value={property}
					theme={theme}
					eventListeners={{ onChange: v => onInputChange(v, setProperty) }}
				/>
			),
		},
		{
			title: 'Collection',
			description: collectionOptions.find(p => p.value === collectionId)?.label,
			isValid: !!property && !!collectionId,
			content: (
				<Select
					options={collectionOptions}
					value={collectionId}
					theme={theme}
					eventListeners={{
						onChange: v =>
							onInputChange(v, async nextCollectionId => {
								setCollectionId(nextCollectionId);
							}),
					}}
				/>
			),
		},
		{
			title: 'Collection data',
			description: dataKeyOptions.find(p => p.value === dataKey)?.label,
			isValid: !!property && !!collectionId && !!dataKey,
			content: (
				<>
					<Select
						label={{ children: 'Collection data column' }}
						options={dataKeyOptions}
						value={dataKey}
						theme={theme}
						eventListeners={{ onChange: v => onInputChange(v, setDataKey) }}
					/>
					<br />
					<br />
					<Select
						label={{
							children: (
								<div>
									Collection data row <small>[optional]</small>
								</div>
							),
						}}
						options={collectionDataIdOptions}
						value={dataId}
						theme={theme}
						eventListeners={{ onChange: setDataId }}
						menuPlacement="top"
						maxMenuHeight={200}
						placeholder={defaultDataId}
					/>
				</>
			),
		},
	];

	const lastStepIndex = steps.length - 1;

	const isSaveDisabled = !collectionId || !dataKey || !property;

	const handleSave = () => {
		props.data.onSave({
			property,
			collectionId: collectionId!,
			dataKey: dataKey!,
			dataId,
		});
		props.close();
	};

	return (
		<>
			<ModalBody>
				<Grid columns="14" gap="large">
					<Column colSpan="6">{steps[currentStep].content}</Column>

					<Column colSpan="8" style={{ paddingTop: 16 }}>
						<ConfigProvider theme={antdTheme}>
							<Steps
								className={css.steps}
								size="small"
								direction="vertical"
								current={currentStep}
								items={steps.map((s, i) => ({
									key: `step-${s.title}`,
									title: s.title,
									description: s.isValid ? s.description : undefined,
									disabled: !s.isValid,
								}))}
								onChange={setCurrentStep}
							/>
						</ConfigProvider>
					</Column>
				</Grid>
			</ModalBody>

			<ModalFooter>
				{currentStep < lastStepIndex ? (
					<Button
						view="secondary"
						color="primary"
						disabled={steps[currentStep].isValid === false}
						onClick={() => setCurrentStep(s => s + 1)}
					>
						Next
					</Button>
				) : (
					<Button view="primary" disabled={isSaveDisabled} onClick={handleSave}>
						Save
					</Button>
				)}

				<Button view="empty" onClick={props.close}>
					Cancel
				</Button>
			</ModalFooter>
		</>
	);
};

export default ModalContent;
