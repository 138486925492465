import type { CollectionPaginationTriggersProp, CmsFilter } from 'types/cms';
import { useMemo, useState, useEffect } from 'react';
import { useListCollectionItems } from 'client/queries/cms/list-collection-items';
import { usePaginationTriggers } from './use-pagination-triggers';
import { useFilterReducer, useFilterTriggers } from './use-filter-triggers';

type UseDataParams = {
	collectionId: string;
	pageSize: number;
	paginationTriggers?: CollectionPaginationTriggersProp;
	collectionFilters?: CmsFilter[];
};

export const useData = ({ collectionId, pageSize, paginationTriggers, collectionFilters = [] }: UseDataParams) => {
	const [filters, setFilters] = useFilterReducer(collectionFilters);

	useFilterTriggers({ filters: collectionFilters, setFilters });

	/**
	 * note
	 * - current implementation supports only one filter condition
	 * - it combines search and filter conditions with AND operator, also includes pagination
	 */
	const filterConditions = useMemo(() => {
		const { filter, search } = filters;
		return [
			search && { type: 'FIND', field: search.field, value: search.value, operator: search.operator },
			filter && { field: filter.field, value: filter.value, operator: filter.operator },
		].filter(Boolean) as NonNullable<Parameters<typeof useListCollectionItems>[0]['filter']>[];
	}, [filters]);

	const items = useListCollectionItems({
		collectionId,
		pageSize,
		page: 0,
		orderBy: 'position',
		orderDir: 'asc',
		...(filterConditions.length ? { filter: { operator: 'AND', conditions: filterConditions } } : null),
	});

	usePaginationTriggers({
		triggers: paginationTriggers,
		fetchNextPage: items.fetchNextPage,
		fetchPreviousPage: items.fetchPreviousPage,
		hasNextPage: items.hasNextPage,
		hasPreviousPage: items.hasPreviousPage,
	});

	const pageCurrent = items.page ?? 0;
	const [currentItems, setCurrentItems] = useState(items.data?.pages.findLast(page => page.page === pageCurrent));

	useEffect(() => {
		if (items.data && items.isSuccess && !items.isFetching) {
			setCurrentItems(items.data.pages.findLast(page => page.page === pageCurrent));
		}
	}, [items.data, items.isSuccess, items.isFetching, pageCurrent]);

	const itemsMax = currentItems?.total ?? 0;
	const pageMax = Math.floor(itemsMax / pageSize);

	// todo: temp. delete it.
	useEffect(() => {
		window.__storyDebug?.({
			hasNext: items.hasNextPage,
			hasPrev: items.hasPreviousPage,
			itemsMax,
			page: `${pageCurrent}/${pageMax}`,
			pageParams: items.data?.pageParams,
			filters,
			filterConditions,
		});
	});

	return {
		collectionId,
		items: currentItems,
		isLoading: items.isLoading,
		hasNext: items.hasNextPage,
		hasPrev: items.hasPreviousPage,
		pageCurrent,
		pageMax,
		itemsMax,
	};
};
