import dayjs from 'dayjs';
import type { TableColumnsType } from 'antd';
import { snakeCase, startCase } from 'lodash';
import { isRequired } from 'admin/components/common/Form/validations';
import type { Validator } from 'redux-form';
import {
	CmsCollectionDataItem,
	CmsCollectionDataValue,
	CmsCollectionFieldItem,
	type CmsCollectionType,
	CmsFieldType,
} from 'types/cms';

export type DataType = CmsCollectionDataItem;

export type ColumnType = TableColumnsType<DataType>[number] & {
	editable?: boolean;
	dataIndex: string;
	type: CmsFieldType;
};

export type ServiceColumnType = Partial<Omit<ColumnType, 'type' | 'editable' | 'dataIndex'>>;

// List of field that are always exists in CmsCollectionDataItem 👈
export const staticCollectionFieldsMap = new Map<
	'id' | 'position' | 'updated_at' | 'created_at',
	CmsCollectionFieldItem
>([
	['id', { key: 'id', name: 'ID', type: CmsFieldType.Text } as CmsCollectionFieldItem],
	['position', { key: 'position', name: 'Position', type: CmsFieldType.Number } as CmsCollectionFieldItem],
	['updated_at', { key: 'updated_at', name: 'Updated At', type: CmsFieldType.Timestamp } as CmsCollectionFieldItem],
	['created_at', { key: 'created_at', name: 'Created At', type: CmsFieldType.Timestamp } as CmsCollectionFieldItem],
]);

export const createFieldKey = (fieldName: string) => snakeCase(fieldName);

export const createFieldNameFromKey = (fieldKey: string) => startCase(fieldKey);

export const timestampFormat = 'YYYY-MM-DD HH:mm:ss[Z]'; // YYYY-MM-DD HH:MM:SS+Z

export const defaultValueMap: Record<CmsFieldType, CmsCollectionDataValue> = {
	[CmsFieldType.Text]: 'Default text',
	[CmsFieldType.Image]: 'https://storycards.com/share.jpg',
	[CmsFieldType.Video]: null,
	[CmsFieldType.Boolean]: false,
	[CmsFieldType.Number]: 0,
	[CmsFieldType.Float]: 0,
	[CmsFieldType.Timestamp]: dayjs().format(timestampFormat),
	[CmsFieldType.RichText]: 'Default text',
	[CmsFieldType.URL]: 'https://storycards.com',
};

export const parseValue: Record<CmsFieldType, (value: CmsCollectionDataValue) => CmsCollectionDataValue> = {
	[CmsFieldType.Text]: (value: CmsCollectionDataValue) => {
		return value?.toString();
	},
	[CmsFieldType.RichText]: (value: CmsCollectionDataValue) => {
		return value?.toString();
	},
	[CmsFieldType.Number]: (value: CmsCollectionDataValue) => {
		const num = Number(value);
		return Number.isFinite(num) ? num : undefined;
	},
	[CmsFieldType.Float]: (value: CmsCollectionDataValue) => {
		const num = Number(value);
		return Number.isFinite(num) ? num : undefined;
	},
	[CmsFieldType.Boolean]: (value: CmsCollectionDataValue) => {
		return Boolean(value);
	},
	[CmsFieldType.Timestamp]: (value: CmsCollectionDataValue) => {
		if (!value) return undefined;
		const date = new Date(dayjs.isDayjs(value) ? value.toISOString() : (value as string));
		return dayjs(date).format(timestampFormat);
	},
	[CmsFieldType.URL]: (value: CmsCollectionDataValue) => {
		try {
			return new URL(value as string).toString();
		} catch {
			return undefined;
		}
	},
	[CmsFieldType.Image]: (value: CmsCollectionDataValue) => {
		return value?.toString();
	},
	[CmsFieldType.Video]: (value: CmsCollectionDataValue) => {
		return value?.toString();
	},
};

export const validateFieldKey = (action: 'upload-csv' | 'add-or-edit-single', fields: CmsCollectionFieldItem[]) => {
	const text = {
		required: 'Required',
		reserved: 'Column with this name is reserved',
		unique: 'Column with this name already exists',
	};

	const required: Validator = (value: string) => (isRequired(value) ? undefined : text.required);

	const notReserved: Validator = (value: string) => {
		const key = createFieldKey(value);
		const isReserved = staticCollectionFieldsMap.has(key as any) && key !== 'id';
		return isReserved ? text.reserved : undefined;
	};

	const uniq: Validator = (value: string) => {
		const key = createFieldKey(value);
		const isUnique = !fields.some(field => field.key === key);
		return isUnique ? undefined : text.unique;
	};

	return action === 'upload-csv' ? [required, notReserved] : [required, notReserved, uniq];
};

export const hasFeature = (feature: 'editable', sourceType: CmsCollectionType['dataSource']) => {
	switch (feature) {
		case 'editable':
			return sourceType === 'internal_tabular';
		default:
			return true;
	}
};
