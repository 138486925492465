import { parseInt, get } from 'lodash';
import type { StoryModel } from 'types/story';
import urlHelper from 'utils/url-helper';
import { prependUrlProtocol } from 'utils/helpers';
import { STORY_PATH } from 'client/constants/routes';

export type FitSize = 'cover' | 'contain';

export const IMAGE_PARAMS = {
	// Custom: original image width {number}
	oW: 'oW',
	// Custom: original width height {number}
	oH: 'oH',
	// Custom: how image resized to fit its container: FitSize
	fsz: 'fsz',
	// {@link https://docs.imgix.com/apis/rendering/size/rect}
	rect: 'rect',
	// {@link https://docs.imgix.com/apis/rendering/size/w}
	width: 'w',
	// {@link https://docs.imgix.com/apis/rendering/format/q}
	quality: 'q',
	// {@link https://docs.imgix.com/apis/rendering/size/fit}
	fit: 'fit',
	// {@link https://docs.imgix.com/apis/rendering/stylize/blur}
	blur: 'blur',
	// {@link https://docs.imgix.com/apis/rendering/auto/auto}
	auto: 'auto',
};

export const VIDEO_PARAMS = {
	transcodingId: 'tid',
};

export const PICTURES_FILE_TYPES = ['.gif', '.jpg', '.jpeg', '.tiff', '.png', '.svg', '.webp'];

export const VIDEO_FILE_TYPES = ['.mp4', '.webm'];

export const FONT_FILE_TYPES = ['woff2', 'woff', 'otf', 'ttf'];

const DOMAINS = {
	DEV_IMAGE: 'dev-storycards.imgix.net',
	DEV_ASSET: 'dev-low-cost-galleryassetsbucket9eb44ac2-pkzruw7z30pc.s3.eu-west-1.amazonaws.com',
	PROD_INFRA_IMAGE: 'storycards-infra-prod-storygalleryimgixassetsbuck-1hz9d9wcpsh2c.s3-eu-west-1.amazonaws.com',
	PROD_INFRA_ASSET: 'storycards-infra-prod-storygalleryassetsbucket-p2lxrzag8t3u.s3-eu-west-1.amazonaws.com',
	GALLERY_ASSET: 'storycards-story-gallery-assets.s3-eu-west-1.amazonaws.com',
	GALLERY_IMAGE: 'storycards-story-gallery-imgix.s3-eu-west-1.amazonaws.com',
	PROD_IMAGE: 'ix.stories.sc', // image
	PROD_ASSET: 'assts.stories.sc', // video, font
};

// key: hosting, value: domain
const HOSTING_MAP = {
	'storycards-infra-prod-storygalleryimgixassetsbuck-1hz9d9wcpsh2c': DOMAINS.PROD_INFRA_IMAGE,
	'storycards-infra-prod-storygalleryassetsbucket-p2lxrzag8t3u': DOMAINS.PROD_INFRA_ASSET,
	'storycards-story-gallery-assets': DOMAINS.GALLERY_ASSET,
	'storycards-story-gallery-imgix': DOMAINS.GALLERY_IMAGE,
	'prod-storycards-gallery-imgix': DOMAINS.PROD_IMAGE,
	'prod-storycards-gallery-assets': DOMAINS.PROD_ASSET,
	'dev-low-cost-galleryimgixbucket349e0b82-cab4l3150sim': DOMAINS.DEV_IMAGE,
	'dev-low-cost-galleryassetsbucket9eb44ac2-pkzruw7z30pc': DOMAINS.DEV_ASSET,
	default: urlHelper.adminBackend,
};

// key: domain, value: `storycardsDomain` key
const DOMAIN_MAP = {
	[DOMAINS.DEV_IMAGE]: 'images',
	[DOMAINS.DEV_ASSET]: 'assets',
	[DOMAINS.PROD_INFRA_IMAGE]: 'images',
	[DOMAINS.PROD_INFRA_ASSET]: 'assets',
	[DOMAINS.GALLERY_ASSET]: 'assets',
	[DOMAINS.GALLERY_IMAGE]: 'images',
	[DOMAINS.PROD_IMAGE]: 'images',
	[DOMAINS.PROD_ASSET]: 'assets',
};

export const isLocalAsset = (src: string) => String(src).startsWith('/assets/');

export const buildLocalAssetsUrl = (pathname: string) => `${window.location.origin}${STORY_PATH.base}${pathname}`;

export function replaceUrlHostname<T extends string | undefined>(
	url: T,
	storycardsDomain?: StoryModel['storycardsDomain']
) {
	if (!storycardsDomain || !url || url === 'none' || isGradient(url) || isLocalAsset(url)) {
		return url;
	}

	try {
		const urlObject = new URL(prependUrlProtocol(url, 'https'));
		const type = getSourceType(urlObject.href);
		const domainKey: string | undefined = DOMAIN_MAP[urlObject.hostname];
		let hostname: string | undefined = storycardsDomain[domainKey];

		switch (type) {
			case 'video':
			case 'hls':
				if (domainKey) hostname = storycardsDomain.videos;
				break;
			case 'font':
				if (!domainKey) hostname = storycardsDomain.assets;
				break;
			default:
				break;
		}

		return hostname ? urlObject.href.replace(urlObject.hostname, hostname) : urlObject.href;
	} catch {
		return url;
	}
}

export function getAssetURL(params: IAssetResource | null | undefined, protocol: boolean = true): string {
	if (!params) {
		return '';
	}

	const hosting = get(HOSTING_MAP, get(params, 'hosting'), HOSTING_MAP.default);
	const url = `${hosting}/${get(params, 'filepath')}`;
	return protocol ? prependUrlProtocol(url, 'https') : url;
}

export const isGradient = (backgroundImage: string) => {
	return String(backgroundImage).match(/^(linear|radial|repeating-linear|repeating-radial)-gradient/);
};

export const bgImage = (url: string) => ({ backgroundImage: !url || url === 'none' ? url : `url('${url}')` });

export const isImageEditableURL = (url: string, storycardsDomain: StoryModel['storycardsDomain']) => {
	return new RegExp(
		`${DOMAINS.DEV_IMAGE}|${DOMAINS.PROD_IMAGE}${storycardsDomain?.images ? `|${storycardsDomain.images}` : ''}`,
		'i'
	).test(url);
};

export const isGif = (url: string = '') => /.gif/i.test(url);

export const isSvg = (url: string = '') => /.svg/i.test(url);

export const getSourceType = (src: string = '') => {
	switch (true) {
		case /vimeo.com/i.test(src):
			return 'vimeo';
		case /(youtu.be\/)|(youtube.com\/)/i.test(src):
			return 'youtube';
		case /\.m3u8/i.test(src):
			return 'hls';
		case RegExp(VIDEO_FILE_TYPES.join('|'), 'i').test(src):
			return 'video';
		case RegExp(PICTURES_FILE_TYPES.join('|'), 'i').test(src):
			return 'image';
		case RegExp(FONT_FILE_TYPES.join('|'), 'i').test(src):
			return 'font';
		case /data:/.test(src):
			return 'uri';
		default:
			return 'none';
	}
};

export function cutURLMethod(str: string) {
	if (str && str.startsWith('url(')) {
		return str.slice(4, -1).replace(/['"]/g, '');
	}
	return str;
}

interface ParseImgSrcProps {
	backgroundSize?: string;
	backgroundPosition?: string;
	storycardsDomain: StoryModel['storycardsDomain'];
}

export function parseImgSrc(srcParam: string, props: ParseImgSrcProps) {
	try {
		let src = cutURLMethod(srcParam);

		if (src === 'none' || !src) {
			throw new Error('no source');
		}

		if (isLocalAsset(src)) {
			return { href: src, rect: '', originalWidth: 0, originalHeight: 0, url: null };
		}

		src = prependUrlProtocol(src, 'https');

		const url = new URL(decodeURIComponent(src));

		// inject properties into url
		if (isImageEditableURL(src, props.storycardsDomain)) {
			if (props.backgroundSize) url.searchParams.set(IMAGE_PARAMS.fsz, props.backgroundSize);
			if (props.backgroundPosition)
				url.searchParams.set(IMAGE_PARAMS.rect, props.backgroundPosition.replace('rect=', ''));
		}

		return {
			url,
			href: decodeURIComponent(url.href),
			rect: url.searchParams.get(IMAGE_PARAMS.rect),
			fsz: (url.searchParams.get(IMAGE_PARAMS.fsz) as FitSize | null) ?? undefined,
			originalWidth: parseInt(url.searchParams.get(IMAGE_PARAMS.oW) ?? '0'),
			originalHeight: parseInt(url.searchParams.get(IMAGE_PARAMS.oH) ?? '0'),
		};
	} catch {
		return { href: '', rect: '', originalWidth: 0, originalHeight: 0, url: null };
	}
}

// get default imgix rect param to crop an image
export function getDefaultRect(
	imageSize: { width: number; height: number },
	bbox: Pick<DOMRect, 'width' | 'height'>,
	fit: FitSize = 'cover'
) {
	let resizedW = 0;
	let resizedH = 0;

	// get ratio to fill (cover) image into bbox
	const rx = bbox.width / imageSize.width;
	const ry = bbox.height / imageSize.height;
	const r = Math[fit === 'cover' ? 'max' : 'min'](rx, ry);

	if (r === ry) {
		// portrait
		resizedH = bbox.height;
		resizedW = imageSize.width * r;
	}
	if (r === rx) {
		// landscape
		resizedW = bbox.width;
		resizedH = imageSize.height * r;
	}

	const offsetX = (resizedW - bbox.width) / 2;
	const offsetY = (resizedH - bbox.height) / 2;

	const offsetXRelative = offsetX / resizedW;
	const offsetYRelative = offsetY / resizedH;

	const [wRatio, hRatio] = [bbox.width / resizedW, bbox.height / resizedH];

	const x = imageSize.width * offsetXRelative;
	const y = imageSize.height * offsetYRelative;
	const w = imageSize.width * wRatio;
	const h = imageSize.height * hRatio;

	return [Math.round(x), Math.round(y), Math.round(w), Math.round(h)];
}
