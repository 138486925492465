import React from 'react';
import type { BBStates, StoryMediaQuery } from 'types/story';
import type { MediaKeysInOrderOfInheritance } from 'utils/get-media-keys-in-order-of-inheritance';
import { COMPONENT_STATES, DEFAULT_MEDIA_QUERY_PLATFORMS } from 'common/constants';
import { platformsConfig } from 'admin/utils';

export type CardEditorContextType = {
	storyId: string;
	cardId: string;
	dynamicCardCollectionId?: string;
	currentState: BBStates;
	mediaQuery: { currentMediaQuery: StoryMediaQuery['defaultPlatform'] } & StoryMediaQuery;
	mediaKeysInOrderOfInheritance: MediaKeysInOrderOfInheritance;
};

export const CardEditorContext = React.createContext<CardEditorContextType>({
	storyId: '',
	cardId: '',
	currentState: COMPONENT_STATES.DEFAULT,
	mediaQuery: {
		currentMediaQuery: DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP,
		defaultPlatform: DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP,
		config: platformsConfig[DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP] as StoryMediaQuery['config'],
	},
	mediaKeysInOrderOfInheritance: [DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP],
});
