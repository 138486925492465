import React from 'react';
import cn from 'classnames';
import { gsap } from 'gsap';
import type { BBCommonProps, BBEditableModeProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { Circle, Line, Rect, SHAPE_TYPE } from 'client/components/common/BuildingBlocks/Shape/Shape';
import AnswerContext from '../Context';
import css from './ResultShape.scss';

type Props = BBCommonProps;

class ResultShape extends React.Component<Props> {
	static contextType = AnswerContext;

	declare context: React.ContextType<typeof AnswerContext>;

	shapeRef = React.createRef<HTMLDivElement>();

	tl?: ReturnType<typeof gsap.timeline>;

	prevVoteValue: number | undefined;

	componentDidMount() {
		if (!this.props.isEditableMode) {
			if (this.context?.answerVote?.value) this.animateIn();
			else gsap.set(this.shapeRef.current, { alpha: 0 });
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>) {
		const voteValue = this.context?.answerVote?.value;
		const hasVoteValue = voteValue !== undefined;

		// if vote value is different from the previous one, animate
		if (this.isShow && (this.tl ? hasVoteValue && voteValue !== this.prevVoteValue : hasVoteValue)) {
			this.animateIn();
		}
	}

	get isShow() {
		return !!this.context?.answerVote?.isShow;
	}

	get type() {
		return this.props.uiConfig.componentProps?.other?.shapeType;
	}

	get lineDir() {
		return this.props.uiConfig.componentProps.other.lineDir!;
	}

	get animationDir() {
		return this.props.uiConfig.componentProps.other.animateDir!;
	}

	animateIn = () => {
		const answerVote = this.context?.answerVote;
		const shapeRef = this.shapeRef.current;

		if (!answerVote || !shapeRef) {
			return;
		}

		this.prevVoteValue = answerVote.value;
		this.tl = gsap.timeline({});

		const duration = answerVote.duration / 1000;
		const value = answerVote.value / 100;
		const { direction: dir, marginLeft, marginTop } = window.getComputedStyle(shapeRef);
		const origin = {
			x: dir === 'ltr' ? 'right' : 'left',
			y: 'top',
		} as const;
		const clip = {
			top: 0,
			right: origin.x === 'right' ? 0 : shapeRef.offsetWidth,
			bottom: shapeRef.offsetHeight,
			left: 0,
		};

		/* animate `clip[iterableTarget]` to `iterableTarget` */
		let iterableTarget: string;
		let iterableValue: number;

		if (this.animationDir === 'v') {
			if (dir === 'ltr') clip.right = shapeRef.offsetWidth;

			clip.top = shapeRef.offsetHeight;
			iterableTarget = origin.y;
			iterableValue = shapeRef.offsetHeight - shapeRef.offsetHeight * value;
		} else {
			if (origin.x === 'left') clip.left = shapeRef.offsetWidth;

			iterableTarget = origin.x;
			iterableValue =
				origin.x === 'right'
					? shapeRef.offsetWidth * value
					: shapeRef.offsetWidth - shapeRef.offsetWidth * value;
		}

		// Why clip and not just a width or scaleX? --> issue with a border-radius
		this.tl.to(clip, {
			[iterableTarget]: iterableValue,
			roundProps: iterableTarget,
			duration,
			ease: 'sine.out',
			onStart: () => {
				shapeRef.style.top = `${shapeRef.offsetTop - (parseFloat(marginTop) || 0)}px`;
				shapeRef.style.left = `${shapeRef.offsetLeft - (parseFloat(marginLeft) || 0)}px`;
				shapeRef.style.width = `${shapeRef.offsetWidth}px`;
				shapeRef.style.height = `${shapeRef.offsetHeight}px`;
				shapeRef.style.position = 'absolute';

				// reveal
				shapeRef.style.opacity = '1';
			},
			onUpdate: () => {
				shapeRef.style.clip = `rect(${clip.top}px, ${clip.right}px, ${clip.bottom}px, ${clip.left}px)`;
			},
		});
	};

	renderShape = () => {
		switch (this.type) {
			case SHAPE_TYPE.RECT:
				return <Rect />;
			case SHAPE_TYPE.CIRCLE:
				return null;
			case SHAPE_TYPE.CIRCLE_SVG: // has issues. not used but reserved for future needs
				return <Circle />;
			case SHAPE_TYPE.LINE:
				return <div data-shape="line" className={cn(css.lineBlock, css[`line-block-${this.lineDir}`])} />;
			case SHAPE_TYPE.LINE_SVG: // has issues. not used but reserved for future needs
				return <Line direction={this.lineDir} size={this.props.uiConfig.componentProps.other.lineSize} />;
			default:
				return <Rect />;
		}
	};

	renderEditable() {
		const props = { ...this.props.editableModeProps?.nodeProps };
		return this.renderDefault(props);
	}

	renderDefault(props: Partial<BBEditableModeProps['nodeProps']> = {}) {
		const { nodeProps } = this.props.uiConfig;
		const className = cn(css.shape, css[`shape-${this.type}`], nodeProps.className, props.className, {
			[css.hidden]: !this.context?.showResults,
		});

		return (
			<div
				{...nodeProps}
				{...this.props.stateAttrs}
				{...this.props.eventListeners}
				{...props}
				className={className}
				ref={this.shapeRef}
			>
				{this.renderShape()}
			</div>
		);
	}

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

export default withCardTransitionContext(ResultShape);
