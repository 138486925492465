import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { CardData, StorySymbols, BBModel } from 'types/story';
import { clientLog } from 'common/utils/helpers';
import { CardFacade } from 'common/utils/facades/card-facade';
import type { ITraverseTreeOptionsListeners } from 'client/components/common/BuildingBlocks/utils/traverse-tree-types';
import IframeMessageReceiver, { UpdateCardProps } from 'client/components/pages/Story/IframeMessageReceiver';
import TreeRenderer from 'client/components/pages/Story/TreeRenderer';
import {
	setStoryCardData,
	setCard,
	setEditableState,
	setStorySettings,
	setStorySymbols,
	setCmsModel,
} from 'client/actions';
import { CardRendererContext } from './context';

const log = clientLog.extend('CardRenderer');

const connector = connect(null, {
	setStoryCardData,
	setCard,
	setEditableState,
	setStorySettings,
	setStorySymbols,
	setCmsModel,
});

type State = {
	data?: CardData;
	symbols?: StorySymbols;
};

type Props = ConnectedProps<typeof connector> & {
	isEditor: boolean;
	data: CardData;
	treeOptions:
		| { target: 'default'; isCardTree: true }
		| ({ target: 'editor'; isCardTree: true } & ITraverseTreeOptionsListeners);
	symbols: StorySymbols;
	nextDefaultCardId: string;
};

class CardRenderer extends React.PureComponent<Props, State> {
	static getDerivedStateFromProps(props: Props, state: State) {
		const currentCardId = state.data?._id;
		const nextCardId = props.data._id;
		const isInitialRender = Boolean(!currentCardId && nextCardId);
		const isCardChanged = currentCardId && currentCardId !== nextCardId;

		// Update state from props only in two cases
		// 1. data provided from props 1st time (initial render)
		// 2. navigated to another card
		if (isInitialRender || isCardChanged) {
			props.setCard(props.data);
			return { data: props.data, ...(isInitialRender ? { symbols: props.symbols } : null) };
		}

		return null;
	}

	state: State = {
		/*
		 Card is rendered from state, not from props, because of,
		 there is also an option to update current card data in admin from one of the CardEditor components
		 and show updates in preview. To provide updates only here and to avoid wasted rendering a number of parents,
		 all these updates pushed here to state. (@see updateCardFromEditor)
		*/
		data: undefined,
		symbols: undefined,
	};

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevState.data?._id !== this.state.data?._id) {
			log('componentDidUpdate » card changed', { prev: prevState.data?._id, next: this.state.data?._id });
		}
	}

	// Update card from admin panel, by CardEditor components
	updateCardFromEditor = ({ data, settings, symbols, editableState, cmsModel }: UpdateCardProps) => {
		log('updateCardFromEditor', { data, settings, editableState, symbols, cmsModel });

		this.setState(
			prevState => {
				const nextState = {
					data: data || prevState.data || this.props.data,
					symbols: prevState.symbols,
				};

				if (symbols) {
					nextState.symbols = symbols;
				}

				return nextState;
			},
			() => {
				if (editableState) {
					this.props.setEditableState(editableState);
				}

				if (data) {
					this.props.setCard(data);
					this.props.setStoryCardData(data);
				}

				if (symbols) {
					this.props.setStorySymbols(symbols);
				}

				if (settings) {
					this.props.setStorySettings(settings);
				}

				if (cmsModel) {
					this.props.setCmsModel(cmsModel);
				}
			}
		);
	};

	onCollectionPaginationUpdate = (data: BBModel[]) => {
		if (!this.state.data) {
			return;
		}

		this.updateCardFromEditor({
			data: {
				...this.state.data,
				// note: it is important pass new elements array, to force update (see TreeRenderer:areEqual)
				elements: [...data] as CardData['elements'],
			},
		});
	};

	render() {
		const cardData = this.state.data as CardData;
		const card = new CardFacade(cardData);
		const { elements } = card;
		const { isEditor, nextDefaultCardId } = this.props;
		log(`render » type » %c${card.data.type}`, 'font-weight: bold;');

		return (
			<CardRendererContext.Provider value={{ card: cardData, nextDefaultCardId }}>
				<TreeRenderer symbols={this.state.symbols} elements={elements} treeOptions={this.props.treeOptions} />
				{isEditor ? <IframeMessageReceiver isCard updateCard={this.updateCardFromEditor} /> : null}
			</CardRendererContext.Provider>
		);
	}
}

/**
 * It is still exported from here only for `AnswerProgress` component.
 * There is an issue in css module ordering when importing from `context.ts` directly. It is not clear why.
 * But keeping it here solves the issue temporarily. Need to find a solution for this.
 *
 * To reproduce the issue, try to import `CardRendererContext` from `context.ts` in `AnswerProgress` component,
 * but before, update `webpack.config.dev.js` to use `MiniCssExtractPlugin` instead of `style-loader` to simulate
 * production admin build locally and run `yarn start:admin`. Otherwise you'll see an issue only after deploy and
 * only in admin.
 *
 * You will see that for example Slider arrows styles are broken.
 */
export { CardRendererContext };

export default connector(CardRenderer);
