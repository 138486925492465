/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import cn from 'classnames';
import produce from 'immer';

import type { BBCommonProps } from 'types/story';

import { COMPONENT_TYPE } from 'common/constants';
import { RawImageComponent } from 'client/components/common/BuildingBlocks/Img/Img';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';

import { defaultProps } from './constants';
import { SpotlightMode } from './SpotlightMode';
import { DividerMode } from './DividerMode';
import { FadeMode } from './FadeMode';

import css from './VisualCompare.scss';

const imgCustomProps: Parameters<typeof RawImageComponent>[0]['custom'] = {
	style: { width: '100%', height: '100%' },
	isToolbarHidden: true,
	isImageEditorDisabled: true,
};

type Props = BBCommonProps;

const VisualCompare = (props: Props) => {
	const { editableModeProps: EMP, stateAttrs, isEditableMode, uiConfig } = props;

	const {
		visualCompareSrcA,
		visualCompareSrcB,
		visualCompareMode = defaultProps.visualCompareMode,
	} = uiConfig.componentProps.other;

	const renderImage = (src: string | undefined) => {
		return (
			<RawImageComponent
				{...produce(props, draft => {
					draft.uiConfig.componentProps.styles.backgroundImage = src;
				})}
				isRaw
				type={COMPONENT_TYPE.IMG}
				custom={imgCustomProps}
			/>
		);
	};

	const renderMode = () => {
		if (visualCompareMode === 'divider') {
			return (
				<DividerMode
					isEditableMode={Boolean(isEditableMode)}
					visualCompareSrcA={() => renderImage(visualCompareSrcA)}
					visualCompareSrcB={() => renderImage(visualCompareSrcB)}
				/>
			);
		}

		if (visualCompareMode === 'fade') {
			return (
				<FadeMode
					isEditableMode={Boolean(isEditableMode)}
					visualCompareSrcA={() => renderImage(visualCompareSrcA)}
					visualCompareSrcB={() => renderImage(visualCompareSrcB)}
				/>
			);
		}

		if (visualCompareMode === 'spotlight') {
			return (
				<SpotlightMode
					spotlightSize={uiConfig.componentProps.styles.visualCompareSpotlightSize}
					isEditableMode={Boolean(isEditableMode)}
					visualCompareSrcA={() => renderImage(visualCompareSrcA)}
					visualCompareSrcB={() => renderImage(visualCompareSrcB)}
				/>
			);
		}

		return <div>{visualCompareMode}</div>;
	};

	return (
		<div
			{...uiConfig.nodeProps}
			{...stateAttrs}
			{...props.eventListeners}
			{...EMP?.nodeProps}
			style={uiConfig.nodeProps?.style}
			className={cn(css.visualCompare, uiConfig.nodeProps.className, EMP?.nodeProps?.className)}
			ref={props.containerRef as React.RefObject<HTMLDivElement>}
		>
			{renderMode()}
		</div>
	);
};

export default withCardTransitionContext(VisualCompare);
