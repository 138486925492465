export function getClientCoords(
	event: MouseEvent | TouchEvent | React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>
) {
	if ('clientX' in event) {
		return { clientX: event.clientX, clientY: event.clientY };
	}

	if ('touches' in event) {
		const touch = event.touches[0] || event.changedTouches[0];
		return touch ? { clientX: touch.clientX, clientY: touch.clientY } : { clientX: 0, clientY: 0 };
	}

	return { clientX: 0, clientY: 0 };
}
