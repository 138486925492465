import {
	COMPONENT_STATES,
	COMPONENT_TYPE as TYPE,
	DEFAULT_MEDIA_QUERY_PLATFORMS,
	LAYER_TYPE,
	COMPONENT_DISPLAY_NAME,
} from 'common/constants';
import slide1 from 'common/assets/images/slide-1.jpg';
import slide2 from 'common/assets/images/slide-2.jpg';
import { generateElementId, generateNodeId } from 'common/utils/generate-id';
import { OTHER_PROPS } from 'common/constants/component-props';
import View from './VisualCompare';
import { defaultProps } from './constants';

export const VISUAL_COMPARE = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.VISUAL_COMPARE;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: false,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						styles: {},
						other: {
							[COMPONENT_STATES.DEFAULT]: {
								[DEFAULT_MEDIA_QUERY_PLATFORMS.DEFAULT]: {
									[OTHER_PROPS.visualCompare.visualCompareSrcA]: slide1,
									[OTHER_PROPS.visualCompare.visualCompareSrcB]: slide2,
									[OTHER_PROPS.visualCompare.visualCompareMode]: defaultProps.visualCompareMode,
								},
							},
						},
					},
				},
				children: [],
			};
		},
	};
};
