import { CSS_PROPS, OTHER_PROPS } from 'common/constants/component-props';
import { createSpotlightSize, createSpotlightBlur, spotlightConfig } from './utils';

export const VISUAL_COMPARE_MODE = {
	divider: 'divider',
	fade: 'fade',
	spotlight: 'spotlight',
} as const;

type ObjectValues<T> = T[keyof T];

export type VisualCompareMode = ObjectValues<typeof VISUAL_COMPARE_MODE>;

export const visualCompareDataSelector = {
	lineSize: 'visual-compare-line-size',
	lineFill: 'visual-compare-line-styles',
	handleSize: 'visual-compare-handle-size',
	handleFill: 'visual-compare-handle-fill',
	spotlight: 'visual-compare-spotlight',
};

export const defaultProps = {
	[OTHER_PROPS.visualCompare.visualCompareMode]: VISUAL_COMPARE_MODE.divider,
	[CSS_PROPS.visualCompare.visualCompareSpotlightSize]: createSpotlightSize(spotlightConfig.size),
	[CSS_PROPS.visualCompare.visualCompareSpotlightBlur]: createSpotlightBlur(spotlightConfig.fadeIntensity),
};
