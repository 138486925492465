import React from 'react';

import { MODAL } from 'admin/constants/common';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import t from 'utils/translate';

import css from './DeleteCardModal.scss';

export type DeleteCardModalData = {
	cardId: string;
	stepId: string;
	onSave: (data: { cardId: string; stepId: string }) => void;
};

const DeleteCardModal: React.FC<ModalManagerProvidedProps<MODAL.DELETE_CARD>> = props => {
	const { open } = props;
	const Title = (
		<Text size={Text.size.subtitle} weight={Text.weight.semibold} compact text={t('deleteCardModal.title')} />
	);

	const onOk = async () => {
		const { stepId, cardId, onSave } = props.data;
		onSave({ stepId, cardId });
		props.close();
	};

	return (
		<Modal title={Title} open={open} destroyOnClose width={564} className={css.modal} maskColor="dark">
			<ModalBody className={css.body}>
				<Text size={Text.size.paragraph} text={t('deleteCardModal.statsText')} />
			</ModalBody>
			<ModalFooter>
				<Button view="primary" onClick={props.close}>
					{t('deleteCardModal.discard')}
				</Button>
				<Button view="secondary-danger" onClick={onOk}>
					{t('deleteCardModal.confirm')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default DeleteCardModal;
