import React, { forwardRef, HTMLAttributes } from 'react';
import Loader from 'common/components/Loader';

type Props = {
	src: string;
	lowQualitySrc?: string;
	alt?: string;
} & HTMLAttributes<HTMLImageElement>;

const ImageLoadable = forwardRef<HTMLImageElement, Props>(({ src, alt = '', lowQualitySrc, ...props }: Props, ref) => {
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [isMounted, setIsMounted] = React.useState(false);

	React.useEffect(() => {
		setIsMounted(true);
	}, []);

	return (
		<>
			{!isLoaded && (
				<>
					{lowQualitySrc ? (
						<img
							{...props}
							src={isMounted ? lowQualitySrc : undefined}
							alt={alt}
							draggable={false}
							loading="lazy"
							style={{ position: 'absolute', top: 0, left: 0 }}
						/>
					) : (
						<Loader color="black" fullFrame bgColor="rgba(255, 255, 255, 0.18)" />
					)}
				</>
			)}
			<img
				{...props}
				src={isMounted ? src : undefined}
				alt={alt}
				ref={ref}
				draggable={false}
				loading="lazy"
				onLoad={() => setIsLoaded(true)}
			/>
		</>
	);
});

export default React.memo(ImageLoadable);
