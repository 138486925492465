import cn from 'classnames';
import React, { ElementType } from 'react';
import { createRoot } from 'react-dom/client';
import { invoke, merge, set } from 'lodash';
import ContentEditable from 'react-contenteditable';
import { connect, ConnectedProps } from 'react-redux';

import { location } from 'common/utils/url-helper';
import { analytics } from 'common/utils/analytics';
import { isLayerType } from 'common/utils/blocks/is-layer-type';
import { CardFacade, StoryFacade } from 'common/utils/facades';
import { pastePlainText, sleep, triggerFocus } from 'common/utils/helpers';
import { CARD_TYPE, COMPONENT_STATES, COMPONENT_TYPE } from 'common/constants';
import { UNIFORM_PROPS } from 'common/constants/component-props';

import { StoryHistory } from 'client/utils';
import { ClientReducerState } from 'client/reducers';
import { getCardVotes, sendForm, sendAnswer, sendSortableAnswer } from 'client/actions/api';
import { selectCard } from 'client/reducers/card/selectors';
import { updateAnswers } from 'client/actions/update-answers';
import { DIAL_CODE_DATA_ATTR, phoneToE164 } from 'client/components/common/PhoneInput/common';
import { setFormData, SetFormDataPayload } from 'client/actions/set-form-data';
import { transmitToAdminBbUpdate } from 'client/utils/transmit-to-admin-bb-update';
import { selectStory, selectStoryCardSettings } from 'client/reducers/story/selectors';
import { ScriptRenderer } from 'client/components/common/ScriptRenderer/ScriptRenderer';
import { selectTotalAnswersByCard, selectUserAnswersByCard } from 'client/reducers/user/selectors';
import { BBCommonProps, BBEditableModeProps, CardData, EditorMode, StoryModel, StorySettingsOfCard } from 'types/story';
import { EventProviderContextT, withEventProvider } from 'client/components/pages/Story/EventProvider/Context';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { withFormContext } from 'client/components/common/StoryCard/Form/withFormContext';
import { FIELD_DATA_ATTR, FieldBBType } from 'client/components/common/BuildingBlocks/Fields/constants';
import { TimerEvEm } from 'client/components/common/BuildingBlocks/Timer/utils';
import { LottieEvEm } from 'client/components/common/BuildingBlocks/Lottie/utils';
import { AnimationEvEm } from 'client/components/common/BuildingBlocks/animation';
import { FormDataT } from 'client/components/common/StoryCard/Form/types';
import { CHILDREN_KEY, getElementIdByNodeId, getLinkData, getLinkProps, scrollIntoViewById } from '../utils/common';
import { DEFAULT_NAV_TIMEOUT } from './constants';
import css from './Button.scss';

const { isPreview } = location.client;

const connector = connect(
	(state: ClientReducerState) => {
		const story = selectStory(state);
		const SF = new StoryFacade(story as StoryModel);
		const card = selectCard(state) as CardData;
		const CF = new CardFacade(card);
		const showResults = CF.getShowResults(SF.settings);
		const storyCardSettings = selectStoryCardSettings(state, CF.cardId);

		return {
			cardSettings: storyCardSettings,
			storyId: SF.storyId,
			cardType: CF.type,
			cardId: CF.cardId,
			userAnswers: selectUserAnswersByCard(state, CF.cardId),
			answersMin: CardFacade.getAnswersMin(CF.type, storyCardSettings),
			answersMax: CardFacade.getAnswersMax(CF.type, storyCardSettings),
			answersSelected: selectTotalAnswersByCard(state),
			forceSubmitBtn: CF.getForceSubmitButton(SF.settings),
			integrationsUrlParams: SF.settings.integrations?.urlParams?.params,
			showResults,
		};
	},
	{ sendForm, getCardVotes, updateAnswers, sendAnswer, sendSortableAnswer, setFormData }
);

type State = {
	html: string;
	editing: boolean;
	submitted: boolean;
};

type OwnProps = Exclude<BBCommonProps, 'children'> & { className?: string; children: string };
type ReduxProps = ConnectedProps<typeof connector>;
type Props = OwnProps & ReduxProps & EventProviderContextT;

const toPlainText = (v: string) => v?.replace(/<[^>]*>?/gm, '');

class Button extends React.Component<Props, State> {
	editableRef = React.createRef<HTMLElement>();

	isClicked = false;

	state = {
		html: this.props.children as string,
		editing: false,
		submitted: false,
	};

	componentDidUpdate = (prevProps: Props) => {
		const { cardId } = this.props;

		if (cardId !== prevProps.cardId && isLayerType(this.props).global) {
			this.isClicked = false;
		}

		const isChildrenChanged = prevProps.children !== this.props.children;
		const isPlatformChanged = prevProps.currentMediaQuery !== this.props.currentMediaQuery;
		if ((isChildrenChanged || isPlatformChanged) && this.props.children !== this.state.html) {
			this.setState({ html: this.props.children });
		}
	};

	onEditableBlur = event => {
		const { path } = event.target.dataset;
		const { currentMediaQuery } = this.props;

		// NOTE: children is always writes to defaultState, but for certain media query
		transmitToAdminBbUpdate({
			id: Button.name,
			path: `elements.${path}.${CHILDREN_KEY}.${COMPONENT_STATES.DEFAULT}.${currentMediaQuery}`,
			value: this.state.html,
			isStory: isLayerType(this.props).global,
		});

		this.setState({ editing: false });
	};

	onEditableChange = event => {
		this.setState({ html: toPlainText(event.target.value) });
		// trigger SelectionHint update to set current editable node sizes
		triggerFocus(this.editableRef.current);
	};

	onEditableDoubleClick = () => {
		this.setState({ editing: true }, () => {
			// update SelectionHint and place cursor into contenteditable block
			triggerFocus(this.editableRef.current);
		});
	};

	get eventListeners() {
		if (this.props.isEditableMode) {
			return {};
		}

		return {
			onClick: async (event: React.MouseEvent<HTMLLinkElement | HTMLButtonElement>) => {
				const { storyId, cardId, uiConfig } = this.props;
				const { internalLink, externalLink, scrollTo } = getLinkData({ uiConfig, cardId });

				if (location.client.isPreview && externalLink && !uiConfig.componentProps.btnTarget) {
					event.preventDefault();
				}

				if (this.isClicked && !scrollTo.currentPageBlockId) {
					return;
				}

				this.isClicked = true;

				analytics.onLinkClick({
					storyId,
					cardId,
					name: uiConfig.editorProps.name,
				});

				invoke(this.props.eventListeners, 'onClick', event);

				if (scrollTo.currentPageBlockId) {
					scrollIntoViewById(scrollTo.currentPageBlockId, { hash: true });
					return;
				}

				if (event.currentTarget.tagName === 'A' && !internalLink) {
					// it is external link, any event shouldn't be called
					return;
				}

				const handleFormSubmit = async (): Promise<boolean> => {
					type FieldType = HTMLInputElement | HTMLTextAreaElement;
					const nodes = document.querySelectorAll<FieldType>(`[${FIELD_DATA_ATTR}]`);
					const fields = [...nodes].filter(isVisible);
					const formData = getFormData(fields, this.props.cardSettings ?? {});

					const { isValid } = await this.props.formContext?.validate?.(formData)!;

					if (!isValid) {
						this.isClicked = false;
						return false;
					}

					TimerEvEm.emit('pause', { cardId });
					LottieEvEm.emit('playback', { action: 'onFormSubmit' });
					AnimationEvEm.emit('playback', { action: 'onFormSubmit' });

					try {
						this.setState({ submitted: true });
						// store form data in DB
						this.props.sendForm({
							storyId,
							cardId,
							formData: Object.values(formData),
						});

						const data = Object.entries(formData).reduce(
							(acc, [fieldId, value]) => {
								acc[fieldId] = value.data;
								return acc;
							},
							{} as SetFormDataPayload['data']
						);

						// store form data in redux store
						this.props.setFormData({ cardId, data });

						// collect submitted form data in local storage
						const sHistory = new StoryHistory({ storyId });
						sHistory.addFormData({ cardId, data });
					} catch {
						this.setState({ submitted: false });
					}

					return true;
				};

				const handleSortablePollSubmit = async (): Promise<boolean> => {
					if (!this.props.userAnswers) {
						return false;
					}
					TimerEvEm.emit('pause', { cardId });
					LottieEvEm.emit('playback', { action: 'onMultipleAnswerSubmit' });
					AnimationEvEm.emit('playback', { action: 'onMultipleAnswerSubmit' });

					const { selected } = this.props.userAnswers;
					this.setState({ submitted: true });

					try {
						// submit selected answers to backend
						if (!isPreview)
							this.props.sendSortableAnswer({ type: 'complete', cardId, storyId, items: selected });

						// Store submit in redux
						this.props.updateAnswers({ cardId, submitted: true });
						// collect submitted answer in extra storage
						const sHistory = new StoryHistory({ storyId });
						sHistory.addAnswer({ cardId, selectedAnswers: selected.map(id => ({ id })) });
					} catch {
						this.setState({ submitted: false });
					}

					return true;
				};

				const handleWithSubmitRequired = async (): Promise<boolean> => {
					if (!this.props.userAnswers) {
						return false;
					}
					const { selected: selectedAnswerIds, data: selectedAnswersData } = this.props.userAnswers;
					const selectedAnswers = selectedAnswerIds.reduce(
						(acc, answerId) => {
							acc[answerId] = { ...selectedAnswersData?.[answerId] };
							return acc;
						},
						{} as { [answerId: string]: { textContent?: string; embedCode?: string } }
					);

					// Stop timer component
					TimerEvEm.emit('pause', { cardId });
					LottieEvEm.emit('playback', { action: 'onMultipleAnswerSubmit' });
					AnimationEvEm.emit('playback', { action: 'onMultipleAnswerSubmit' });

					// Store votes and submit in redux
					this.props.updateAnswers({ cardId, submitted: true });

					const sHistory = new StoryHistory({ storyId });

					// collect submitted answer in extra storage
					sHistory.addAnswer({
						cardId,
						selectedAnswers: Object.keys(selectedAnswers).map(id => ({ id })),
					});

					Object.entries(selectedAnswers).forEach(([answerId, { embedCode }]) => {
						// render pixels for each answer
						if (!embedCode) return;
						const container = document.createElement('div');
						container.id = `answer-script-${cardId}-${answerId}`;
						document.body.appendChild(container);
						const root = createRoot(container);
						root.render(<ScriptRenderer value={embedCode} scriptOnly />);
					});

					// submit selected answers to backend and get user votes
					// let votes: NonNullable<Props['userAnswers']>['votes'];
					try {
						if (!isPreview) {
							this.props.sendAnswer({
								storyId,
								cardId,
								answerIds: selectedAnswerIds,
								answerText: selectedAnswerIds.map(id => selectedAnswersData?.[id]?.textContent ?? ''),
							});

							// Update votes after marking the chosen answer, as this operation might be time-consuming
							if (this.props.showResults) {
								const votes = await this.props.getCardVotes({ storyId, cardId });
								if (votes) this.props.updateAnswers({ cardId, votes });
							}
						}
						this.setState({ submitted: true });
					} catch {
						this.setState({ submitted: false });
					}

					return true;
				};

				const handlers: Array<{
					check: (props: Props) => boolean;
					handler: () => Promise<boolean>;
					navTimeout: boolean;
				}> = [
					{ check: isFormSubmit, handler: handleFormSubmit, navTimeout: true },
					{ check: isSortablePollSubmit, handler: handleSortablePollSubmit, navTimeout: true },
					{ check: isMultipleAnswerSubmit, handler: handleWithSubmitRequired, navTimeout: true },
					{ check: isForceSubmit, handler: handleWithSubmitRequired, navTimeout: true },
					{ check: isSingleAnswerSubmit, handler: async () => false, navTimeout: true },
				];

				const matchedHandler = handlers.find(({ check }) => check(this.props));

				if (matchedHandler) {
					const success = await matchedHandler.handler();
					if (!success) return;
				}

				const defaultTimeout = matchedHandler?.navTimeout ? DEFAULT_NAV_TIMEOUT : 0;
				const timeoutProp = parseFloat(`${uiConfig.componentProps.answerTimeout}`);
				const redirectTimeout = (Number.isNaN(timeoutProp) ? defaultTimeout : timeoutProp) * 1000;
				await sleep(redirectTimeout);

				this.props.eventProvider.events.flow(internalLink || this.props._id, {
					isDirectId: !!internalLink,
					scrollTo: scrollTo.anotherPageBlockId,
				});
			},
		};
	}

	get isDisabled() {
		if (isFormSubmit(this.props)) return this.state.submitted;
		if (isMultipleAnswerSubmit(this.props) || isSortablePollSubmit(this.props) || isForceSubmit(this.props))
			return this.props.userAnswers?.submitted;
		return isSingleAnswerSubmit(this.props);
	}

	get isInactive() {
		if (isSortablePollSubmit(this.props)) return false;
		if (isForceSubmit(this.props)) return this.props.answersSelected < this.props.answersMin;
		if (isMultipleAnswerSubmit(this.props)) return this.props.answersSelected < this.props.answersMin;
		return isSingleAnswerSubmit(this.props);
	}

	get isHidden() {
		const isLastCard = !this.props.eventProvider.nextDefaultCardId;
		return isLastCard && this.props.type === COMPONENT_TYPE.BUTTON_SUBMIT && !!this.props.userAnswers?.submitted;
	}

	renderEditable() {
		const { editableModeProps, uiConfig } = this.props;
		const nodeProps = merge({}, uiConfig.nodeProps, editableModeProps?.nodeProps);
		const isContentMode = this.props.editorMode === EditorMode.CONTENT;

		/**
		 * Why do we assign "updater" to className?
		 * This is a temporary fix, to update <ContentEditable> in case of
		 * some state was provided via nodeProps. Otherwise <ContentEditable> won't be
		 * updated, because of his own shouldComponentUpdate logic, will return false
		 * if there were changed some data attributes only.
		 */
		let updater = '';
		const updaterObj = {
			...nodeProps,
			...this.props.stateAttrs,
			...(isContentMode ? { 'data-content-mode': true } : null),
		};
		Object.keys(updaterObj).forEach(k => {
			const res = k.match(/^(data-)/);
			if (res) updater += `${k}=${updaterObj[k]}`;
		});

		return (
			<ContentEditable
				{...nodeProps}
				{...this.props.stateAttrs}
				{...this.props.eventListeners}
				innerRef={this.editableRef}
				className={cn(css.btn, css.editable, uiConfig.nodeProps.className, updater)}
				tagName="div"
				html={this.state.html}
				disabled={!this.state.editing}
				onDoubleClick={this.onEditableDoubleClick}
				onChange={this.onEditableChange}
				onBlur={this.onEditableBlur}
				onPaste={pastePlainText}
				{...(isContentMode
					? {
							onClick: e => {
								this.props.eventListeners?.onClick?.(e);
								this.onEditableDoubleClick();
							},
						}
					: null)}
			/>
		);
	}

	renderDefault(props: Partial<BBEditableModeProps['nodeProps']> = {}) {
		const { isInactive, isDisabled, isHidden } = this;
		const { nodeProps } = this.props.uiConfig;
		const { Component, ...componentProps } = getComponent(this.props);

		return (
			<Component
				{...componentProps}
				{...nodeProps}
				{...this.props.stateAttrs}
				{...this.props.eventListeners}
				{...this.eventListeners}
				{...props}
				style={{ ...nodeProps.style, ...props.style, ...(isHidden ? { visibility: 'hidden' } : null) }}
				className={cn(css.btn, nodeProps.className, props.className, {
					[css.inactive]: isInactive,
				})}
				/* eslint-disable-next-line react/no-danger */
				dangerouslySetInnerHTML={{ __html: this.state.html }}
				ref={this.props.containerRef}
				disabled={isDisabled || isInactive}
			/>
		);
	}

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

export function isFormSubmit(props: Pick<Props, 'uiConfig' | 'cardType'>) {
	// check UNIFORM_PROPS.btnSubmit instead of component.type to support old stories
	return !!props.uiConfig.componentProps[UNIFORM_PROPS.btnSubmit] && props.cardType === CARD_TYPE.FORM;
}

export function isSortablePollSubmit(props: Pick<Props, 'cardType' | 'type'>) {
	return props.type === COMPONENT_TYPE.BUTTON_SUBMIT && props.cardType === CARD_TYPE.SORTABLE_POLL;
}

export function isAnswerSubmit(props: Pick<Props, 'cardType' | 'type'>) {
	return props.type === COMPONENT_TYPE.BUTTON_SUBMIT && CardFacade.hasFeature(props.cardType).answer;
}

export function isMultipleAnswerSubmit(props: Pick<Props, 'cardType' | 'answersMax' | 'type'>) {
	return isAnswerSubmit(props) && props.answersMax > 1;
}

export function isSingleAnswerSubmit(props: Pick<Props, 'cardType' | 'answersMax' | 'type'>) {
	return isAnswerSubmit(props) && props.answersMax === 1;
}

export function isForceSubmit(props: Pick<Props, 'cardType' | 'answersMax' | 'type' | 'forceSubmitBtn'>) {
	return isAnswerSubmit(props) && props.forceSubmitBtn;
}

function isVisible(e: HTMLInputElement | HTMLTextAreaElement) {
	// reject visually hidden fields (no size, display: none, etc.)
	return Boolean(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
}

function getComponent(props: Props) {
	const externalLink = props.uiConfig.componentProps[UNIFORM_PROPS.btnLink];

	if (externalLink && !isFormSubmit(props)) {
		return getLinkProps(props.uiConfig, { link: externalLink, integrationUrlParams: props.integrationsUrlParams });
	}

	return {
		Component: 'button' as ElementType,
		type: 'button',
	};
}

function getFormData(fields: (HTMLInputElement | HTMLTextAreaElement)[], cardSettings: StorySettingsOfCard) {
	return fields.reduce<FormDataT>((memo, field) => {
		const { value } = field;
		const type = field.type === FieldBBType.hidden ? field.dataset['data-field-type'] : field.type;
		const checked = 'checked' in field ? field.checked : undefined;
		const nodeId = field.dataset.bb ? field.id : field.closest('[data-bb]')?.id;
		const id = getElementIdByNodeId(nodeId ?? '') ?? '';
		const name = cardSettings.input?.[id]?.name || field.name;
		const dialCode = field.getAttribute(DIAL_CODE_DATA_ATTR) ?? '';

		set(memo, id, {
			name,
			type,
			data:
				type === 'tel'
					? phoneToE164(
							!value
								? ''
								: value.startsWith('+')
									? value /* already in international number format */
									: dialCode
										? `+${dialCode}${value}` /* build international number format */
										: value
						)
					: value,
			attributes: {
				id,
				...(type === 'checkbox' || type === 'radio' ? { checked } : null),
			},
		});

		return memo;
	}, {});
}

const WithRedux = connector(Button);
const WithFormContext = withFormContext(WithRedux);
const WithCardTransitionContext = withCardTransitionContext(WithFormContext);

export default withEventProvider(WithCardTransitionContext);
