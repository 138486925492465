import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import type { BBCommonProps } from 'types/story';
import { EditorMode } from 'types/story';
import CrossIcon from 'common/components/Icon/Cross';
import { SEARCH_FIELD_EVENT } from 'client/components/common/BuildingBlocks/CmsRepeater/use-filter-triggers';
import type { TransitionHandlerContextType } from 'client/components/common/CardTransition/TransitionHandler';
import type { TFieldProps } from 'client/components/common/BuildingBlocks/Fields/types';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { isLayerType } from 'utils/blocks/is-layer-type';
import { transmitToAdminBbUpdate } from 'client/utils/transmit-to-admin-bb-update';
import { SelectionHintEvent } from 'client/components/common/SelectionHint/SelectionHintEvent';
import { CSS_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';
import { placeholderPath } from '../utils';
import { FIELD_DATA_ATTR, FIELD_EDITABLE_ATTR, FieldBBType } from '../constants';
import css from './SearchField.scss';

type Props = BBCommonProps & TransitionHandlerContextType;

/**
 * SearchField is a presentational component that renders a search input field.
 * By his own it does nothing, but it is used by `CmsRepeater` which gets data from the server based on the input value.
 */
const SearchField: React.FC<Props> = props => {
	const inputRef = useRef<HTMLInputElement>(null);
	const propsRef = useRef(props);
	propsRef.current = props;

	const { isEditableMode, editorMode } = props;
	const path = props.editableModeProps?.nodeProps?.['data-path'];
	const type = props.uiConfig.componentProps[UNIFORM_PROPS.fieldType] ?? FieldBBType.search;
	const name = 'q';
	const phColor: string | undefined = props.uiConfig.componentProps.styles[CSS_PROPS.field.style.text.phColor];
	const isGlobal = isLayerType(props).global;
	const [readOnly, setReadOnly] = useState(isEditableMode);
	const [pending, setPending] = useState(false);
	const providedPlaceholder = props.uiConfig.componentProps[UNIFORM_PROPS.fieldPlaceholder];
	const [placeholder, setPlaceholder] = useState(providedPlaceholder);

	// list of properties to enable placeholder inline editing
	const editableModeInputProps: Partial<TFieldProps> = useMemo(() => {
		if (!isEditableMode) return {};

		return {
			...(readOnly ? null : { [FIELD_EDITABLE_ATTR]: '' /* necessarily prevent component drag */ }),
			value: readOnly ? '' : placeholder ?? '',
			style: { color: phColor },
			[editorMode === EditorMode.CONTENT ? 'onClick' : 'onDoubleClick']: () => {
				setReadOnly(false);
			},
			onChange: event => {
				setPlaceholder(event.target.value);
			},
			onBlur: event => {
				if (placeholder === providedPlaceholder) {
					setReadOnly(true);
					return;
				}

				transmitToAdminBbUpdate({
					id: SearchField.name,
					path: `elements.${path}.${placeholderPath}`,
					value: placeholder,
					isStory: isGlobal,
				});

				setPending(true);
			},
			onFocus: event => {
				// prevent this block from being selected when clicking on its parent
				event.stopPropagation();
			},
		};
	}, [providedPlaceholder, placeholder, readOnly, isGlobal, path, phColor, isEditableMode, editorMode]);

	// list of common input properties
	const inputProps: TFieldProps = useMemo(() => {
		return {
			className: css.field,
			type,
			name,
			placeholder,
			autoComplete: 'off',
			[FIELD_DATA_ATTR]: '',
			readOnly,
			...editableModeInputProps,
		} as const;
	}, [type, name, placeholder, readOnly, editableModeInputProps]);

	const nodeProps = {
		...props.uiConfig.nodeProps,
		...(props.editableModeProps ? props.editableModeProps.nodeProps ?? null : null),
		className: cn(
			css.searchField,
			css[inputProps.type],
			props.uiConfig.nodeProps.className,
			props.editableModeProps?.nodeProps?.className
		),
	};

	// place caret into input to start editing placeholder
	useEffect(() => {
		if (!readOnly) {
			const p = propsRef.current as WithRequired<BBCommonProps, 'editableModeProps'>;
			SelectionHintEvent.triggerFocus(p);
		}
	}, [readOnly]);

	// update placeholder with a value from props
	useEffect(() => {
		setPlaceholder(providedPlaceholder);
	}, [providedPlaceholder]);

	// ensure that editable placeholder successfully saved to complete editing mode
	useEffect(() => {
		if (providedPlaceholder === placeholder && pending) {
			setPending(false);
			setReadOnly(true);
		}
	}, [providedPlaceholder, placeholder, pending]);

	return (
		<div
			{...props.stateAttrs}
			{...props.eventListeners}
			{...nodeProps}
			ref={props.containerRef as RefObject<HTMLDivElement>}
		>
			<input {...inputProps} ref={inputRef} />

			{!props.isEditableMode && (
				<button
					type="button"
					className={css.btnClear}
					onClick={() => {
						if (inputRef.current) {
							inputRef.current.value = '';
							const changeEvent = new Event(SEARCH_FIELD_EVENT, { bubbles: true });
							inputRef.current.dispatchEvent(changeEvent);
						}
					}}
				>
					<span className={css.btnClearBody}>
						<CrossIcon color="currentColor" />
					</span>
				</button>
			)}
		</div>
	);
};

export default withCardTransitionContext(SearchField);
