import { CmsFilter, CmsFilterOperator } from 'types/cms';
import { useEffect, useReducer } from 'react';
import { getElementById } from 'client/components/common/BuildingBlocks/utils/common';
import css from './CmsRepeater.scss';

type Condition = {
	field: string;
	value: string;
	operator?: CmsFilterOperator;
};

type FilterReducerState = {
	search: Condition | null;
	filter: Condition | null;
};

type FilterReducerAction =
	| { type: 'search'; payload: Condition | null }
	| { type: 'filter'; payload: Condition | null };

export function useFilterReducer(initialFilters: CmsFilter[]) {
	return useReducer(
		(state: FilterReducerState, action: FilterReducerAction) => {
			switch (action.type) {
				case 'search':
					return { ...state, search: action.payload };
				case 'filter':
					return { ...state, filter: action.payload };
				default:
					return state;
			}
		},
		initialFilters.reduce(
			(acc, filter) => {
				if (filter.type === 'default') {
					acc.filter = { field: filter.field, value: filter.value, operator: filter.operator };
				}
				return acc;
			},
			{ search: null, filter: null } as FilterReducerState
		)
	);
}

export const SEARCH_FIELD_EVENT = 'change';

interface FilterTriggerListenersParams {
	filters: CmsFilter[];
	setFilters: (action: FilterReducerAction) => void;
}

// Add event listeners for filter field
export function useFilterTriggers({ filters, setFilters }: FilterTriggerListenersParams) {
	useEffect(() => {
		const controller = new AbortController();

		filters.forEach(addEventListener);

		function addEventListener(filter: CmsFilter) {
			switch (filter.type) {
				case 'search': {
					const input = getElementById(filter.componentId)?.querySelector('input');
					input?.addEventListener(
						SEARCH_FIELD_EVENT,
						event => {
							const value = (event.target as HTMLInputElement).value.trim();
							setFilters({
								type: 'search',
								payload: value ? { field: filter.field, value, operator: filter.operator } : null,
							});
						},
						{ signal: controller.signal }
					);
					break;
				}
				case 'filter': {
					const el = getElementById(filter.componentId);
					el?.classList.add(css.filterTrigger);
					el?.addEventListener('click', event => onFilterChange(event, filter), {
						signal: controller.signal,
						capture: true,
					});
					break;
				}
				case 'default':
					break;
				default:
					break;
			}
		}

		function onFilterChange(event: Event, filter: Extract<CmsFilter, { type: 'filter' }>) {
			const target = event.currentTarget as HTMLElement;
			// prevent target action, for example prevent Button to navigate
			event.stopPropagation();

			const isActive = target.classList.contains(css.active);

			if (isActive) {
				target.classList.remove(css.active);
			} else {
				resetActiveFilter();
				target.classList.add(css.active);
			}

			const payload = isActive ? null : { field: filter.field, value: filter.value, operator: filter.operator };
			setFilters({ type: 'filter', payload });
		}

		function resetActiveFilter() {
			filters.forEach(filter => {
				if (filter.type === 'filter') {
					const el = getElementById(filter.componentId);
					el?.classList.remove(css.active);
				}
			});
		}

		return () => controller.abort();
	}, [filters, setFilters]);

	return null;
}
