import React from 'react';

import t from 'utils/translate';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import TwoFAConfirmationForm, { type FormValues } from 'admin/components/common/TwoFAConfirmationForm';
import { Column, Grid } from 'admin/components/common/Grid';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { MODAL } from 'admin/constants/common';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';

import dayjs from 'dayjs';
import { useAdminDispatch } from 'admin/reducers';
import { auth, setModal } from 'src/routes/admin/actions';
import css from './MobileAppAuthenticatorBackupCodesModal.scss';

type Props = ModalManagerProvidedProps<MODAL.TWO_FA_BACKUP_CODES>;

export type MobileAppAuthenticatorBackupCodesModalData = {
	title?: string;
	codes: string[];
};

const MobileAppAuthenticatorBackupCodesModal: React.FC<Props> = props => {
	const { open, close, data } = props;
	const [view, setView] = React.useState<'codes' | 'regenerate'>('codes');
	const [submitting, setSubmitting] = React.useState(false);
	const [dirty, setDirty] = React.useState(false);
	const formId = React.useId();
	const dispatch = useAdminDispatch();
	const title = (
		<Text
			size={Text.size.subtitle}
			weight={Text.weight.semibold}
			compact
			text={data.title ?? t('profile.2fa.modal.backupCodes.title')}
		/>
	);

	const onDownloadCodesClick = () => {
		const csvContent = data?.codes.map(code => `"${code}"`).join('\n') || '';
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		const url = URL.createObjectURL(blob);

		link.setAttribute('href', url);
		link.setAttribute('download', `SC_BACKUP_CODES_${dayjs(Date.now()).format('YYYY-MM-DD_HH-mm-ss')}.csv`);

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
	};

	const onRegenerateCodesClick = () => {
		setView('regenerate');
	};

	const onTwoFAConfirmationFormSubmit = async (values: FormValues) => {
		await dispatch(auth.post.totpRecoveryCodes({ code: values.code, password: values.password }));
		const { result } = await dispatch(auth.get.totp());

		dispatch(setModal({ id: MODAL.TWO_FA_BACKUP_CODES, data: { codes: result?.totp.recoveryCodes! } }));
		setView('codes');
	};

	const onTwoFAConfirmationFormStateChange = (s: boolean, d: boolean) => {
		setSubmitting(s);
		setDirty(d);
	};

	const renderView = () => {
		switch (view) {
			case 'codes': {
				return (
					<>
						<ModalBody>
							<Grid columns="12" columnGap="large">
								<Column colSpan="12">
									<Text className={css.text} size="paragraph">
										{t('profile.2fa.modal.backupCodes.description')}
									</Text>
									<div className={css.codes}>
										{data?.codes.length ? (
											data?.codes.map(code => {
												return (
													<div key={code} className={css.code}>
														{code}
													</div>
												);
											})
										) : (
											<Text className={css.noCodesText} size="paragraph" tag="div">
												{t('profile.2fa.modal.backupCodes.noCodes')}
											</Text>
										)}
									</div>
									<div className={css.actions}>
										{data?.codes.length > 0 && (
											<button type="button" className={css.action} onClick={onDownloadCodesClick}>
												<Text size="paragraph" tag="span">
													{t('profile.2fa.modal.backupCodes.download')}
												</Text>
											</button>
										)}
										<button type="button" className={css.action} onClick={onRegenerateCodesClick}>
											<Text size="paragraph" tag="span">
												{t('profile.2fa.modal.backupCodes.regenerate')}
											</Text>
										</button>
									</div>
								</Column>
							</Grid>
						</ModalBody>

						<ModalFooter>
							<Button onClick={close} view="primary">
								{t('profile.2fa.modal.backupCodes.close')}
							</Button>
						</ModalFooter>
					</>
				);
			}
			case 'regenerate': {
				return (
					<>
						<ModalBody>
							<Grid columns="12" columnGap="large">
								<Column colSpan="12">
									<Text className={css.text} size="paragraph">
										{t('profile.2fa.modal.backupCodes.regenerateDescription')}
									</Text>
									<TwoFAConfirmationForm
										id={formId}
										onSubmit={onTwoFAConfirmationFormSubmit}
										onStateChange={onTwoFAConfirmationFormStateChange}
									/>
								</Column>
							</Grid>
						</ModalBody>

						<ModalFooter>
							<Button form={formId} disabled={!dirty || submitting} view="primary" type="submit">
								{t('profile.2fa.modal.backupCodes.regenerate')}
							</Button>
							<Button disabled={submitting} onClick={close} view="secondary">
								{t('profile.2fa.modal.backupCodes.close')}
							</Button>
						</ModalFooter>
					</>
				);
			}
			default:
				return null;
		}
	};

	return (
		<Modal title={title} open={open} destroyOnClose width="640px">
			{renderView()}
		</Modal>
	);
};

export default MobileAppAuthenticatorBackupCodesModal;
