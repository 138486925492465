import { get, set, unset } from 'lodash';
import UrlHelper from 'utils/url-helper';
import { ADMIN_SESSION } from 'common/constants';
import { StoryMigrationAdapter } from 'admin/utils/story-migration-adapter';

const isV2ApiRegExp = new RegExp(`${UrlHelper.adminBackendV2}|${UrlHelper.adminBackendV2R}`, 'i');

/**
 * interceptor for api requests - adds session id and isBrowser keys
 *
 * @param {object} req - request object
 * @param {object} requestOptions - request options
 * @param {object} requestOptions.data - request data
 * @return req
 */
export function apiRequest(req, requestOptions) {
	req.withCredentials();

	set(requestOptions, ['data'], isV2ApiRegExp.test(req.url) ? requestOptions.data : { data: requestOptions.data });

	// Admin uses x-session-token. Client uses cookies
	// get existing session id when in browser
	const sessionId = localStorage.getItem(ADMIN_SESSION);

	if (sessionId) {
		set(requestOptions, ['headers', 'x-session-token'], sessionId);
	} else {
		unset(requestOptions, ['headers', 'x-session-token']);
	}

	return req;
}

/**
 * apiClientResponse - interceptor for api responses - sets session id in local storage
 *
 * @param {object} req - request object
 * @param {object} res - response object
 * @param {object} options - request options
 * @return res
 */
export function apiResponse(req, res, options) {
	// Admin uses x-session-token. Client uses cookies
	const sessionId = get(res, ['headers', 'x-session-token']);

	// store session id
	if (sessionId) {
		try {
			localStorage.setItem(ADMIN_SESSION, sessionId);
		} catch {
			console.error('unable to set id');
		}
	}

	// Story migration adapter
	if (options.storyAdapter) {
		if (Array.isArray(res.body.result)) {
			res.body.result = res.body.result.map(story => {
				return new StoryMigrationAdapter({ story }).adapt();
			});
		} else if (res.body.result) {
			res.body.result = new StoryMigrationAdapter({ story: res.body.result }).adapt();
		}
	}

	return res;
}
