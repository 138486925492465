import type { ElementType } from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import kebabCase from 'lodash/kebabCase';
import { BBStates, BBUiConfig, BBCommonProps, BBModel } from 'types/story';
import type { IOrganizationIntegrations } from 'types/organization';
import {
	COMPONENT_STATES,
	COMPONENT_TYPE,
	COMPONENT_TYPE as TYPE,
	EXPOSE_DEFAULT_DURATION,
	TRANSITION_TYPE,
} from 'common/constants';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { NODE_ID_SEPARATOR, generateNodeId } from 'utils/generate-id';
import { prependUrlProtocol } from 'utils/helpers';

export const CHILDREN_KEY = 'children';

// component types which you cannot remove
export const UNREMOVABLE_TYPES = [
	TYPE.CARD,
	TYPE.CONTENT,
	TYPE.FLOAT_ABOVE,
	TYPE.FLOAT_BELOW,
	TYPE.RESULT_SHAPE,
	TYPE.RESULT_TEXT,
	TYPE.SLIDER_ARROW,
	TYPE.SLIDER_SLIDES,
	TYPE.SLIDER_PAGINATION,
];

// component type for which is able to create some event in a "Flow" screen
export const TYPES_WITH_EVENT = [TYPE.ANSWER, TYPE.BUTTON, TYPE.BUTTON_SUBMIT, TYPE.TIMER, TYPE.COUNTER] as string[];

export const TRAVERSE_TREE_TARGETS = {
	EDITOR: 'editor',
	DEFAULT: 'default',
} as const;

export const getElementIdByNodeId = (id: BBModel['uiConfig']['nodeProps']['id']): string | undefined => {
	if (!id) return undefined;

	const separatorIndex = id.indexOf(NODE_ID_SEPARATOR);
	if (separatorIndex === -1) return undefined;

	return id.substring(separatorIndex + 1) || undefined;
};

export const getElementById = (id: BBModel['_id'], type?: BBModel['type']) => {
	if (!id) return undefined;
	if (type && type in COMPONENT_TYPE) return document.getElementById(generateNodeId(id, type));
	return document.querySelector(`[id$="${NODE_ID_SEPARATOR}${id}"]`);
};

export function getExposeParams(props: {
	exposeEffect?: ValuesType<typeof TRANSITION_TYPE>;
	exposeDuration?: number;
	in?: boolean;
	bbType?: string;
}) {
	const exposeEffect = props.exposeEffect || TRANSITION_TYPE.FADE;
	const cardExposeDuration = (props.exposeDuration || 0) * 0.5;

	if (props.bbType === COMPONENT_TYPE.CARD) {
		return {
			duration: cardExposeDuration,
			delay: 0,
			effect: exposeEffect,
		};
	}

	const exposeInDuration = cardExposeDuration * 0.6;
	const exposeOutDuration = exposeInDuration;
	const exposeInDelay = Math.max(0, cardExposeDuration - exposeOutDuration);
	const exposeOutDelay = 0;

	return {
		duration: props.in ? exposeInDuration : exposeOutDuration,
		delay: props.in ? exposeInDelay : exposeOutDelay,
		effect: exposeEffect,
	};
}

export function parseExposeDuration(value: string) {
	const number = parseFloat(value);
	return (Number.isNaN(number) ? EXPOSE_DEFAULT_DURATION : number) * 1000;
}

// Image | Button BB: [btnLink property]
export function getLinkProps(
	uiConfig: BBUiConfig,
	{
		link = '',
		integrationUrlParams,
	}: { link: string; integrationUrlParams: NonNullable<IOrganizationIntegrations['urlParams']>['params'] }
) {
	const target = uiConfig.componentProps[UNIFORM_PROPS.btnTarget];
	const urlWithProtocol = prependUrlProtocol(link);
	let targetUrl = urlWithProtocol;

	try {
		const url = new URL(targetUrl);
		const currentUrl = new URL(window.location.href);

		if (integrationUrlParams) {
			integrationUrlParams.forEach(paramKey => {
				const paramValue = currentUrl.searchParams.get(paramKey);

				if (paramValue && paramKey !== 'lockscreen') {
					url.searchParams.set(paramKey, paramValue);
				}
			});
		}

		targetUrl = url.toString();
	} catch {
		targetUrl = urlWithProtocol;
	}

	return {
		Component: 'a' as ElementType,
		href: /^(tel|mailto):/i.test(link) ? link : targetUrl,
		target: target ? '_blank' : '_self',
		rel: target ? 'noopener noreferrer' : undefined,
	};
}

export const scrollIntoViewById = (id = '', options?: Partial<{ hash: boolean }>) => {
	if (!id) return;

	if (options?.hash) {
		window.location.hash = id;
	}

	document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export function getLinkData({ uiConfig, cardId }: { uiConfig: BBUiConfig; cardId: string }) {
	const externalLink = uiConfig.componentProps[UNIFORM_PROPS.btnLink];
	const internalLink = externalLink ? undefined : uiConfig.componentProps[UNIFORM_PROPS.btnNavLink];
	const btnBlockLink = internalLink ? uiConfig.componentProps[UNIFORM_PROPS.btnBlockLink] : undefined;
	const scrollTo = {
		currentPageBlockId: internalLink === cardId ? btnBlockLink : undefined,
		anotherPageBlockId: internalLink !== cardId ? btnBlockLink : undefined,
	};

	return { externalLink, internalLink, scrollTo };
}

const kebabCaseStates = Object.values(COMPONENT_STATES).reduce(
	(acc, value) => {
		acc[value] = kebabCase(value);
		return acc;
	},
	{} as Record<BBStates, string>
);

/**
 * Get BBState represented as DOM attribute string
 */
export const getStateDOMAttr = (state: BBStates) => `data-${kebabCaseStates[state]}`;

/**
 * Generate object with state data attributes
 * to spread onto DOM element (BuildingBlock root node)
 */
export const getStateDOMAttrs = (states: Partial<Record<BBStates, boolean>>) => {
	const result: Record<string, ''> = {};

	forEach(Object.entries(states), ([key, value]) => {
		if (value) result[getStateDOMAttr(key as BBStates)] = '';
	});

	return result;
};

export function isEditableMode(componentProps: Pick<BBCommonProps, 'editableModeProps'>) {
	return !isEmpty(componentProps.editableModeProps);
}
