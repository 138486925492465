/**
 *	All requests to the api needs to send with the user session from the client.
 *	All requests from the client to the api must be done behind the scenes so that the user in the product does not
 *	feel that any request is being made and is not waiting for it, the request should be in the background.
 *	The client does not have to wait for a reply from the API and should proceed to the next screen or the next step
 *	as soon as the user has answered or taken some action.
 *	Each API request will also be accompanied by an EVENT submission to Google Analytics. We need to send this event
 *	to ga also if the the api not responding. In case the API does not respond, the RETRY process should be done
 *	as follows:
 *	—	The request will remain in the background of the browser even if it has not been successfully sent and the
 *		client will attempt the operation repeatedly up to a maximum of 3 attempts.
 *	—	The number of attempts to perform the repeat operation should be structured in a way that will be easy to
 *		change in the future if we need to.
 *	—	Each additional attempt at a repeat request must wait a random number of seconds between a request and a
 *		request that will range from 1 - 30 seconds, meaning the first return request and the second return request
 *		will be made with a different wait for them and not evenly.
 *	—	Every time we make a retry request we need to send event to ga:
 *			Event Category: Retry to api
 *			Event Action:  Retry $number -  $api_request_name
 *			Event Label: $api_request_name
 *	—	Every time the api not responding or the api answer with some error, we need to send new event to ga
 *		with the error:
 *			Event Category: error from api
 *			Event Action:  $api_request_name
 *			Event Label: $error code
 */

import type { Action } from 'redux';
import type { StoryModel, StorySettingsType } from 'types/story';
import type { ClientReducerState, ClientThunkAction } from 'client/reducers';
import type { FormDataT } from 'client/components/common/StoryCard/Form/types';
import { Endpoint } from 'common/resources/rabbi-resources';
import { clientLog } from 'utils/helpers';
import { location } from 'utils/url-helper';
import { analytics } from 'utils/analytics';
import { StoryFacade } from 'utils/facades/story-facade';
import { parseURL } from 'utils/parse-url';
import { AnswerDelta, SubmissionDelta } from 'client/utils';
import { getRestrictionsParams } from 'client/utils/get-restrictions-params';
import { API, SET_OFFLINE_MODE } from 'client/constants/actions';
import * as endpoints from 'client/resources';
import { selectUser } from 'client/reducers/user/selectors';
import { selectStory, selectStorySettings } from 'client/reducers/story/selectors';

const log = clientLog.extend('api');
const logError = clientLog.extend('api');
logError.log = console.error.bind(console);

const { isPreview } = location.client;
const NO_RESULT_IN_RESPONSE = 'no result in response';
const MAX_ATTEMPTS = 3;
const MIN_DELAY = 1 * 1000;
const MAX_DELAY = 30 * 1000;
const NO_RESPONSE_TIMEOUT = 10 * 1000;
const noResponseError = new Error('NO_RESPONSE');
const endpointMap = new Map();

// @ts-expect-error ts-migrate FIXME
noResponseError.status = 'NO_RESPONSE'; // what is normal status for 'no response'?
endpointMap.set(API.REGISTER_GUEST, endpoints.registerGuest);
endpointMap.set(API.GET_USER_DATA, endpoints.me);
endpointMap.set(API.GET_GAME, endpoints.game);
endpointMap.set(API.START_GAME, endpoints.gameStart);
endpointMap.set(API.SEND_GAME_CHECKPOINT, endpoints.gameCheckpoint);
endpointMap.set(API.SEND_ANSWER, endpoints.onSendAnswer);
endpointMap.set(API.SEND_SORTABLE_ANSWER, endpoints.onSendSortableAnswer);
endpointMap.set(API.SEND_FORM, endpoints.onSendForm);
endpointMap.set(API.SEND_VIEW_STORY_EVENT, endpoints.onEvent);
endpointMap.set(API.SEND_VIEW_CARD_EVENT, endpoints.onEvent);
endpointMap.set(API.SEND_REGISTER_EVENT, endpoints.onEvent);
endpointMap.set(API.SEND_START_STORY_EVENT, endpoints.onEvent);
endpointMap.set(API.SEND_FINISH_STORY_EVENT, endpoints.onEvent);
endpointMap.set(API.GET_CARD_VOTES, endpoints.getCardVotes);
endpointMap.set(API.SEND_VIDEO_EVENT, endpoints.sendVideoEvent);
endpointMap.set(API.INVOKE_ACTION, endpoints.invokeAction);
endpointMap.set(API.GET_ACTION_STATUS, endpoints.getActionStatus);
endpointMap.set(API.LOGOUT, endpoints.logout);

type ApiActionType = (typeof API)[keyof typeof API];

const shouldRespondWithFakeSuccess = (story: ClientReducerState['story']['story'], action: ApiActionType) => {
	const isDisabledApi = !!(story && new StoryFacade(story).settings.restrictions?.isDisabledApi);

	return story?.type === 'widget' || (isDisabledApi && action !== API.SEND_VIEW_STORY_EVENT);
};

type ClientURLParamsPayload = Record<string, string>;

/**
 * Get record with pair key/value of url search params that are listed in story integration settings
 */
const getClientURLParams = (settings: StorySettingsType): ClientURLParamsPayload | undefined => {
	const urlParams = new URLSearchParams(window.location.search);
	const result = settings.integrations?.urlParams?.params?.reduce(
		(acc, param) => {
			const value = urlParams.get(param);
			if (value !== null) acc[param] = value;
			return acc;
		},
		{} as Record<string, string>
	);

	return result && Object.keys(result).length > 0 ? result : undefined;
};

type ApiRequestParams = {
	action: ApiActionType;
	params?: {
		organizationId?: string;
		storyId?: string;
		cardId?: string;
	};
	data?: any;
	retries?: boolean;
	customEndpoint?: Endpoint;
};
/**
 * Makes an API request with retries and Google Analytics events accompanied.
 * Receives one param as an object with the following fields:
 *
 * @param {object} action - predefined action-naming object from API object ('client/constants/actions')
 * @param {object} params - params for endpoint route
 * @param {object} data - params for request
 * @param {boolean} [retries]
 * @param {*} [customEndpoint]
 */
export function apiRequest<R>({
	action,
	params,
	data,
	retries = true,
	customEndpoint,
}: ApiRequestParams): ClientThunkAction<Action, Error | boolean | R> {
	let attempt = 0;

	return async function apiRequestAttempt(dispatch, getState) {
		log('apiRequest', { attempt, action, params, data });
		const isRetry = attempt > 0;
		const endpoint = customEndpoint || endpointMap.get(action);
		const state = getState();

		if (isPreview) {
			return new Error('No /api calls in preview mode');
		}

		if (state.user.isOffline) {
			return new Error('Offline mode is active');
		}

		try {
			dispatch({ type: action.START, payload: { params, data, isRetry } });

			if (shouldRespondWithFakeSuccess(selectStory(state), action)) {
				const payload = true;
				dispatch({ type: action.SUCCESS, payload });
				return payload;
			}

			if (isRetry) {
				analytics.onApiRetry(attempt, endpoint.url);
			}

			const response: Response = await Promise.race([
				endpoint.params(params).send(data),
				new Promise((resolve, reject) => setTimeout(() => reject(noResponseError), NO_RESPONSE_TIMEOUT)),
			]);
			const { body, status }: { body: any; status: Response['status'] } = response ?? {};

			// === RESULT
			let result: R | boolean;
			if (body?.result) {
				result = body.result;
			} else if (body?.success !== undefined) {
				result = body.success;
			} else {
				result = status >= 200 && status < 400;
			}
			// === RESULT
			log('apiRequest:result', { result });

			if (result) {
				dispatch({ type: action.SUCCESS, payload: result });
				return result;
			}
			throw new Error(`${endpoint.url}: ${NO_RESULT_IN_RESPONSE}`);
		} catch (_error) {
			const error = _error as Error;
			analytics.onApiError((error as any)?.status || error?.message, endpoint.url);
			if (retries && attempt < MAX_ATTEMPTS) {
				attempt += 1;

				return new Promise(resolve => {
					const delay = MIN_DELAY + (MAX_DELAY - MIN_DELAY) * Math.random();

					setTimeout(() => resolve(apiRequestAttempt(dispatch, getState)), delay);
				});
			}
			dispatch({ type: action.FAIL, payload: error.message });
			return error;
		}
	};
}

/**
 * When new user enter to the story /  when we not have session for user
 */
export function registerGuest({ story, user }: { story: StoryModel; user: ClientReducerState['user'] }) {
	return async dispatch => {
		const storyFacade = new StoryFacade(story, StoryFacade.VERSIONS.latest);
		const cardId = parseURL(window.location.search).searchObject.cardId || storyFacade.dataByFirstCard.card?._id;

		const request = apiRequest({
			action: API.REGISTER_GUEST,
			params: { organizationId: story.organizationId },
			retries: false,
		});
		const result = await dispatch(request);

		if (result instanceof Error) {
			if (!user.isOffline) {
				analytics.onOffline(story.id, cardId, endpointMap.get(API.GET_USER_DATA).url);
			}
			dispatch({ type: SET_OFFLINE_MODE, payload: true });
		}

		return result;
	};
}

type GetUserDataProps = {
	story: StoryModel;
	user: ClientReducerState['user'];
};

type GetUserDataEmpty = boolean;

interface GetUserDataGuest {
	guest: boolean;
	id: string;
	organizationId: string;
	country: string;
}

interface GetUserDataGoogle extends GetUserDataGuest {
	email: string;
	provider: 'google';
	google: {
		id: string;
		name: string;
		email: string;
		picture: string;
	};
}

interface GetUserDataSms extends GetUserDataGuest {
	phone: string;
	provider: 'sms';
}

export type GetUserDataReturnResult = GetUserDataGuest | GetUserDataGoogle | GetUserDataSms | GetUserDataEmpty;

type SetOfflineModeAction = {
	type: typeof SET_OFFLINE_MODE;
	payload: boolean;
};

type GetUserDataAction = {
	type: ValuesType<typeof API.GET_USER_DATA>;
	payload: GetUserDataReturnResult;
};
/**
 * Get user data
 */
export function getUserData({
	story,
	user,
}: GetUserDataProps): ClientThunkAction<GetUserDataAction | SetOfflineModeAction, GetUserDataReturnResult | Error> {
	return async dispatch => {
		const storyFacade = new StoryFacade(story, StoryFacade.VERSIONS.latest);
		const cardId = parseURL(window.location.search).searchObject.cardId || storyFacade.dataByFirstCard.card?._id;

		const request = apiRequest<GetUserDataReturnResult>({
			action: API.GET_USER_DATA,
			params: { organizationId: story.organizationId },
			retries: false,
		});
		const result = await dispatch(request);
		const nonBlockingErrorMsg = `${endpointMap.get(API.GET_USER_DATA).url}: ${NO_RESULT_IN_RESPONSE}`;

		if (result instanceof Error && !(result.message === nonBlockingErrorMsg)) {
			if (!user.isOffline) {
				analytics.onOffline(story.id, cardId, endpointMap.get(API.GET_USER_DATA).url);
			}
			dispatch({ type: SET_OFFLINE_MODE, payload: true });
		}

		return result;
	};
}

interface SendAnswerCommon {
	storyId: string;
	cardId: string;
}

interface SendAnswerSingle extends SendAnswerCommon {
	type?: 'single-answer';
	answerId: string;
	answerText: string;
}

interface SendAnswerMultiple extends SendAnswerCommon {
	type?: 'multiple-answer';
	answerIds: string[];
	answerText: string[];
}

/**
 * When the user chose answer
 */
export function sendAnswer(payload: SendAnswerSingle | SendAnswerMultiple): ClientThunkAction<Action, Error | boolean> {
	log('api:sendAnswer', payload);

	AnswerDelta.end(payload.cardId);
	const delta = AnswerDelta.get(payload.cardId);

	return (dispatch, getStore) => {
		const state = getStore();
		const user = selectUser(state);
		const storySettings = selectStorySettings(state);

		if (!user.isRegistered) {
			return Promise.reject(new Error('User is unregistered.'));
		}

		const isMultiple = 'answerIds' in payload;

		(isMultiple ? payload.answerIds : [payload.answerId]).forEach((id, i) => {
			analytics.onSendAnswer(
				payload.storyId,
				payload.cardId,
				id,
				Array.isArray(payload.answerText) ? payload.answerText[i] : payload.answerText
			);
		});

		const clientURLParams = storySettings ? getClientURLParams(storySettings) : undefined;

		return dispatch(
			apiRequest({
				action: API.SEND_ANSWER,
				params: { storyId: payload.storyId },
				data: {
					data: {
						cardId: payload.cardId,
						pageViewId: user.storyPageViewId,
						...(delta !== undefined ? { delta } : undefined),
						...(clientURLParams ? { clientURLParams } : undefined),
						...(isMultiple ? { answerIds: payload.answerIds } : { answerId: payload.answerId }),
					},
				},
			})
		);
	};
}

interface SendSortableTriviaAnswer extends SendAnswerCommon {
	type: 'partial';
	items: string[];
	answerId: string;
	answerText: string;
}

interface SendSortablePollAnswer extends SendAnswerCommon {
	type: 'complete';
	items: string[];
}

type SendSortableAnswerPayload = SendSortableTriviaAnswer | SendSortablePollAnswer;

export function sendSortableAnswer(payload: SendSortableAnswerPayload): ClientThunkAction<Action, Error | boolean> {
	log('api:sendSortableAnswer', payload);

	AnswerDelta.end(payload.cardId);
	const delta = AnswerDelta.get(payload.cardId);

	// start new delta for the next answer on sort
	AnswerDelta.start(payload.cardId);

	return (dispatch, getStore) => {
		const state = getStore();
		const user = selectUser(state);
		const storySettings = selectStorySettings(state);

		if (!user.isRegistered) {
			return Promise.reject(new Error('User is unregistered.'));
		}

		if (payload.type === 'partial') {
			analytics.onSendAnswer(payload.storyId, payload.cardId, payload.answerId, payload.answerText);
		}

		const clientURLParams = storySettings ? getClientURLParams(storySettings) : undefined;

		return dispatch(
			apiRequest({
				action: API.SEND_SORTABLE_ANSWER,
				params: { storyId: payload.storyId },
				data: {
					data: {
						cardId: payload.cardId,
						pageViewId: user.storyPageViewId,
						...(delta !== undefined ? { delta } : undefined),
						...(clientURLParams ? { clientURLParams } : undefined),
						sort: {
							type: payload.type,
							items: payload.items,
							...('answerId' in payload ? { lastItem: payload.answerId } : null),
						},
					},
				},
			})
		);
	};
}

/**
 * When the user submit form
 */
export function sendForm({
	storyId,
	cardId,
	formData,
}: {
	storyId: string;
	cardId: string;
	formData: FormDataT[string][];
}) {
	log('api:sendForm', { storyId, cardId, formData });

	SubmissionDelta.end(cardId);

	// analytics.onSendForm(storyId, cardId, formData);
	return (dispatch, getStore) => {
		const state = getStore();
		const user = selectUser(state);
		const story = selectStory(state)!;
		const storySettings = selectStorySettings(state);

		const data: {
			organizationId: StoryModel['organizationId'];
			teamId: StoryModel['teamId'];
			pageViewId: string | null;
			inputs: typeof formData;
			delta?: number;
			clientURLParams?: ClientURLParamsPayload;
		} = {
			inputs: formData,
			pageViewId: user.storyPageViewId,
			organizationId: story.organizationId,
			teamId: story.teamId,
		};

		const delta = SubmissionDelta.get(cardId);
		if (delta !== undefined) data.delta = delta;

		const clientURLParams = storySettings ? getClientURLParams(storySettings) : undefined;
		if (clientURLParams) data.clientURLParams = clientURLParams;

		return dispatch(
			apiRequest({
				action: API.SEND_FORM,
				params: { storyId, cardId },
				data: { data },
			})
		);
	};
}

type GetCardVotes = {
	storyId: string;
	cardId: string;
};

type CardVotes = { [answerId: string]: number };

export function getCardVotes(params: GetCardVotes): ClientThunkAction<Action, CardVotes | null> {
	return async (dispatch, getStore) => {
		const { user } = getStore();

		if (!user.isRegistered) {
			return null;
		}

		const response = await dispatch(
			apiRequest<CardVotes>({
				action: API.GET_CARD_VOTES,
				params,
				retries: false,
			})
		);

		if (typeof response === 'boolean' || response instanceof Error) {
			return null;
		}

		return response;
	};
}

export function logout() {
	return dispatch => {
		return dispatch(apiRequest({ action: API.LOGOUT }));
	};
}

export type GameResponse = {
	game: null | {
		id: string;
		plays: number;
		pageViewId: string;
		response: { [cardId: string]: number };
		sortable: object;
		checkpoint: {
			cardId: string;
			date: number;
		};
		createdAt: number;
		updatedAt: number;
	};
};

export function getGame(storyId: string): ClientThunkAction<Action, GameResponse> {
	return async (dispatch, getStore) => {
		const restrictedUrlParams = getRestrictionsParams(getStore());
		const encodedRestrictedUrlParamsStr = encodeURIComponent(
			Object.entries(restrictedUrlParams || {})
				.map(([key, value]) => `${key}=${value}`)
				.join(',')
		);

		const result = await dispatch(
			apiRequest<GameResponse>({
				action: API.GET_GAME,
				params: { storyId },
				...(encodedRestrictedUrlParamsStr && { data: { clientURLParams: encodedRestrictedUrlParamsStr } }),
				retries: false,
			})
		);

		if (result instanceof Error || typeof result === 'boolean') {
			return { game: null };
		}

		return result;
	};
}

export function startGame(storyId: string): ClientThunkAction<Action, GameResponse> {
	return async (dispatch, getStore) => {
		const restrictedUrlParams = getRestrictionsParams(getStore());

		const result = await dispatch(
			apiRequest<GameResponse>({
				action: API.START_GAME,
				params: { storyId },
				...(restrictedUrlParams && { data: { clientURLParams: restrictedUrlParams } }),
				retries: false,
			})
		);

		if (result instanceof Error || typeof result === 'boolean') {
			return { game: null };
		}

		return result;
	};
}

type InvokeActionResponse = {
	jobId: string;
};
export function invokeAction(
	storyId: string,
	actionId: string,
	variablesMap: Record<string, string>
): ClientThunkAction<Action, InvokeActionResponse | null> {
	return async (dispatch, getStore) => {
		const result = await dispatch(
			apiRequest<InvokeActionResponse>({
				action: API.INVOKE_ACTION,
				params: { storyId, cardId: actionId },
				data: { inputs: variablesMap },
				retries: false,
			})
		);

		if (result instanceof Error || typeof result === 'boolean') {
			return null;
		}

		return result;
	};
}

type GetActionStatus =
	| {
			status: 'pending';
	  }
	| {
			status: 'completed';
			result: {
				type: 'completions';
				completion: {
					[outputId: string]: string;
				};
			};
	  }
	| {
			status: 'error';
	  };
export function getActionStatus(jobId: string): ClientThunkAction<Action, GetActionStatus | null> {
	return async (dispatch, getStore) => {
		const result = await dispatch(
			apiRequest<GetActionStatus>({
				action: API.GET_ACTION_STATUS,
				params: { cardId: encodeURIComponent(jobId) },
				retries: false,
			})
		);

		if (result instanceof Error || typeof result === 'boolean') {
			return null;
		}

		return result;
	};
}

export function sendGameCheckpoint(storyId: string, cardId: string): ClientThunkAction<Action, any> {
	return (dispatch, getStore) => {
		const restrictedUrlParams = getRestrictionsParams(getStore());

		return dispatch(
			apiRequest({
				action: API.SEND_GAME_CHECKPOINT,
				params: { storyId, cardId },
				...(restrictedUrlParams && { data: { clientURLParams: restrictedUrlParams } }),
			})
		);
	};
}
