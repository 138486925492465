import type { Props as PlayerProps } from './index';

export function trackVisibilityChange(
	element: HTMLElement | null,
	callback: (visible: boolean) => void,
	intervalDuration: number
) {
	if (!element) return () => {};

	let cleanupVisibilityCheck: (() => void) | null = null;
	const observer = new IntersectionObserver(
		([entry]) => {
			if (entry.isIntersecting) {
				cleanupVisibilityCheck = runVisibilityCheck(element, callback);
			} else {
				cleanupVisibilityCheck?.();
				callback(false);
			}
		},
		{ threshold: 0 }
	);

	observer.observe(element);

	return () => {
		cleanupVisibilityCheck?.();
		observer.unobserve(element);
	};

	function runVisibilityCheck(el: HTMLElement, cb: (visible: boolean) => void) {
		cb(checkVisibility(el));

		const interval = setInterval(() => {
			cb(checkVisibility(el));
		}, intervalDuration);

		return () => {
			clearInterval(interval);
		};
	}
}

function checkVisibility(element: HTMLElement, childBBox?: DOMRect): boolean {
	try {
		const parent = element.parentElement;
		const elStyle = window.getComputedStyle(element);
		const elBBox = element.getBoundingClientRect();

		const isVisible =
			elStyle.display !== 'none' &&
			elStyle.visibility !== 'hidden' &&
			elStyle.opacity !== '0' &&
			(elStyle.overflow === 'hidden' && childBBox ? hasIntersection(elBBox, childBBox) : true) &&
			(parent && parent !== document.body ? checkVisibility(parent, childBBox ?? elBBox) : true);

		return Boolean(isVisible);
	} catch {
		return false;
	}
}

function hasIntersection(rect1: DOMRect, rect2: DOMRect) {
	const minOverlap = 2; // px

	return (
		Math.round(rect1.top + minOverlap) <= Math.round(rect2.bottom) &&
		Math.round(rect1.bottom - minOverlap) >= Math.round(rect2.top) &&
		Math.round(rect1.left + minOverlap) <= Math.round(rect2.right) &&
		Math.round(rect1.right - minOverlap) >= Math.round(rect2.left)
	);
}

export function isPlaying(video: HTMLVideoElement | null): video is HTMLVideoElement {
	return video ? !video.paused && !video.ended && video.currentTime >= 0 : false;
}

// https://www.youtube.com/watch?v=videoID1
// https://www.youtube.com/embed/videoID1
// http://youtu.be/videoID1
export function getYoutubeId(url: string = '') {
	const arr = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/i);
	return arr[2] !== undefined ? arr[2].split(/[^0-9a-z_-]/i)[0] : arr[0];
}

// https://vimeo.com/0000000
// https://player.vimeo.com/video/0000000
// https://player.vimeo.com/external/000000000.hd.mp4?s=181e2d4d58c43142b701c9803ed57f33cff6777b&profile_id=175
export function getVimeoId(url: string = '') {
	return url.match(/\d+/i)?.[0];
}

export const getYoutubePlayerSrc = (props: PlayerProps) => {
	const id = getYoutubeId(props.src);

	return id
		? `https://www.youtube.com/embed/${id}` +
				'?modestbranding=1' +
				'&playsinline=1' +
				'&enablejsapi=1' +
				'&autoplay=0' +
				`&loop=${props.loop ? 1 : 0}` +
				`&mute=${props.muted ? 1 : 0}` +
				`&controls=${props.controls ? 1 : 0}`
		: '';
};

export const getVimeoPlayerSrc = (props: PlayerProps) => {
	const id = getVimeoId(props.src);

	return id
		? `https://player.vimeo.com/video/${id}` +
				'?byline=1' +
				'&portrait=1' +
				'&autopause=1' +
				'&playsinline=1' +
				'&api=1' +
				'&autoplay=0' +
				`&loop=${props.loop ? 1 : 0}` +
				`&muted=${props.muted ? 1 : 0}` +
				`&title=${props.title ? 1 : 0}` +
				`&controls=${props.controls ? 1 : 0}`
		: '';
};
