import React from 'react';
import ReactDOM from 'react-dom';
import css from './Debugger.scss';

const RecursiveObjectViewer = ({ data }) => {
	// Recursive function to render the object or array
	const renderObject = obj => {
		if (typeof obj !== 'object' || obj === null) {
			// Render primitive values (string, number, etc.)
			return <span>{String(obj)}</span>;
		}

		return (
			<details open>
				<summary>{Array.isArray(obj) ? `Array [${obj.length}]` : 'Object'}</summary>
				<div>
					{Object.entries(obj).map(([key, value]) => (
						<div key={key}>
							<strong>{key}:</strong> {renderObject(value)}
						</div>
					))}
				</div>
			</details>
		);
	};

	return <div>{renderObject(data)}</div>;
};

const placementMap = {
	tl: { top: 0, left: 0 },
	tr: { top: 0, right: 0 },
	bl: { bottom: 0, left: 0 },
	br: { bottom: 0, right: 0 },
} as const;

const Debugger = () => {
	const [value, setValue] = React.useState<object | null>(null);
	const [placement, setPlacement] = React.useState<keyof typeof placementMap>('tl');

	React.useEffect(() => {
		window.__storyDebug = (_value: typeof value, _placement?: typeof placement) => {
			setValue(_value);
			if (_placement) setPlacement(_placement);
		};
	}, []);

	if (value === null) return null;

	return ReactDOM.createPortal(
		<div className={css.debugger} style={placementMap[placement]}>
			<div className={css.content}>
				<RecursiveObjectViewer data={value} />
			</div>
			<div>
				<button type="button" onClick={() => setValue(null)}>
					hide
				</button>
				&nbsp;
				<button
					type="button"
					onClick={() =>
						setPlacement(s => {
							switch (s) {
								case 'tl':
									return 'tr';
								case 'tr':
									return 'bl';
								case 'bl':
									return 'br';
								case 'br':
									return 'tl';
								default:
									return 'tl';
							}
						})
					}
				>
					align
				</button>
			</div>
		</div>,
		document.body
	);
};

export default Debugger;
