// eslint-disable-next-line import/no-webpack-loader-syntax
import '!style-loader!css-loader!@draft-js-plugins/inline-toolbar/lib/plugin.css';
import { EditorState, getVisibleSelectionRect, RichUtils } from 'draft-js';
import Editor, { createEditorStateWithText, EditorPlugin } from '@draft-js-plugins/editor';
import { BoldButton, ItalicButton, UnderlineButton } from '@draft-js-plugins/buttons';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import { ToolbarChildrenProps } from '@draft-js-plugins/inline-toolbar/lib/components/Toolbar';
import createLinkPlugin from '@draft-js-plugins/anchor';
import EditorUtils from '@draft-js-plugins/utils';

import React, { Component, ComponentType } from 'react';
import { createPortal } from 'react-dom';
import { emailRegExp, numberRegExp, telE164RegExp } from 'utils/regexp';
import { prependUrlProtocol } from 'utils/helpers';

import MentionSuggestions, { mentionPlugin, AvailableVariablesArr } from './MentionPlugin';
import ColorPicker, { colorPickerPlugin } from './ColorPickerPlugin';
import { getHtmlFromState, getStateFromHtml, removeEntities, removeInlineStyles } from './utils';
import css from './RichEditor.scss';

// Plugins
const linkPlugin = createLinkPlugin({ placeholder: 'https://...', linkTarget: '_blank' });
const inlineToolbarPlugin = createInlineToolbarPlugin({
	theme: {
		toolbarStyles: { toolbar: css.toolbar },
		buttonStyles: {
			active: css.active,
			button: css.button,
			buttonWrapper: css.buttonWrapper,
		},
	},
});

const { LinkButton } = linkPlugin;
const { InlineToolbar } = inlineToolbarPlugin;

const ColorButton = (props: { children: React.ReactNode; theme: ToolbarChildrenProps['theme'] }) => (
	// TODO: active state class
	// When using a click event inside overridden content, mouse down
	// events needs to be prevented so the focus stays in the editor
	// and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
	<div className={props.theme.buttonWrapper} onMouseDown={(event: React.MouseEvent) => event.preventDefault()}>
		{props.children}
	</div>
);

export type RichEditorProps = {
	html: string;
	onChange?: (p: { editorState: EditorState; getHtml: () => string }) => void;
	onBlur?: (event: React.SyntheticEvent<FocusEvent>) => void;
	variables?: AvailableVariablesArr;
	noToolbar?: boolean;
};

type State = {
	editorState: EditorState;
	overrideContent: boolean;
};

export class RichEditor extends Component<RichEditorProps, State> {
	state = {
		editorState: getStateFromHtml(this.props.html, { variables: this.props.variables }),
		overrideContent: false,
	};

	picker: ReturnType<typeof colorPickerPlugin>;

	toolbarRef = React.createRef<HTMLDivElement>();

	raf: number = 0;

	plugins: EditorPlugin[] = [];

	constructor(props: RichEditorProps) {
		super(props);

		this.plugins = props.noToolbar ? [mentionPlugin] : [inlineToolbarPlugin, linkPlugin, mentionPlugin];
		this.picker = colorPickerPlugin(this.updateEditorState, this.getEditorState);
	}

	componentDidMount() {
		const setPosition = () => {
			const { editorState, overrideContent } = this.state;
			const selection = editorState.getSelection();
			const isVisible = (!selection.isCollapsed() && selection.getHasFocus()) || overrideContent;
			const toolbar = this.toolbarRef.current as HTMLDivElement;

			if (!isVisible) {
				toolbar.style.visibility = 'hidden';
				toolbar.style.transform = 'scale(0)';
			} else {
				const toolbarRect = toolbar.getBoundingClientRect();
				const selectionRect = getVisibleSelectionRect(window);
				const offset = 5;
				const arrowHeight = 6;
				const offsetY = offset + arrowHeight;

				if (!selectionRect) {
					this.raf = requestAnimationFrame(setPosition);
					return;
				}

				// top
				const hasTopSpace = selectionRect.top - offsetY > toolbarRect.height;
				const top = hasTopSpace
					? selectionRect.top - offsetY - toolbarRect.height
					: selectionRect.bottom + offsetY;

				// left
				const scrollBarWidth = window.innerWidth - document.documentElement.offsetWidth;
				const toolbarHalfW = toolbarRect.width / 2;
				let left = selectionRect.left + selectionRect.width / 2 - toolbarHalfW;
				const overflowRight = left + toolbarRect.width - (window.innerWidth - offset);
				if (overflowRight > 0) left = left - overflowRight - scrollBarWidth;
				else if (left < 0) left = offset;

				toolbar.style.top = `${top}px`;
				toolbar.style.left = `${left}px`;
				toolbar.style.visibility = 'visible';
				toolbar.style.transform = 'scale(1)';
				toolbar.setAttribute('data-position', hasTopSpace ? 'top' : 'bottom');
			}

			this.raf = requestAnimationFrame(setPosition);
		};

		if (this.toolbarRef.current) setPosition();
	}

	componentWillUnmount() {
		cancelAnimationFrame(this.raf);
	}

	getHtml = () => {
		const contentState = this.state.editorState.getCurrentContent();
		// Check if the content is empty (no text and only one empty block)
		if (!contentState.hasText() && contentState.getBlockMap().size === 1) {
			const firstBlock = contentState.getFirstBlock();
			if (firstBlock.getText() === '' && firstBlock.getType() === 'unstyled') {
				return ''; // Return empty string for truly empty content instead of '<br />'
			}
		}

		return getHtmlFromState(this.state.editorState);
	};

	updateEditorState = (editorState: EditorState) => {
		const content = { current: editorState.getCurrentContent(), prev: this.state.editorState.getCurrentContent() };
		const currentBlockMap = content.current.getBlockMap();
		const previousBlockMap = content.prev.getBlockMap();

		this.setState({ editorState }, () => {
			if (!currentBlockMap.equals(previousBlockMap)) {
				this.props.onChange?.({ editorState, getHtml: this.getHtml });
			}
		});
	};

	getEditorState = () => this.state.editorState;

	onOverrideContent =
		(onOverrideContent: ToolbarChildrenProps['onOverrideContent']) =>
		(content: Parameters<ToolbarChildrenProps['onOverrideContent']>[number]) => {
			onOverrideContent(content);
			this.setState({ overrideContent: !!content });
		};

	/**
	 * clear formatting in selected range
	 */
	clear = () => {
		let nextEditorState = this.picker.styles.color.remove(this.state.editorState);
		nextEditorState = removeEntities(nextEditorState);
		nextEditorState = removeInlineStyles(nextEditorState);
		this.updateEditorState(nextEditorState);
	};

	/**
	 * transform editor content to plain text
	 */
	toPlainText = () => {
		const content = this.state.editorState.getCurrentContent();
		const plainText = content.getPlainText();
		this.updateEditorState(createEditorStateWithText(plainText));
	};

	handleKeyCommand = (command: string, editorState: EditorState) => {
		const newState = RichUtils.handleKeyCommand(editorState, command);

		if (newState) {
			this.updateEditorState(newState);
			return 'handled';
		}

		return 'not-handled';
	};

	render() {
		const { editorState } = this.state;

		return (
			<>
				<Editor
					stripPastedStyles
					editorState={editorState}
					onChange={this.updateEditorState}
					onBlur={this.props.onBlur}
					plugins={this.plugins}
					handleKeyCommand={this.handleKeyCommand}
					customStyleFn={this.picker.customStyleFn}
				/>
				{createPortal(<MentionSuggestions variables={this.props.variables} />, document.body)}
				{!this.props.noToolbar &&
					createPortal(
						<div className={css.toolbarWrapper} ref={this.toolbarRef}>
							<InlineToolbar>
								{tbProps => (
									<>
										<BoldButton {...tbProps} />
										<ItalicButton {...tbProps} />
										<UnderlineButton {...tbProps} />
										<LinkButton
											{...tbProps}
											onOverrideContent={input => {
												if (!input) return;

												this.onOverrideContent(tbProps.onOverrideContent)(() => (
													<div className={css.linkContent}>
														{/* @ts-expect-error */}
														{input({
															getEditorState: this.getEditorState,
															setEditorState: this.updateEditorState,
															onOverrideContent: this.onOverrideContent(
																tbProps.onOverrideContent
															),
														})}
														<button
															type="button"
															onMouseDown={e => {
																/*
															This listener repeats behaviour of this module:
															 - node_modules/@draft-js-plugins/anchor/lib/index.esm.js

															 Also:
															 Input saves link on keypress "enter", and closes
															 input tool on blur. Because of "onMouseDown"
															 has higher priority then "blur" it is a good point to
															 handle save by custom button and prevent calling of
															 input "blur" event.
															 */
																e.preventDefault();

																const prev = e.currentTarget
																	?.previousSibling as HTMLInputElement;

																if (!prev) return;

																let { value } = prev;

																if (emailRegExp.test(value)) {
																	value = `mailto:${value}`;
																} else if (
																	telE164RegExp.test(value) ||
																	numberRegExp.test(value)
																) {
																	value = `tel:${value}`;
																} else if (/^(?!mailto:|tel:|sms:)/.test(value)) {
																	value = prependUrlProtocol(value, 'https');
																}

																this.updateEditorState(
																	EditorUtils.createLinkAtSelection(
																		this.getEditorState(),
																		value
																	)
																);

																this.onOverrideContent(tbProps.onOverrideContent)(
																	undefined
																);
															}}
														>
															save
														</button>
													</div>
												));
											}}
										/>
										<ColorButton {...tbProps}>
											<ColorPicker
												{...tbProps}
												onOverrideContent={this.onOverrideContent(tbProps.onOverrideContent)}
												className={tbProps.theme.button}
												toggleColor={color => this.picker.addColor(color)}
												color={this.picker.currentColor()}
											/>
										</ColorButton>
										<div
											className={tbProps.theme.buttonWrapper}
											onMouseDown={(event: React.MouseEvent) => event.preventDefault()}
										>
											<button
												className={tbProps.theme.button}
												type="button"
												onClick={this.clear}
												style={{
													paddingRight: 8,
													width: 48,
												}}
											>
												clear
											</button>
										</div>
									</>
								)}
							</InlineToolbar>
						</div>,
						document.body
					)}
			</>
		);
	}
}

export default RichEditor as ComponentType<RichEditorProps>;
