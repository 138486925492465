import React, { AllHTMLAttributes, forwardRef } from 'react';
import classnames from 'classnames';
import { FontSize, FontWeight, FontStyle, TextTransform, TextColor } from './config';
import css from './Text.scss';

interface Props extends Omit<AllHTMLAttributes<HTMLElement>, 'size'> {
	tag?: keyof React.JSX.IntrinsicElements;
	text?: string;
	size?: ValuesType<typeof FontSize>;
	color?: ValuesType<typeof TextColor>;
	weight?: ValuesType<typeof FontWeight>;
	fontStyle?: ValuesType<typeof FontStyle>;
	transform?: ValuesType<typeof TextTransform>;
	compact?: boolean;
	children?: any;
	ellipsis?: boolean;
}

/**
 * General Text component which includes all text styles variations
 * accordingly to Rabbi Guidelines (https://github.com/rabbiagency/guidelines)
 */
const TextBase = forwardRef<HTMLElement, Props>(
	(
		{
			tag = 'div',
			text = '',
			size = 'paragraph',
			weight = 'none',
			fontStyle = 'none',
			transform = 'none',
			compact = false,
			className,
			children = [],
			ellipsis,
			color,
			...rest
		},
		ref
	) => {
		const classNames = classnames(css[size], css[weight], css[fontStyle], css[transform], className, {
			[css.compact]: compact,
			'text-ellipsis': ellipsis,
			[css[`color-${color}`]]: color !== undefined,
		});
		const Component = tag as any;

		return (
			<Component className={classNames} ref={ref} {...rest}>
				{text || children}
			</Component>
		);
	}
);

TextBase.displayName = 'Text';

// Extend the component type to include static properties
type TextComponent = typeof TextBase & {
	size: typeof FontSize;
	color: typeof TextColor;
	weight: typeof FontWeight;
	fontStyle: typeof FontStyle;
	transform: typeof TextTransform;
};

// Attach static properties to the component
export const Text = TextBase as TextComponent;

Text.size = FontSize;
Text.color = TextColor;
Text.weight = FontWeight;
Text.fontStyle = FontStyle;
Text.transform = TextTransform;

export default Text;
