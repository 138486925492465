import parseLocation from 'client/utils/parse-location';
import React from 'react';
import type { BBCommonProps } from 'types/story';
import { TRANSITION_TYPE } from 'common/constants';
import usePrevious from 'common/components/usePrevious';
import { useClientSelector } from 'client/reducers';
import { selectCardId } from 'client/reducers/card/selectors';
import { selectStoryCardSettings } from 'client/reducers/story/selectors';
import { CardRendererContext } from 'client/components/common/StoryCard/CardRenderer/context';
import { getExposeParams, parseExposeDuration } from 'client/components/common/BuildingBlocks/utils/common';

export const useCardId = () => {
	const cardIdFromState = useClientSelector(selectCardId); // current story card
	const cardIdFromCtx = React.useContext(CardRendererContext)?.card._id; // card that renders current component

	// fallback in case of component is placed in story elements or card is not being mounted yet
	return cardIdFromCtx || cardIdFromState || parseLocation.getCardId() || undefined;
};

type UseExposeParamsProps = {
	exposeEffect: ValuesType<typeof TRANSITION_TYPE> | undefined;
	bbType: BBCommonProps['type'];
	in: boolean | undefined;
};

export const useExposeParams = ({ exposeEffect, bbType, in: inProp }: UseExposeParamsProps) => {
	const cardId = useCardId();
	const exposeDuration = useClientSelector(state => selectStoryCardSettings(state, cardId))?.expose?.duration ?? '';

	return React.useMemo(
		() =>
			getExposeParams({
				exposeEffect,
				exposeDuration: parseExposeDuration(exposeDuration),
				bbType,
				in: inProp,
			}),
		[exposeEffect, exposeDuration, bbType, inProp]
	);
};

export const useTransitionEndTimeout = (props: BBCommonProps) => {
	const transitionTimeout = React.useRef<number | undefined>(undefined);
	const prevProps = usePrevious(props);
	const { duration, effect, delay } = useExposeParams({
		exposeEffect: props.uiConfig.expose?.effect,
		bbType: props.type,
		in: props.in,
	});

	React.useEffect(() => {
		const inProp = props.in;
		if (effect && inProp !== undefined && inProp !== prevProps?.in) {
			clearTimeout(transitionTimeout.current);
			transitionTimeout.current = window.setTimeout(
				() =>
					props.onTransitionEnd({
						...props,
						in: inProp,
					}),
				effect ? duration + delay : 0
			);
		}

		return () => {
			clearTimeout(transitionTimeout.current);
		};
	}, [props, prevProps, duration, effect, delay]);

	return null;
};
