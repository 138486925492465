import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, SubmissionError } from 'redux-form';
import _ from 'lodash';

import { AdminReducerState } from 'admin/reducers';

import { TextField } from 'admin/components/common/Form';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Modal, ModalFooter } from 'admin/components/common/Modal';
import t from 'utils/translate';
import { updateMemberEmail } from 'admin/actions';
import { FORM_MODEL, MODAL } from 'admin/constants/common';
import { emailRegExp } from 'common/utils/regexp';
import { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';

import css from './ChangeUserEmailModal.scss';

const mapStateToProps = ({ user }: AdminReducerState) => ({
	userId: _.get(user, 'id', ''),
	initialValues: { email: _.get(user, 'email', '') },
});

const mapDispatchToProps = {
	updateMemberEmail,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Values = {
	email: string;
};

type OwnProps = ModalManagerProvidedProps<MODAL.CHANGE_USER_EMAIL>;

type Props = OwnProps & InjectedFormProps<Values, OwnProps> & ConnectedProps<typeof connector>;

type State = {
	submitSucceed: boolean;
};

class ChangeUserEmailModal extends Component<Props, State> {
	public readonly state = {
		submitSucceed: false,
	};

	renderTitle = () => {
		const title = this.state.submitSucceed
			? t('profile.changeEmailModal.submitSucceedTitle')
			: t('profile.changeEmailModal.title');

		return <Text size={Text.size.subtitle} weight={Text.weight.semibold} compact text={title} />;
	};

	onFormSubmit = async (values: Values) => {
		const { userId } = this.props;

		const response = await this.props.updateMemberEmail({ memberId: userId, data: { email: values.email } });

		if (response.result?.memberId) {
			this.props.close();
		} else {
			throw new SubmissionError({ _error: t('profile.changeEmailModal.submissionErrors.general') });
		}

		// for now we haven't functionalty to send aproovial email to the client inbox, so we update email immediately
		// this.setState({ submitSucceed: true });
	};

	onCloseClick = () => {
		this.setState({ submitSucceed: false });
		this.props.close();
	};

	renderSucceedContent = () => {
		return (
			<>
				<Text>{t('profile.changeEmailModal.submitSucceedText')}</Text>
				<Button smallText view="primary" onClick={this.onCloseClick}>
					{t('profile.changeEmailModal.close')}
				</Button>
			</>
		);
	};

	renderFormContent = () => {
		const { handleSubmit, dirty, error, submitting, close } = this.props;

		return (
			<>
				<Text className={css.title} text={t('profile.changeEmailModal.subtitle')} />
				<form className={css.form} onSubmit={handleSubmit(this.onFormSubmit)}>
					<Field
						className={css.input}
						name="email"
						component={TextField}
						autoComplete="off"
						isLabelUppercase={false}
						placeholder={t('profile.generalSettingsForm.fields.email.placeholder')}
						label={t('profile.generalSettingsForm.fields.email.label')}
						isRequired
					/>

					{error && <Text className={css.error}>{error}</Text>}

					<ModalFooter>
						<Button disabled={!dirty || submitting} view="primary" type="submit">
							{t('profile.changeEmailModal.update')}
						</Button>
						<Button disabled={submitting} view="secondary" onClick={close}>
							{t('profile.changeEmailModal.cancel')}
						</Button>
					</ModalFooter>
				</form>
			</>
		);
	};

	render() {
		const { open } = this.props;
		const { submitSucceed } = this.state;

		return (
			<Modal title={this.renderTitle()} open={open} destroyOnClose width="564px" className={css.modal}>
				{submitSucceed ? this.renderSucceedContent() : this.renderFormContent()}
			</Modal>
		);
	}
}

const ChangeEmailForm = reduxForm({
	validate: (values: Values, props: any) => {
		const errors: Partial<Values> = {};

		if (!values.email) {
			_.set(errors, 'email', t('profile.changeEmailModal.errors.required'));
		} else if (!emailRegExp.test(values.email)) {
			_.set(errors, 'email', t('profile.changeEmailModal.errors.wrong'));
		}

		return errors;
	},
	form: FORM_MODEL.CHANGE_USER_EMAIL,
})(ChangeUserEmailModal);

export default connector(ChangeEmailForm);
