import { convertPxToVw, convertVwToPx } from 'utils/helpers';
import { getClientFrame } from 'utils/iframe-tunnel';
import { parseUnit } from 'utils/parse-unit';

const spotlightConfig = {
	fadeIntensity: 50, //
	blurStart: 35, // %
	size: '15.63vw', // approx. 200px for 1280px screen (desktop platform min)
};

function createSpotlightSize(size: string) {
	return `${size} ${size}`.trim();
}

function createSpotlightBlur(blur: number) {
	if (!Number.isFinite(blur)) return '';
	const blurEnd = spotlightConfig.blurStart + spotlightConfig.blurStart * (blur / 100);
	return `radial-gradient(rgb(0, 0, 0) ${spotlightConfig.blurStart}%, transparent ${blurEnd}%)`;
}

// Format spotlight blur value for view in Inspector toolbar PropField | just representative numeric value
function formatSpotlightBlurField(value: string | number): string | number {
	const match = value.toString().match(/transparent (\d+(\.\d+)?)%/);

	if (match) {
		const extractedValue = parseFloat(match[1]);
		return Math.round(((extractedValue - spotlightConfig.blurStart) / spotlightConfig.blurStart) * 100).toString();
	}

	return spotlightConfig.fadeIntensity.toString();
}

// Parse spotlight blur value for store by Inspector toolbar PropField
function parseSpotlightBlurField(value: string): string {
	return createSpotlightBlur(parseInt(value, 10));
}

// Format spotlight blur size value for view in Inspector toolbar PropField
function formatSpotlightSizeField(value: string | number): string | number {
	const targetValue = value || createSpotlightSize(spotlightConfig.size);

	if (typeof targetValue === 'number') {
		return parseInt(targetValue.toString(), 10).toString();
	}

	const parsedVal = targetValue.split(' ')[0];
	const { contentWindow } = getClientFrame() as { contentWindow: Window };

	return convertVwToPx(parsedVal, 0, contentWindow.innerWidth);
}

// Parse spotlight blur size value for store by Inspector toolbar PropField
function parseSpotlightSizeField(value: string): string {
	const [, unit] = parseUnit(value);

	if (unit === 'vw') {
		return createSpotlightSize(value);
	}

	const { contentWindow } = getClientFrame() as { contentWindow: Window };
	const targetValue = convertPxToVw(parseInt(value, 10), 2, contentWindow.innerWidth);

	return createSpotlightSize(targetValue);
}

export {
	spotlightConfig,
	createSpotlightSize,
	createSpotlightBlur,
	formatSpotlightBlurField,
	parseSpotlightBlurField,
	formatSpotlightSizeField,
	parseSpotlightSizeField,
};
