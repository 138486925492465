// Think about split action constants to files per activity

import { createThunkActionType } from 'common/thunk-action';

export const SET_EDITOR_MODE = 'SET_EDITOR_MODE' as const;
export const SET_EDITABLE_STATE = 'SET_EDITABLE_STATE' as const;
export const SET_EDITOR_SELECTED_NODE = 'SET_EDITOR_SELECTED_NODE';
export const SET_CARD = 'SET_CARD' as const;
export const SET_STORY = 'SET_STORY' as const;
export const SET_STORY_ELEMENTS = 'SET_STORY_ELEMENTS' as const;
export const SET_STORY_SETTINGS = 'SET_STORY_SETTINGS' as const;
export const SET_STORY_SYMBOLS = 'SET_STORY_SYMBOLS' as const;
export const SET_STORY_CARD_DATA = 'SET_STORY_CARD_DATA' as const;
export const SET_CMS_MODEL = 'SET_CMS_MODEL' as const;
export const SET_OFFLINE_MODE = 'SET_OFFLINE_MODE' as const;
export const SET_CARD_HEIGHT = 'SET_CARD_HEIGHT' as const;
export const SET_EMBED_PARAMS = 'SET_EMBED_PARAMS' as const;
export const UPDATE_ANSWERS = 'UPDATE_ANSWERS' as const;
export const SET_FORM_DATA = 'SET_FORM_DATA' as const;
export const UPDATE_CUSTOM_VARIABLE = 'UPDATE_CUSTOM_VARIABLE' as const;

export const GET_PHONE_VERIFICATION_CODE = {
	START: 'API.GET_PHONE_VERIFICATION_CODE.START',
	SUCCESS: 'API.GET_PHONE_VERIFICATION_CODE.SUCCESS',
	FAIL: 'API.GET_PHONE_VERIFICATION_CODE.FAIL',
};

export const VERIFY_PHONE_CODE = {
	START: 'API.VERIFY_PHONE_CODE.START',
	SUCCESS: 'API.VERIFY_PHONE_CODE.SUCCESS',
	FAIL: 'API.VERIFY_PHONE_CODE.FAIL',
};

export const GET_STORY_SPREADSHEET_VALUES = createThunkActionType('GET_STORY_SPREADSHEET_RANGES');

export const CMS = {
	SET_ITEMS: 'SET_ITEMS' as const,
	SET_COLLECTIONS: 'SET_COLLECTIONS' as const,
};

export const API = {
	REGISTER_GUEST: {
		START: 'API.REGISTER_GUEST.START',
		SUCCESS: 'API.REGISTER_GUEST.SUCCESS',
		FAIL: 'API.REGISTER_GUEST.FAIL',
	} as const,
	GET_USER_DATA: {
		START: 'API.GET_USER_DATA.START',
		SUCCESS: 'API.GET_USER_DATA.SUCCESS',
		FAIL: 'API.GET_USER_DATA.FAIL',
	} as const,
	GET_GAME: {
		START: 'API.GET_GAME.START',
		SUCCESS: 'API.GET_GAME.SUCCESS',
		FAIL: 'API.GET_GAME.FAIL',
	} as const,
	START_GAME: {
		START: 'API.START_GAME.START',
		SUCCESS: 'API.START_GAME.SUCCESS',
		FAIL: 'API.START_GAME.FAIL',
	} as const,
	SEND_GAME_CHECKPOINT: {
		START: 'API.SEND_GAME_CHECKPOINT.START',
		SUCCESS: 'API.SEND_GAME_CHECKPOINT.SUCCESS',
		FAIL: 'API.SEND_GAME_CHECKPOINT.FAIL',
	},
	SEND_ANSWER: {
		START: 'API.SEND_ANSWER.START',
		SUCCESS: 'API.SEND_ANSWER.SUCCESS',
		FAIL: 'API.SEND_ANSWER.FAIL',
	},
	SEND_SORTABLE_ANSWER: {
		START: 'API.SEND_SORTABLE_ANSWER.START',
		SUCCESS: 'API.SEND_SORTABLE_ANSWER.SUCCESS',
		FAIL: 'API.SEND_SORTABLE_ANSWER.FAIL',
	},
	SEND_VIEW_STORY_EVENT: {
		START: 'API.SEND_VIEW_STORY_EVENT.START',
		SUCCESS: 'API.SEND_VIEW_STORY_EVENT.SUCCESS',
		FAIL: 'API.SEND_VIEW_STORY_EVENT.FAIL',
	},
	SEND_VIEW_CARD_EVENT: {
		START: 'API.SEND_VIEW_CARD_EVENT.START',
		SUCCESS: 'API.SEND_VIEW_CARD_EVENT.SUCCESS',
		FAIL: 'API.SEND_VIEW_CARD_EVENT.FAIL',
	},
	SEND_START_STORY_EVENT: {
		START: 'API.SEND_START_STORY_EVENT.START',
		SUCCESS: 'API.SEND_START_STORY_EVENT.SUCCESS',
		FAIL: 'API.SEND_START_STORY_EVENT.FAIL',
	},
	SEND_FINISH_STORY_EVENT: {
		START: 'API.SEND_FINISH_STORY_EVENT.START',
		SUCCESS: 'API.SEND_FINISH_STORY_EVENT.SUCCESS',
		FAIL: 'API.SEND_FINISH_STORY_EVENT.FAIL',
	},
	SEND_REGISTER_EVENT: {
		START: 'API.SEND_REGISTER_EVENT.START',
		SUCCESS: 'API.SEND_REGISTER_EVENT.SUCCESS',
		FAIL: 'API.SEND_REGISTER_EVENT.FAIL',
	},
	SEND_FORM: {
		START: 'API.SEND_FORM.START',
		SUCCESS: 'API.SEND_FORM.SUCCESS',
		FAIL: 'API.SEND_FORM.FAIL',
	},
	GET_CARD_VOTES: {
		START: 'API.GET_CARD_VOTES.START',
		SUCCESS: 'API.GET_CARD_VOTES.SUCCESS',
		FAIL: 'API.GET_CARD_VOTES.FAIL',
	},
	SEND_VIDEO_EVENT: {
		START: 'API.SEND_VIDEO_EVENT.START',
		SUCCESS: 'API.SEND_VIDEO_EVENT.SUCCESS',
		FAIL: 'API.SEND_VIDEO_EVENT.FAIL',
	},
	INVOKE_ACTION: {
		START: 'API.INVOKE_ACTION.START',
		SUCCESS: 'API.INVOKE_ACTION.SUCCESS',
		FAIL: 'API.INVOKE_ACTION.FAIL',
	},
	GET_ACTION_STATUS: {
		START: 'API.GET_ACTION_STATUS.START',
		SUCCESS: 'API.GET_ACTION_STATUS.SUCCESS',
		FAIL: 'API.GET_ACTION_STATUS.FAIL',
	},
	LOGOUT: {
		START: 'API.LOGOUT.START',
		SUCCESS: 'API.LOGOUT.SUCCESS',
		FAIL: 'API.LOGOUT.FAIL',
	},
} as const;
