import type { ComponentTypes, BBStates, StoryMediaPlatform } from 'types/story';
import { CmsFieldType, CmsCollectionFieldItem } from 'types/cms';
import { COMPONENT_TYPE } from 'common/constants';
import { CSS_PROPS, UNIFORM_PROPS, OTHER_PROPS } from 'common/constants/component-props';
import { staticCollectionFieldsMap } from 'admin/components/pages/Collections/CollectionEditor/CollectionTable/utils';
import { getPFChildrenPath, getPFCompPropName } from 'admin/components/pages/Story/CardEditor/Inspector/PropField';

type GetBindableProps = {
	type: ComponentTypes;
	state: BBStates;
	platform: StoryMediaPlatform;
	path: string;
};

export type ComponentBindableProps = Array<{
	// component property name
	value: string;
	// label for the property
	label: string;
	// dot notated path to the property in card elements
	propPath: string;
	// allowed collection field types for the property
	fieldType: CmsFieldType[];
}>;

// Get list of properties for a component type available for binding to collection
export const getBindableProps = (props: GetBindableProps) => {
	// Define allowed field types for each property
	const fieldTypeConfig: Record<string, CmsFieldType[]> = {
		[CSS_PROPS.text.color]: [CmsFieldType.Text],
		[CSS_PROPS.background.backgroundImage]: [CmsFieldType.Image],
		[UNIFORM_PROPS.btnLink]: [CmsFieldType.URL],
		children: [...Object.values(CmsFieldType)],
	};

	// Get name of the field (dot notated path to the property in card elements)
	const getPropPath = (type: 'styles' | 'other' | 'uniform' | 'children', name: string) => {
		const propsNameProps = { path: props.path, currentState: props.state, currentMediaQuery: props.platform };
		if (type === 'children') return getPFChildrenPath(propsNameProps);
		return getPFCompPropName(name, type)(propsNameProps);
	};

	const propFieldsConfig = {
		children: {
			value: 'children',
			label: 'Text content',
			propPath: getPropPath('children', ''),
			fieldType: fieldTypeConfig.children,
		},
		[CSS_PROPS.background.backgroundImage]: {
			value: CSS_PROPS.background.backgroundImage,
			label: 'Background image',
			propPath: getPropPath('styles', CSS_PROPS.background.backgroundImage),
			fieldType: fieldTypeConfig[CSS_PROPS.background.backgroundImage],
		},
		[UNIFORM_PROPS.btnLink]: {
			value: UNIFORM_PROPS.btnLink,
			label: 'Link',
			propPath: getPropPath('uniform', UNIFORM_PROPS.btnLink),
			fieldType: fieldTypeConfig[UNIFORM_PROPS.btnLink],
		},
		[CSS_PROPS.text.color]: {
			value: CSS_PROPS.text.color,
			label: 'Text Color',
			propPath: getPropPath('styles', CSS_PROPS.text.color),
			fieldType: fieldTypeConfig[CSS_PROPS.text.color],
		},
		[OTHER_PROPS.video.videoSrc]: {
			value: OTHER_PROPS.video.videoSrc,
			label: 'Video source',
			propPath: getPropPath('other', OTHER_PROPS.video.videoSrc),
			fieldType: fieldTypeConfig[OTHER_PROPS.video.videoSrc],
		},
	};

	// Define properties for each component type
	const componentBindablePropsConfig: Record<string, ComponentBindableProps> = {
		[COMPONENT_TYPE.TEXT]: [propFieldsConfig.children, propFieldsConfig.color],
		[COMPONENT_TYPE.BUTTON]: [propFieldsConfig.children, propFieldsConfig.btnLink],
		[COMPONENT_TYPE.IMG]: [propFieldsConfig.backgroundImage, propFieldsConfig.btnLink],
		[COMPONENT_TYPE.VIDEO]: [propFieldsConfig.videoSrc],
	};

	return componentBindablePropsConfig[props.type] ?? [];
};

export const getCollectionFieldOptions = (
	fields: CmsCollectionFieldItem[],
	allowedTypes: CmsFieldType[] | 'any',
	staticFields: Parameters<typeof staticCollectionFieldsMap.get>[0][] = []
) => {
	if (!fields) return [];
	const fieldsList = [...fields, ...staticFields.map(f => staticCollectionFieldsMap.get(f)!)];
	return (allowedTypes === 'any' ? fieldsList : fieldsList.filter(f => f.type && allowedTypes.includes(f.type))).map(
		f => ({ value: f.key, label: f.name })
	);
};
