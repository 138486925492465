import React from 'react';
import { Field } from 'redux-form';

import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { Grid, Column } from 'admin/components/common/Grid';
import { ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { TextField } from 'admin/components/common/Form';
import { CARD_NAME_MAX_LEN } from 'admin/components/common/Form/validations';
import t from 'utils/translate';
import { Context, THEME } from 'admin/components/common/Theme';
import CharacterEvents from './CharacterEvents';
import UrlParamEvents from './UrlParamEvents';
import RedirectUrl from './RedirectUrl';
import ScoreEvents from './ScoreEvents';
import RateEvents from './RateEvents';
import { ModalType } from './modal-type';
import { fields, ChildrenFormProp } from './Form';
import css from './EditCardModal.scss';

type Props = {
	editType: ModalType;
	onDiscard?: () => void;
	form: ChildrenFormProp;
};

const Content: React.FC<Props> = ({ editType, onDiscard, form: { invalid, formData, change } }) => {
	const context = React.useContext(Context);
	const columnProps: { colStart: '1' | '3'; colSpan: '8' | '12' } =
		context?.theme === THEME.DARK ? { colStart: '1', colSpan: '12' } : { colStart: '3', colSpan: '8' };

	return (
		<>
			<ModalBody className={css.body}>
				<Grid columns="12" columnGap={Grid.gap.small} rowGap={Grid.gap.medium} className={css.bodyGrid}>
					{editType === ModalType.default ? (
						<Column {...columnProps}>
							<Field
								name={fields.name}
								component={TextField}
								label={t(`story.editCardModal.fields.${fields.name}.label`)}
								limit={{ max: CARD_NAME_MAX_LEN }}
								theme={context?.theme}
								isLabelUppercase={false}
								isRequired
							/>
							<Text
								className={css.fieldDescription}
								text={t(`story.editCardModal.fields.${fields.name}.hint`)}
								size={Text.size.description}
							/>
						</Column>
					) : editType === ModalType.editScoreNav ? (
						<Column {...columnProps}>
							<ScoreEvents events={formData.events} change={change} />
						</Column>
					) : editType === ModalType.editCharacterNav ? (
						<Column {...columnProps}>
							<CharacterEvents events={formData.events} change={change} />
						</Column>
					) : editType === ModalType.editRateNav ? (
						<Column {...columnProps}>
							<RateEvents events={formData.events} change={change} />
						</Column>
					) : editType === ModalType.editUrlParamsNav ? (
						<Column {...columnProps}>
							<UrlParamEvents
								urlParam={formData.settings.urlNavigationParam}
								events={formData.events}
								change={change}
							/>
						</Column>
					) : editType === ModalType.editRedirectNav ? (
						<Column {...columnProps}>
							<RedirectUrl />
						</Column>
					) : null}
				</Grid>
			</ModalBody>
			<ModalFooter>
				<Button type="submit" theme={context?.theme} view="primary" smallText disabled={invalid}>
					{t('story.editCardModal.save')}
				</Button>
				<Button theme={context?.theme} view="empty" smallText onClick={onDiscard}>
					{t('story.editCardModal.discard')}
				</Button>
			</ModalFooter>
		</>
	);
};

export { Content };
