import type {
	BBModel,
	BBSymbolLink,
	BBSymbolInstance,
	StorySymbols,
	ComponentTypes,
	WithStateAndPlatform,
	BBOtherProp,
} from 'types/story';
import type { CollectionPaginationTriggersProp } from 'types/cms';
import produce, { Draft } from 'immer';
import { COMPONENT_TYPE } from 'common/constants';
import { deepClone } from 'utils/helpers';
import { componentWalk } from 'utils/blocks/component-walk';
import { isSymbolLink, isSymbol } from 'utils/blocks/symbol';
import { findComponentBy } from 'utils/blocks/find-component-by';
import { UNIFORM_PROPS } from 'common/constants/component-props';

type OldId = string;
type NewId = string;
type IdsMap = Map<OldId, { newId: NewId; type: ComponentTypes }>;

export function createIdsMap(): IdsMap {
	return new Map();
}

type ComponentsList = Array<BBModel | BBSymbolLink | BBSymbolInstance>;

/**
 * This function is designed to maintain relationships between components. It is intended to be used
 * for duplicated component structures where new `_id` values have been created, and an `idsMap` object
 * has been formed to map old `_id` values to their corresponding new ones.
 *
 * If a component has a property that references another block by `_id`, this reference should be updated
 * to the new `_id` to preserve relationships between components. The function traverses the component tree,
 * replacing old `_id` values with their corresponding new values from `idsMap`.
 *
 * What if the component is a symbol?
 * Expected that function to be called separately — first for new plain blocks, and then for new symbol instances.
 * The function will ignore `BBSymbolLink`, processing only `BBModel` or `BBSymbolInstance`.
 * Therefore, helper functions responsible for applying modifications should always work with a complete block model,
 * not with a `BBSymbolLink`.
 *
 * @example
 * const idsMap = createIdsMap();
 * const updatedPlainComponents = updateReferenceIds(duplicatedPlainComponentsArray, idsMap, symbols);
 * const updatedSymbolInstances = updateReferenceIds(duplicatedSymbolInstancesArray, idsMap, symbols);
 */
export function updateReferenceIds<T extends ComponentsList>(components: T, idsMap: IdsMap, symbols: StorySymbols) {
	return produce(components, draft => {
		componentWalk(draft, function walk({ component: draftComponent }) {
			if (isSymbolLink(draftComponent)) return;

			switch (draftComponent.type) {
				case COMPONENT_TYPE.OVERLAY:
					update(draftComponent, symbols, UNIFORM_PROPS.overlayTriggers, oldValue => {
						const updateTriggers = (arr: string[] = []) => arr.map(item => getNewReferenceId(item, idsMap));
						return { show: updateTriggers(oldValue?.show), hide: updateTriggers(oldValue?.hide) };
					});
					break;
				case COMPONENT_TYPE.SWIPE:
					update(draftComponent, symbols, UNIFORM_PROPS.onSwipe, oldValue => ({
						left: getNewReferenceId(oldValue.left, idsMap),
						right: getNewReferenceId(oldValue.right, idsMap),
					}));
					break;
				case COMPONENT_TYPE.LOTTIE:
					update(draftComponent, symbols, 'other', oldValue => {
						Object.values(oldValue as WithStateAndPlatform<BBOtherProp>).forEach(draftStateValue => {
							Object.values(draftStateValue).forEach(draftPlatformValue => {
								const { ltContainer } = draftPlatformValue;
								draftPlatformValue.ltContainer = getNewReferenceId(ltContainer, idsMap);
							});
						});

						return oldValue;
					});
					break;
				case COMPONENT_TYPE.CMS_REPEATER:
					update(draftComponent, symbols, UNIFORM_PROPS.collectionPaginationTriggers, oldValue =>
						Object.keys(oldValue).reduce((acc, key) => {
							acc[key] = getNewReferenceId(oldValue[key], idsMap);
							return acc;
						}, {} as CollectionPaginationTriggersProp)
					);
					update(draftComponent, symbols, UNIFORM_PROPS.collectionFilters, oldValue =>
						oldValue.map(draftFilter => {
							if ('componentId' in draftFilter) {
								draftFilter.componentId = getNewReferenceId(draftFilter.componentId, idsMap);
							}
							return draftFilter;
						})
					);
					break;
				default:
					break;
			}

			componentWalk(draftComponent.children, walk);
		});
	});
}

type ComponentProps = NonNullable<Draft<BBModel>['uiConfig']['componentProps']>;

function update<Key extends keyof ComponentProps, Value extends NonNullable<ComponentProps[Key]>>(
	draftEl: Draft<BBModel>,
	symbols: StorySymbols,
	propKey: Key,
	updateFn: (oldValue: Value) => Value
) {
	if (!draftEl?.uiConfig?.componentProps) {
		return;
	}

	let value = draftEl.uiConfig.componentProps[propKey];

	if (!value && isSymbol(draftEl)) {
		/**
		 * If it is a symbol and the value is not found, it makes sense to check the master.
		 * If the value is found in the master, then we can update current draft with it and the new reference ID.
		 */
		const masterBlock = findMasterBlock(draftEl, symbols);
		const masterValue = masterBlock?.uiConfig?.componentProps?.[propKey];
		if (masterValue) value = typeof masterValue === 'object' ? deepClone(masterValue) : masterValue;
	}

	if (value) {
		draftEl.uiConfig.componentProps[propKey] = updateFn(value as Value);
	}
}

function findMasterBlock(draftEl: Draft<BBSymbolInstance>, symbols: StorySymbols) {
	const master = symbols[draftEl.symbol.masterId]?.master;
	return draftEl.symbol.childId
		? findComponentBy([master], { path: '_id', value: draftEl.symbol.childId })?.component
		: master;
}

function getNewReferenceId(oldReferenceId: string = '', idsMap: IdsMap) {
	return idsMap.has(oldReferenceId) ? idsMap.get(oldReferenceId)!.newId : oldReferenceId;
}
