import type { CmsCollectionDataItem, ListCollectionItemsInput } from 'types/cms';
import { useRef, useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useClientDispatch, useClientSelector } from 'client/reducers';
import { selectStoryId } from 'client/reducers/story/selectors';
import { setCollectionItems } from 'client/actions/cms';
import cmsUtils from 'common/utils/cms';
import { location } from 'common/utils/url-helper';
import { QUERY_KEYS } from '../constants';

const PAGE_SIZE = cmsUtils.defaultProps.collectionPageSize;

const INITIAL_PAGE = 0;

interface ListCollectionItemsQueryFnInput extends ListCollectionItemsInput {
	storyId: string;
	dispatch: ReturnType<typeof useClientDispatch>;
}

interface ListCollectionItemsOutput {
	items: CmsCollectionDataItem[];
	total: number;
	page: number;
}

export const listCollectionItemsQueryFn = async (params: ListCollectionItemsQueryFnInput) => {
	const { storyId, collectionId, pageSize, page, filter, orderDir, orderBy } = params;

	const { cms } = await (location.client.isPreview ? import('admin/resources') : import('client/resources'));

	const response = await cms.get.collectionItems.params({ collectionId, storyId }).send({
		pageSize,
		page,
		orderBy,
		orderDir,
		...(filter ? { filters: cmsUtils.encodeFilter(filter) } : null),
	});

	const data =
		response.body && 'result' in response.body
			? (response as ApiV1Response<ListCollectionItemsOutput>).body?.result // client resource
			: (response as ApiV2Response<ListCollectionItemsOutput>).body; // admin resource
	const result = { items: [], total: 0, ...data };

	params.dispatch(setCollectionItems({ collectionId: params.collectionId, items: result.items }));

	return { ...result, page };
};

export const listCollectionItemsQueryKey = ({ collectionId, ...rest }: ListCollectionItemsInput) => [
	QUERY_KEYS.LIST_CMS_COLLECTION_ITEMS,
	collectionId,
	rest,
];

const getPageCursor = (page: number) => ({ current: page, prev: page - 1, next: page + 1 });

export const useListCollectionItems = (params: ListCollectionItemsInput) => {
	const dispatch = useClientDispatch();
	const storyId = useClientSelector(selectStoryId)!;
	const initialPage = params.page ?? INITIAL_PAGE;
	const pageCursorRef = useRef(getPageCursor(initialPage));
	const pageSize = parseInt(`${params.pageSize}`, 10) || PAGE_SIZE;
	const queryKey = listCollectionItemsQueryKey(params);
	const queryKeyString = JSON.stringify(queryKey);

	useEffect(() => {
		pageCursorRef.current = getPageCursor(initialPage); // 👈 reset page
	}, [queryKeyString, initialPage]);

	const query = useInfiniteQuery({
		enabled: !!params.collectionId,
		queryKey,
		queryFn: async ({ pageParam }) => {
			pageCursorRef.current.current = pageParam; // 👈 update current page

			return listCollectionItemsQueryFn({
				collectionId: params.collectionId,
				page: pageParam ?? INITIAL_PAGE,
				pageSize,
				filter: params.filter,
				orderDir: params.orderDir,
				orderBy: params.orderBy,
				dispatch,
				storyId,
			});
		},
		initialPageParam: params.page ?? INITIAL_PAGE,
		getNextPageParam: (lastPage, pages) => {
			const itemsMax = lastPage.total ?? 0;
			const pageMax = Math.floor(itemsMax / pageSize);

			const nextPage = pageCursorRef.current.current + 1;
			const hasMore = pageCursorRef.current.current < pageMax;
			if (hasMore) {
				pageCursorRef.current.next = nextPage; // 👈 update next page
				return nextPage;
			}
			return undefined;
		},
		getPreviousPageParam: () => {
			if (pageCursorRef.current.current > INITIAL_PAGE) {
				const prevPage = pageCursorRef.current.current - 1;
				pageCursorRef.current.prev = prevPage; // 👈 update prev page
				return prevPage;
			}
			return undefined;
		},
	});

	return { ...query, page: pageCursorRef.current.current };
};
