import type { CollectionPaginationTriggersProp } from 'types/cms';
import { useEffect } from 'react';
import { STORY_ROOT_ID } from 'client/constants/common';
import { getElementById } from 'client/components/common/BuildingBlocks/utils/common';
import css from './CmsRepeater.scss';

type TriggerType = keyof CollectionPaginationTriggersProp;

type TriggerValue = ValuesType<CollectionPaginationTriggersProp>;

type UsePaginationTriggerParams = {
	triggers: CollectionPaginationTriggersProp | undefined;
	fetchNextPage: () => void;
	fetchPreviousPage: () => void;
	hasNextPage: boolean;
	hasPreviousPage: boolean;
};

// Add event listeners for pagination triggers
export function usePaginationTriggers({
	triggers,
	fetchNextPage,
	fetchPreviousPage,
	hasNextPage,
	hasPreviousPage,
}: UsePaginationTriggerParams) {
	useEffect(() => {
		const controller = new AbortController();
		const rootTarget = document.getElementById(STORY_ROOT_ID);

		if (triggers) {
			(Object.entries(triggers) as [TriggerType, TriggerValue][]).forEach(([trigger, triggerId]) => {
				const triggerElement = getElementById(triggerId);
				triggerElement?.classList.add(css.paginationTrigger);

				if ((trigger === 'next' && !hasNextPage) || (trigger === 'prev' && !hasPreviousPage)) {
					triggerElement?.setAttribute('disabled', 'true');
					return;
				}

				triggerElement?.removeAttribute('disabled');

				document.addEventListener(
					'click',
					event => {
						for (
							let target = event.target as HTMLElement | null;
							target && target !== rootTarget;
							target = target.parentElement
						) {
							if (target === triggerElement) {
								// prevent target action, for example prevent Button to navigate
								event.stopPropagation();
								if (trigger === 'next') fetchNextPage();
								else if (trigger === 'prev') fetchPreviousPage();
								break;
							}
						}
					},
					{ signal: controller.signal, capture: true }
				);
			});
		}

		return () => controller.abort();
	}, [triggers, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage]);

	return null;
}
