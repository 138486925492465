import type { Draft } from 'immer';
import type { BBModel } from 'types/story';
import {
	CmsModel,
	CollectionPaginationTriggersProp,
	CollectionPaginationType,
	CmsCollectionDataItem,
	ListCollectionItemsInput,
	CmsFilter,
	CmsFilterOperator,
} from 'types/cms';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import parseLocation from 'client/utils/parse-location';

/*
 Implementation concept:

 [Editor]      : connect elements with cms. create references and mark repeatable components
                 display cms data or no?
                 currently displayed data for all particular fields and for repeatable only 1st item

 				 *Required data*: on demand for a sidebar or all integrated collections in case of we display cms data

 [Preview]     : process story in place where data is loaded and modify to generate repeatable components

 				 *Required data*: all integrated collections

 [Pre-publish] : process story in place where data is pushed to server and modify story settings with
                 repeatable components data. Don't need to store generated blocks!
                 Need only to store in settings generated ids for repeatable components
                 and original ids for elements which were used to generate repeatable components. For example:
                 `answers: { [generatedBlockId]: { generatedFor: { dataId, originalBlockId } } }`

 				 *Required data*: length of each collection, to loop through repeatable components, but if there is no
 				 repeatable, then I don't need anything

 [Published]   : process story in place where data is loaded and modify to generate repeatable components,
                 but reuse generated ids from pre-publish if they exist

 				 *Required data*: all integrated collections, to generate repeatable components and display actual data
 */

const DATA_DYNAMIC_CARD = '_dynamic-card-id';
const DATA_ANY = 'any';
const DELIMITER = '::';
const referencePattern = new RegExp(`^${DELIMITER}([^: ]+)${DELIMITER}([^: ]+)${DELIMITER}([^: ]+)$`);

/**
 * @param collectionId - id of collection
 * @param dataId - id of data row
 * @param dataKey - key of data row object (column name)
 *
 * @example createReference('collectionId', 'dataId', 'username') => '::collectionId::dataId::username'
 */
/**
 * @param {string} collectionId - The unique identifier for the collection.
 * @param {string} dataId       - The unique identifier for an individual item (row) within the collection.
 * @param {string} dataKey      - The specific key within the collection item (row) object, representing a column name.
 *                                Each key in the item (row) object corresponds to a collection column.
 *
 * @example createReference('collectionId', 'dataId', 'username') => '::collectionId::dataId::username'
 */
const createReference = (collectionId: string, dataId: string, dataKey: string) => {
	return `${DELIMITER}${collectionId}${DELIMITER}${dataId}${DELIMITER}${dataKey}`;
};

const isCollectionReference = (value: unknown): value is string => {
	return typeof value === 'string' && value.match(referencePattern) !== null;
};

const parseReference = (value: string, logError = true) => {
	if (typeof value !== 'string') {
		if (logError) console.error('Invalid reference format');
		return null;
	}
	const match = value.match(referencePattern);

	if (!match) {
		if (logError) console.error('Invalid reference format');
		return null;
	}

	const [, collectionId, dataId, dataKey] = match;

	return {
		collectionId,
		dataId,
		dataKey,
	};
};

const replaceCollectionReference = (value: string, cmsItems: CmsModel['items']) => {
	const parsedReference = parseReference(value);
	if (!parsedReference) {
		return value;
	}
	const { collectionId, dataId, dataKey } = parsedReference;
	const collectionItems = cmsItems?.[collectionId] ?? {};
	let dataRow = collectionItems[dataId];

	if (dataId === DATA_DYNAMIC_CARD) {
		const cmsDataId = parseLocation.getCmsDataId();
		if (cmsDataId) dataRow = collectionItems[cmsDataId];
		else [dataRow] = Object.values(collectionItems);
	} else if (dataId === DATA_ANY) {
		[dataRow] = Object.values(collectionItems);
	}

	return dataRow && dataKey in dataRow ? dataRow[dataKey] : '';
};

const defaultProps = {
	[UNIFORM_PROPS.collectionPageSize]: 7,
	[UNIFORM_PROPS.collectionPagination]: CollectionPaginationType.none,
	[UNIFORM_PROPS.collectionPaginationTriggers]: { prev: '', next: '' } as CollectionPaginationTriggersProp,
	[UNIFORM_PROPS.collectionFilters]: [] as CmsFilter[],
};

const getComponentProp = <T extends keyof typeof defaultProps>(component: Pick<BBModel, 'uiConfig'>, prop: T) => {
	return component.uiConfig.componentProps[prop] ?? defaultProps[prop];
};

const transformItemsArrayToObject = (items: CmsCollectionDataItem[]) => {
	return items.reduce(
		(acc, item) => ({ ...acc, [item.id]: item }),
		{} as { [itemId: string]: CmsCollectionDataItem }
	);
};

// Common function to put collection items in redux store in order to have an easy way
// to get particular collection items by id without need to search in array
function applyCmsReducerUpdate(payload: CmsModel, draftState: Draft<CmsModel>) {
	if (payload.collections?.length) {
		const collections = [...(draftState.collections ?? [])];
		payload.collections.forEach(collection => {
			if (!collections.find(c => c.id === collection.id)) {
				collections.push(collection);
			}
		});
		draftState.collections = collections;
	}

	if (payload.items) {
		Object.keys(payload.items).forEach(collectionId => {
			draftState.items[collectionId] = { ...draftState.items[collectionId], ...payload.items[collectionId] };
		});
	}
}

/**
 * @param filter - Filter object
 *
 *
 * @example
 * const filter: Filter = {
 *   operator: "AND",
 *   conditions: [
 *     { field: "status", operator: "=", value: "Active" },
 *     {
 *       operator: "OR",
 *       conditions: [
 *         { field: "role", operator: "=", value: "admin" },
 *         { field: "role", operator: "=", value: "editor" },
 *       ],
 *     },
 *     { type: "FIND", value: "John", field: "name" },
 *     { field: "age", operator: ">=", value: 18 },
 *   ],
 * };
 * @example '{id} = "5c834958-e53f-406a-b7b6-be416d850dd7"'
 * @example 'FIND("Storycards", {title})'
 * @example 'OR({id} = "5c834958-e53f-406a-b7b6-be416d850dd7", {id} = "d82cb6b4-a451-41dd-9ed5-319501ac7978")'
 * @example 'AND({id} = "5c834958-e53f-406a-b7b6-be416d850dd7", {position} = "20")'
 */
function encodeFilter(filter: NonNullable<ListCollectionItemsInput['filter']>) {
	if ('type' in filter || filter.operator === CmsFilterOperator.Contains) {
		return `FIND("${filter.value}", {${filter.field}})`;
	}

	if ('conditions' in filter) {
		const conditions = filter.conditions.map(encodeFilter).join(`, `);
		return `${filter.operator}(${conditions})`;
	}

	return `{${filter.field}} ${filter.operator} "${filter.value}"`;
}

export default {
	parseReference,
	isCollectionReference,
	replaceCollectionReference,
	createReference,
	DATA_ANY,
	DATA_DYNAMIC_CARD,
	defaultProps,
	getComponentProp,
	transformItemsArrayToObject,
	applyCmsReducerUpdate,
	encodeFilter,
};
