import { Flex } from 'antd';
import React, { useState } from 'react';
import cms from 'common/utils/cms';
import type { ComponentTypes } from 'types/story';
import { CmsFilter, CmsFilterOperator, CmsCollectionFieldItem } from 'types/cms';
import { COMPONENT_TYPE } from 'common/constants';
import { useDidMount } from 'common/components/useDidMount';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { componentWalkFull } from 'common/utils/blocks/component-walk-full';
import { MODAL } from 'admin/constants/common';
import { useAdminSelector } from 'admin/reducers';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { TextField } from 'admin/components/common/Form/TextField';
import { Select, SelectProps } from 'admin/components/common/Form/Select';
import { TogglePure } from 'admin/components/common/Form/Toggle';
import { useTheme } from 'admin/components/common/Theme';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { selectEditableElement } from 'admin/reducers/card-editor-extra/selectors';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { getPFCompPropName } from 'admin/components/pages/Story/CardEditor/Inspector/PropField';
import type { InspectorContextType } from 'admin/components/pages/Story/CardEditor/Inspector/InspectorContext';
import {
	selectEditableCardElements,
	selectEditableStoryElements,
	selectEditableSymbols,
} from 'admin/reducers/card-editor/selectors';
import { getCollectionFieldOptions } from 'admin/components/pages/Story/CardEditor/Inspector/CmsSettings/utils';
import css from './CmsFiltersModal.scss';

export type EditCmsFiltersModalData = {
	onSave: InspectorContextType['onUpdate'];
	collectionFields: CmsCollectionFieldItem[];
};

type ModalProps = ModalManagerProvidedProps<MODAL.EDIT_CMS_FILTERS>;
type SearchFilter = Extract<CmsFilter, { type: 'search' }>;
type CustomFilter = Extract<CmsFilter, { type: 'filter' }>;
type DefaultFilter = Extract<CmsFilter, { type: 'default' }>;

const operatorOptions = [
	{ label: 'Contains', value: CmsFilterOperator.Contains },
	{ label: 'Equal', value: CmsFilterOperator.Equal },
	{ label: 'Not equal', value: CmsFilterOperator.NotEqual },
	{ label: '>', value: CmsFilterOperator.GreaterThan },
	{ label: '<', value: CmsFilterOperator.LessThan },
	{ label: '>=', value: CmsFilterOperator.GreaterThanOrEqual },
	{ label: '<=', value: CmsFilterOperator.LessThanOrEqual },
];

const texts = {
	title: 'Filters',
	addCustom: 'Add custom filter',
	searchTitle: 'Search filter',
	defaultTitle: 'Default filter',
	customTitle: (index: number) => `Custom filter #${index}`,
	fieldLabel: 'Collection field',
	componentIdLabel: 'Component',
	operatorLabel: 'Condition',
	valueLabel: 'Value',
	save: 'Save',
	discard: 'Discard',
};

const ModalContent: React.FC<ModalProps> = props => {
	const element = useAdminSelector(selectEditableElement);
	const currentFilters = cms.getComponentProp(element, UNIFORM_PROPS.collectionFilters);

	const { theme } = useTheme();
	const componentsList = useComponentsList();

	// filter applied by search field
	const [search, setSearch] = useState(
		(currentFilters.find(f => f.type === 'search') as SearchFilter) ?? createRawSearchFilter()
	);
	// filters triggered by components
	const [customFilters, setCustomFilters] = useState(
		currentFilters.filter(f => f.type === 'filter') as CustomFilter[]
	);
	// filters applied by default
	const [defaultFilter, setDefaultFilter] = useState(
		(currentFilters.find(f => f.type === 'default') as DefaultFilter) ?? createRawDefaultFilter()
	);

	const fieldOptions = getCollectionFieldOptions(props.data.collectionFields, 'any', ['id', 'position']);
	const componentListOptions = Array.from(componentsList.values());

	return (
		<>
			<ModalBody>
				<Flex vertical gap="large">
					<Text size="subtitle" weight="semibold" text={texts.title} />
					<Flex vertical gap="large">
						{renderSearchFilter()}
						{renderDefaultFilter()}
						{renderCustomFilters()}
						<Button
							size="small"
							view="secondary-gray"
							textWeight="normal"
							textSize="paragraph"
							onClick={() => setCustomFilters(p => [...p, createRawCustomFilter(fieldOptions[0].value)])}
						>
							{texts.addCustom}
						</Button>
					</Flex>
				</Flex>
			</ModalBody>
			<ModalFooter>
				<Button view="primary" onClick={handleSave}>
					{texts.save}
				</Button>
				<Button view="secondary-gray" onClick={props.close}>
					{texts.discard}
				</Button>
			</ModalFooter>
		</>
	);

	function renderSearchFilter() {
		const isEnabled = Boolean(search.field);
		const onChange = (on: boolean) => setSearch(createRawSearchFilter(on ? fieldOptions[0].value : undefined));
		return (
			<Flex vertical gap="small">
				<TogglePure
					checked={isEnabled}
					switchProps={{ onChange }}
					label={{ children: texts.searchTitle }}
					labelPosition="right"
				/>
				{isEnabled && (
					<Flex justify="start" align="center" gap="small">
						{renderSelectField({
							value: search.field,
							options: fieldOptions,
							onChange: field => setSearch(prev => ({ ...prev, field })),
							label: texts.fieldLabel,
						})}
						{renderSelectField({
							value: search.componentId,
							options: componentListOptions.filter(o => o.type === COMPONENT_TYPE.SEARCH_FIELD),
							onChange: componentId => setSearch(prev => ({ ...prev, componentId })),
							label: texts.componentIdLabel,
						})}
					</Flex>
				)}
			</Flex>
		);
	}

	function renderDefaultFilter() {
		const isEnabled = Boolean(defaultFilter.field);
		const onChange = (on: boolean) =>
			setDefaultFilter(createRawDefaultFilter(on ? fieldOptions[0].value : undefined));
		return (
			<Flex vertical gap="small">
				<TogglePure
					checked={isEnabled}
					switchProps={{ onChange }}
					label={{ children: texts.defaultTitle }}
					labelPosition="right"
				/>
				{isEnabled && (
					<Flex justify="start" align="center" gap="small">
						{renderSelectField({
							value: defaultFilter.field,
							options: fieldOptions,
							onChange: field => setDefaultFilter(prev => ({ ...prev, field })),
							label: texts.fieldLabel,
						})}
						{renderSelectField({
							value: defaultFilter.operator,
							options: operatorOptions,
							onChange: operator => setDefaultFilter(prev => ({ ...prev, operator })),
							label: texts.operatorLabel,
						})}
						{renderTextField({
							value: defaultFilter.value,
							onChange: event => setDefaultFilter(prev => ({ ...prev, value: event.target.value })),
							label: texts.valueLabel,
						})}
					</Flex>
				)}
			</Flex>
		);
	}

	function renderCustomFilters() {
		return customFilters.map((filter, index) => {
			const key = `custom-filter-${filter.componentId}-${index}`;
			const isEnabled = Boolean(filter.field);
			const onChange = (on: boolean) =>
				!on &&
				setCustomFilters(prev => {
					const next = [...prev];
					next.splice(index, 1);
					return next;
				});
			return (
				<Flex vertical gap="small" key={key}>
					<TogglePure
						checked={isEnabled}
						switchProps={{ onChange }}
						label={{ children: texts.customTitle(index + 1) }}
						labelPosition="right"
					/>
					{isEnabled && (
						<Flex justify="start" align="center" gap="small">
							{renderSelectField({
								value: filter.field,
								options: fieldOptions,
								onChange: field =>
									setCustomFilters(prev => {
										const next = [...prev];
										next[index] = { ...next[index], field };
										return next;
									}),
								label: texts.fieldLabel,
							})}
							{renderSelectField({
								value: filter.componentId,
								options: componentListOptions,
								onChange: componentId =>
									setCustomFilters(prev => {
										const next = [...prev];
										next[index] = { ...next[index], componentId };
										return next;
									}),
								label: texts.componentIdLabel,
							})}
							{renderSelectField({
								value: filter.operator,
								options: operatorOptions,
								onChange: operator =>
									setCustomFilters(prev => {
										const next = [...prev];
										next[index] = { ...next[index], operator };
										return next;
									}),
								label: texts.operatorLabel,
							})}
							{renderTextField({
								value: filter.value,
								onChange: event => {
									setCustomFilters(prev => {
										const next = [...prev];
										next[index] = { ...next[index], value: event.target.value };
										return next;
									});
								},
								label: texts.valueLabel,
							})}
						</Flex>
					)}
				</Flex>
			);
		});
	}

	function renderSelectField(p: RenderSelectFieldProps) {
		return (
			<Select
				value={p.value}
				options={p.options}
				theme={theme}
				eventListeners={{ onChange: p.onChange }}
				menuPlacement="bottom"
				maxMenuHeight={200}
				isSearchable
				className={css.field}
				label={{ children: p.label, theme }}
			/>
		);
	}

	function renderTextField(p: RenderTextFieldProps) {
		return p.readonly ? null : (
			<TextField
				theme={theme}
				value={p.value}
				onChange={p.onChange}
				readOnly={p.readonly}
				className={css.field}
				label={p.label}
				isRequired
				isLabelUppercase={false}
			/>
		);
	}

	function handleSave() {
		const filters = [search, defaultFilter, ...customFilters].filter(filter => {
			// drop triggers that refers to component that already does not exist
			if ('componentId' in filter && !componentsList.has(filter.componentId)) return false;
			// drop filters that are not valid
			return validateFilter(filter);
		});

		(props.data satisfies EditCmsFiltersModalData).onSave({
			name: getPFCompPropName(UNIFORM_PROPS.collectionFilters, 'uniform')({ path: element.path }),
			value: filters satisfies CmsFilter[],
		});

		props.close();
	}
};

interface RenderSelectFieldProps extends Pick<SelectProps, 'value' | 'options'> {
	onChange: (value: any) => void;
	label: string;
}

interface RenderTextFieldProps {
	value?: string;
	readonly?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	label: string;
}

const createRawSearchFilter = (defaultField = ''): SearchFilter => ({
	type: 'search',
	field: defaultField,
	componentId: '',
	operator: CmsFilterOperator.Contains,
});

const createRawDefaultFilter = (defaultField = ''): DefaultFilter => ({
	type: 'default',
	field: defaultField,
	value: '',
	operator: CmsFilterOperator.Equal,
});

const createRawCustomFilter = (defaultField = ''): CustomFilter => ({
	type: 'filter',
	field: defaultField,
	componentId: '',
	operator: CmsFilterOperator.Equal,
	value: '',
});

function validateFilter(filter: CmsFilter): boolean {
	switch (filter.type) {
		case 'search':
			return Boolean(filter.field && filter.componentId);
		case 'default':
			return Boolean(filter.field && filter.value);
		case 'filter':
			return Boolean(filter.field && filter.componentId && filter.operator && filter.value);
		default:
			return false;
	}
}

function useComponentsList() {
	const symbols = useAdminSelector(selectEditableSymbols);
	const elements = useAdminSelector(selectEditableCardElements)!;
	const storyElements = useAdminSelector(selectEditableStoryElements);
	const [components, setComponents] = useState<Map<string, { label: string; value: string; type: ComponentTypes }>>(
		new Map()
	);

	useDidMount(() => {
		const map: typeof components = new Map();

		const whiteList = new Set<ComponentTypes>([
			// COMPONENT_TYPE.TEXT,
			// COMPONENT_TYPE.IMG,
			COMPONENT_TYPE.BUTTON,
			COMPONENT_TYPE.SEARCH_FIELD,
			// COMPONENT_TYPE.BOX,
			// COMPONENT_TYPE.SHAPE,
			// COMPONENT_TYPE.VIDEO,
			// COMPONENT_TYPE.TIMER,
			// COMPONENT_TYPE.LOTTIE,
		]);

		componentWalkFull({
			symbols,
			elements,
			storyElements,
			callback: ({ component, path, stopCurrent }) => {
				if (whiteList.has(component.type)) {
					const isRepeatable = Boolean(component.uiConfig.componentProps.collectionId);

					if (isRepeatable) {
						// omit repeatable and its children
						stopCurrent();
					} else {
						map.set(component._id, {
							label: component.uiConfig.editorProps.name,
							value: component._id,
							type: component.type,
						});
					}
				}
			},
		});

		setComponents(map);
	});

	return components;
}

const CmsFiltersModal: React.FC<ModalProps> = props => {
	return (
		<Modal theme="dark" maskColor="black" open={props.open} destroyOnClose width={640} transitionName="">
			<ModalContent {...props} />
		</Modal>
	);
};

export default CmsFiltersModal;
