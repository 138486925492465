/**
 * This file contains functions to process and duplicate repeatable CMS components within a card or story.
 * The main function, processRepeatableComponent, handles the duplication and recursive processing of child
 * components based on their CMS references and context, then returns the duplicated components.
 */

import type { BBModel, BBStylesProp, BBOtherProp, StorySymbols } from 'types/story';
import type { CmsCollectionDataItem, CmsModel } from 'types/cms';
import produce, { Draft } from 'immer';
import { set } from 'lodash';
import cms from 'common/utils/cms';
import { appLog, deepClone } from 'common/utils/helpers';
import { updateId } from 'common/utils/generate-id';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { componentWalk } from 'common/utils/blocks/component-walk';
import { detachSymbol } from 'common/utils/blocks/get-symbol-model';
import { createIdsMap, updateReferenceIds } from 'common/utils/blocks/update-reference-ids';
import { findComponentBy } from 'common/utils/blocks/find-component-by';
import type { GenerateIdCallback } from 'common/utils/blocks/symbol-manager';
import { isSymbol, getInstanceByRef } from 'common/utils/blocks/symbol';
import { COMPONENT_TYPE } from 'common/constants';

// Test cases:
//   === Plain component ===
// - ✅Check single repeatable element
// - ✅Check repeatable with a children
// - ⚪️Check repeatable inside repeatable, not supported yet
// - ✅With nested linked Overlay
//   === Symbol component ===
// - ✅Check if the master symbol is repeatable
// - ✅Check if the instance symbol is repeatable
// - ✅Check if symbol is child of repeatable
// - ✅Check if the symbol child is repeatable. Done: repeat is ignored.
// - ✅With nested linked Overlay

const log = appLog.extend('CmsRepeatableComponentsProcessor');

type DraftElement = Draft<BBModel>;

type RepeatableContext = {
	data: CmsCollectionDataItem;
	collectionId: string;
};

type ProcessRepeatableComponentParams = {
	symbols: StorySymbols;
	items: CmsModel['items'];
	component: BBModel;
};

/**
 * Duplicate repeatable component by collection items size and replace CMS references with actual data.
 */
export function processRepeatableComponent<T extends ProcessRepeatableComponentParams>(
	params: T
): { components: T['component'][]; isModified: boolean } {
	const { items: collectionItems, symbols = {} } = params;
	const duplicatedElementsCtx = new Map<string, RepeatableContext>();
	const newIds = createIdsMap();
	const collectNewIds: GenerateIdCallback = ({ oldId, ...data }) => newIds.set(oldId, data);

	const plainComponent = detachSymbol(params.component, params.symbols).element;
	const { collectionId } = plainComponent.uiConfig.componentProps;
	const collectionData = collectionId ? Object.values(collectionItems?.[collectionId] ?? {}) : null;

	if (!collectionId || !collectionData?.length) {
		log('No collection data found for collectionId:', collectionId);
		return {
			components: [plainComponent],
			isModified: false,
		};
	}

	log(`Duplicate repeatable component ${collectionData.length}-times`);

	/**
	 * Duplicate the original component for each collection item.
	 * - Generate new IDs for each component recursively.
	 * - Update references to the new IDs.
	 * - Collect data context for each duplicated element.
	 */
	const duplicates = collectionData.map(data => {
		let newComponent = deepClone(plainComponent);

		// generate new id for each component
		componentWalk([newComponent], function walk({ component }) {
			const oldId = component._id;
			updateId(component);
			collectNewIds({ oldId, newId: component._id, type: component.type });
			componentWalk(component.children, walk);
		});

		[newComponent] = updateReferenceIds([newComponent], newIds, symbols);

		// collect data for each duplicated element
		duplicatedElementsCtx.set(newComponent._id, {
			data,
			collectionId,
		});

		return newComponent;
	});

	const result = produce(duplicates, draft => {
		draft.forEach(component => {
			const context = duplicatedElementsCtx.get(component._id);
			if (Array.isArray(component.children) && context) {
				component.children.forEach(draftChild => {
					processElement(draftChild, context);
				});
			}
		});
	});

	duplicatedElementsCtx.clear();

	return {
		components: result as T['component'][],
		isModified: true,
	};

	/**
	 * Processes a draft element by handling its CMS references and recursively processes each child.
	 *
	 * Detailed Functionality:
	 *
	 * * Context Handling
	 *   Context is an object containing the collection item data and collectionId for current draft element.
	 *   If context provided, it modifies text nodes, styles, uniform properties, etc. based on the context.
	 *   Note, it only creates a proper reference by id to the data, but does not inject any real data.
	 *
	 * * Child Processing:
	 *   If the draft element has children, it iterates over them recursively with a given context.
	 *   If a child is a repeater component - ignore, nested repeaters are not supported.
	 */
	function processElement(draftElement: DraftElement, context?: RepeatableContext) {
		if (draftElement.type === COMPONENT_TYPE.CMS_REPEATER) {
			return;
		}

		// replace references based on context
		if (context) {
			// handle text cms references
			modifyTextNodes({ draftElement, context, symbols });

			// handle styles, other cms references
			modifyStyleOrOtherProps({ draftElement, context, symbols });

			// handle uniform props cms references
			modifyUniformProps({ draftElement, context, symbols });
		}

		if (Array.isArray(draftElement.children)) {
			draftElement.children.forEach(draftChild => {
				processElement(draftChild, context ?? duplicatedElementsCtx.get(draftChild._id));
			});
		}
	}
}

type ApplyModificationParams = { draftElement: Draft<BBModel>; path: string; value: any; symbols: StorySymbols };

// Applies modifications to a draft element based on the provided path and value.
function applyModification(params: ApplyModificationParams) {
	// log('applyModification', { params });
	if (isSymbol(params.draftElement)) {
		const instance = getInstanceByRef(params.draftElement.symbol, params.symbols);
		if (!instance) return;
		// find is because of draftElement might be a symbol child
		const target = findComponentBy([instance], { path: '_id', value: params.draftElement._id });
		if (!target?.component) return;
		set(target.component, params.path, params.value);
	} else {
		set(params.draftElement, params.path, params.value);
	}
}

type ModifyFn = (params: { draftElement: Draft<BBModel>; context: RepeatableContext; symbols: StorySymbols }) => void;

// Modifies text nodes within a draft element based on the provided context.
const modifyTextNodes: ModifyFn = ({ draftElement, context, symbols }) => {
	if (!Array.isArray(draftElement.children)) {
		Object.keys(draftElement.children).forEach(state => {
			Object.keys(draftElement.children[state]).forEach(platform => {
				const originalValue = draftElement.children[state][platform];
				const ref = cms.parseReference(originalValue, false);

				if (ref?.collectionId === context.collectionId && ref?.dataId === cms.DATA_ANY) {
					// replace "any" dataId with an actual dataId
					applyModification({
						path: `children.${state}.${platform}`,
						value: cms.createReference(ref.collectionId, context.data.id, ref.dataKey),
						draftElement,
						symbols,
					});
				}
			});
		});
	}
};

// Modifies style or other properties within a draft element based on the provided context.
const modifyStyleOrOtherProps: ModifyFn = ({ draftElement, context, symbols }) => {
	const draftObjEntries = [
		['styles', draftElement.uiConfig.componentProps.styles],
		['other', draftElement.uiConfig.componentProps.other],
	] as const;

	draftObjEntries.forEach(([draftObjKey, draftObj]) => {
		Object.entries(draftObj).forEach(([state, platforms]) => {
			Object.entries(platforms).forEach(([platform, entries]) => {
				Object.entries(entries as BBStylesProp | BBOtherProp).forEach(([key, value]) => {
					const ref = cms.parseReference(value, false);

					if (ref?.collectionId === context.collectionId && ref?.dataId === cms.DATA_ANY) {
						// Replace "any" dataId with an actual dataId
						applyModification({
							path: `uiConfig.componentProps.${draftObjKey}.${state}.${platform}.${key}`,
							value: cms.createReference(ref.collectionId, context.data.id, ref.dataKey),
							draftElement,
							symbols,
						});
					}
				});
			});
		});
	});
};

// Modifies uniform properties within a draft element based on the provided context.
const modifyUniformProps: ModifyFn = ({ draftElement, context, symbols }) => {
	const draftComponentProps = draftElement.uiConfig.componentProps;
	Object.keys(draftComponentProps).forEach(prop => {
		if (prop in UNIFORM_PROPS) {
			const originalValue = draftComponentProps[prop];
			const ref = cms.parseReference(originalValue, false);
			if (ref?.collectionId === context.collectionId && ref?.dataId === cms.DATA_ANY) {
				// replace "any" dataId with an actual dataId
				applyModification({
					path: `uiConfig.componentProps.${prop}`,
					value: cms.createReference(ref.collectionId, context.data.id, ref.dataKey),
					draftElement,
					symbols,
				});
			}
		}
	});
};
