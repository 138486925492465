/* eslint-disable react/no-danger */
import React from 'react';
import { flushSync } from 'react-dom';
import { Field, change, formValueSelector, getFormSyncErrors, getFormMeta, touch } from 'redux-form';
import { get } from 'lodash';
import cn from 'classnames';

import { useAdminDispatch, useAdminSelector } from 'admin/reducers';
import RichEditor from 'client/components/common/RichEditor';
import { Errors } from 'admin/components/common/Form/Errors';
import { FORM_MODEL } from 'admin/constants/common';
import { VariableDataType } from 'common/utils/variables/types';

import mentionsCss from 'client/components/common/RichEditor/MentionPlugin/MentionPlugin.scss';
import css from './GptTextResponseAction.scss';

type Props = {
	variables: VariableDataType[];
	name: string;
	placeholder?: string;
	autofocus?: boolean;
};

// todo: consider to refactor with `common/components/common/Form/RichField`
const EditableText = (props: Props) => {
	const { variables, name, placeholder, autofocus } = props;
	const [isEditing, setIsEditing] = React.useState(false);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const dispatch = useAdminDispatch();
	const fieldValue = useAdminSelector(state => formValueSelector(FORM_MODEL.EDIT_CARD_ACTION)(state, name)) ?? '';
	const fieldError = useAdminSelector(state => get(getFormSyncErrors(FORM_MODEL.EDIT_CARD_ACTION)(state), name));
	const fieldMeta = useAdminSelector(state => get(getFormMeta(FORM_MODEL.EDIT_CARD_ACTION)(state), name));
	const showError = fieldMeta?.touched && fieldError;

	const onChange = (p: { getHtml: () => void }) => {
		dispatch(change(FORM_MODEL.EDIT_CARD_ACTION, name, p.getHtml()));
	};

	const onBlur = () => {
		dispatch(touch(FORM_MODEL.EDIT_CARD_ACTION, name));
	};

	const activateEditor = (caretPosition: any) => {
		flushSync(() => {
			setIsEditing(true);
		});

		const range = document.createRange();
		const selection = window.getSelection();
		const node = containerRef.current?.querySelector('[contenteditable="true"]');

		if (node) {
			if (caretPosition) {
				range.setStart(caretPosition.offsetNode, caretPosition.offset);
				range.collapse(true);
			} else {
				range.selectNodeContents(node);
				range.collapse(false);
			}

			selection?.removeAllRanges();
			selection?.addRange(range);
		}
	};

	const onContentClick = (e: React.MouseEvent) => {
		const { clientX, clientY } = e;
		const caretPosition =
			'caretPositionFromPoint' in document ? (document as any).caretPositionFromPoint(clientX, clientY) : null;

		activateEditor(caretPosition);
	};

	const onContainerFocus = () => {
		activateEditor(null);
	};

	React.useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (autofocus) {
				activateEditor(null);
			}
		}, 100);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [autofocus]);

	React.useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			const isClickInsideContainer = containerRef.current && containerRef.current.contains(e.target as Node);
			const isClickInsideMentionSuggestions = document
				.querySelector(`.${mentionsCss.mentionSuggestions}`)
				?.contains(e.target as Node);

			if (!isClickInsideContainer && !isClickInsideMentionSuggestions) {
				setIsEditing(false);
			}
		};

		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	return (
		<div
			className={cn(css.editableText, showError && css.error)}
			ref={containerRef}
			tabIndex={0}
			role="textbox"
			onFocus={onContainerFocus}
		>
			<div className={css.editorWrapper}>
				<Field name={name} component="input" type="hidden" />
				{isEditing ? (
					<RichEditor html={fieldValue} onChange={onChange} onBlur={onBlur} variables={variables} noToolbar />
				) : (
					<div
						className={cn(css.editorPlaceholder, fieldValue && css.hasContent)}
						onClick={onContentClick}
						dangerouslySetInnerHTML={{ __html: fieldValue || placeholder }}
					/>
				)}
			</div>
			<Errors show={fieldMeta?.touched}>{fieldError}</Errors>
		</div>
	);
};

export { EditableText };
export default EditableText;
