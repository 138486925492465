import _ from 'lodash';
import React, { CSSProperties, HTMLAttributes } from 'react';
import { addDataAttribute } from './utils';

/** @see ./_vars.scss -> $grid-column-position */
type ColumnPosition = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '-1';

/** @see ./_vars.scss -> $grid-row-position */
type RowPosition = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14' | '-1';

type Props = {
	colStart?: ColumnPosition;
	colSpan?: ColumnPosition;
	colEnd?: ColumnPosition;
	rowStart?: RowPosition;
	rowSpan?: RowPosition;
	rowEnd?: RowPosition;
	justifyContent?: CSSProperties['justifyContent'];
	alignItems?: CSSProperties['alignItems'];
	alignSelf?: CSSProperties['alignSelf'];
	direction?: CSSProperties['flexDirection'];
	wrap?: CSSProperties['flexWrap'];
	columnGap?: 'small' | 'medium' | 'large';
	rowGap?: 'small' | 'medium' | 'large';
	className?: string;
	children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const Column = ({
	colSpan,
	colStart,
	colEnd,
	columnGap,
	rowGap,
	rowSpan,
	rowStart,
	rowEnd,
	justifyContent,
	alignItems,
	alignSelf,
	direction,
	wrap,
	...props
}: Props) => {
	const dataAttributes = {};
	addDataAttribute('column-span', colSpan, dataAttributes);
	addDataAttribute('column-start', colStart, dataAttributes);
	addDataAttribute('column-end', colEnd, dataAttributes);
	addDataAttribute('column-gap', columnGap, dataAttributes);
	addDataAttribute('row-gap', rowGap, dataAttributes);
	addDataAttribute('row-span', rowSpan, dataAttributes);
	addDataAttribute('row-start', rowStart, dataAttributes);
	addDataAttribute('row-end', rowEnd, dataAttributes);
	addDataAttribute('justify-content', justifyContent, dataAttributes);
	addDataAttribute('align-items', alignItems, dataAttributes);
	addDataAttribute('align-self', alignSelf, dataAttributes);
	addDataAttribute('flex-direction', direction, dataAttributes);
	addDataAttribute('flex-wrap', wrap, dataAttributes);

	if ([justifyContent, alignItems, alignSelf, direction, wrap].some(_.isString)) {
		addDataAttribute('flex', '', dataAttributes);
	}

	return <div {...dataAttributes} {...props} />;
};
