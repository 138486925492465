import { getSourceType } from 'utils/assets';
import { emailRegExp, fullName } from 'utils/regexp';

export const isRequired = <T extends any>(v: T) => !!v;
export const isEmail = <T extends string>(v: T) => emailRegExp.test(v);
export const isFullName = <T extends string>(v: T) => fullName.test(v);
export const isInteger = <T = unknown>(value: T, strict = true) => {
	if (strict && (value === undefined || value === '' || value === null)) return false;
	const number = Number(value);
	return Number.isInteger(number);
};
export const isNumber = <T = unknown>(value: T, strict = true) => {
	if (strict && (value === undefined || value === '' || value === null)) return false;
	const number = Number(value);
	return Number.isFinite(number);
};
export const isUrl = <T = unknown>(v: T) => {
	try {
		const url = new URL(String(v));
		return Boolean(url);
	} catch {
		return false;
	}
};
export const minLength = (v: string, min: number) => v && v.length >= min;
export const maxLength = (v: string, max: number) => v && v.length <= max;
export const isVideoSrc = <Value extends string>(value: Value) => {
	if (!value || value === 'none') {
		return true;
	}

	const source = getSourceType(value);
	const isValidUrl = /^https?:\/\//i.test(value);
	const hasValidExtension = source === 'video' || source === 'hls' || source === 'vimeo' || source === 'youtube';
	return isValidUrl && hasValidExtension;
};
export const isImageSrc = <Value extends string>(value: Value) => {
	if (!value || value === 'none') {
		return true;
	}

	const sourceType = getSourceType(value);
	const isValidUrl = /^https?:\/\//i.test(value);
	const hasValidExtension = sourceType === 'image';
	return isValidUrl && hasValidExtension;
};

export const USERNAME_MAX_LEN = 70;
export const VIDEO_TITLE_MAX_LEN = 40;
export const TEAM_MAX_LEN = 34;
export const WORKSPACE_MAX_LEN = 25;
export const STORY_NAME_MIN_LEN = 2;
export const STORY_NAME_MAX_LEN = 65;
export const AI_PROMPT_MAX_LEN = 500;
export const CARD_NAME_MIN_LEN = 1;
export const CARD_NAME_MAX_LEN = 65;
export const EVENT_NAME_MAX_LEN = 25;
export const SEO_TEXT_MAX_LEN = 120;
export const SHARE_TEXT_MAX_LEN = 120;
export const LAYER_NAME_MIN_LEN = 1;
export const LAYER_NAME_MAX_LEN = 50;
export const COLLECTION_MAX_LEN = 34;
export const COLLECTION_FIELD_NAME_MAX_LEN = 34;
