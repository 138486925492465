import { PARAMS } from 'common/constants';
import * as clientRoutes from 'client/constants/routes';

// Function to get cardId from URL
function getCardId(): string | null {
	const path = window.location.pathname;
	const searchParams = new URLSearchParams(window.location.search);

	if (searchParams.has(PARAMS.CARD_ID)) {
		return searchParams.get(PARAMS.CARD_ID);
	}

	const segments = path.split('/').filter(segment => segment.length > 0);

	const cardSegmentIndex = segments.indexOf(clientRoutes.CARD_PATH_SEGMENT);

	if (cardSegmentIndex !== -1 && cardSegmentIndex + 1 < segments.length) {
		return segments[cardSegmentIndex + 1];
	}

	return null;
}

// Function to get collection dataId from URL
function getDataId(): string | null {
	const path = window.location.pathname;
	const searchParams = new URLSearchParams(window.location.search);

	if (searchParams.has(PARAMS.COLLECTION_ITEM_ID)) {
		return searchParams.get(PARAMS.COLLECTION_ITEM_ID);
	}

	const segments = path.split('/').filter(segment => segment.length > 0);

	const cardSegmentIndex = segments.indexOf(clientRoutes.CARD_PATH_SEGMENT);

	if (cardSegmentIndex !== -1 && cardSegmentIndex + 2 < segments.length) {
		return segments[cardSegmentIndex + 2];
	}

	return null;
}

export default {
	getCardId,
	getCmsDataId: getDataId,
};
