import React, { FC, useCallback, useState } from 'react';
import { Tooltip } from 'antd';
import createMentionPlugin from '@draft-js-plugins/mention';
import type { SubMentionComponentProps } from '@draft-js-plugins/mention/lib/Mention';
import type { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry';
import type { MentionSuggestionsPubProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/MentionSuggestions';
import useToggle from 'common/components/useToggle';
import type { VariableDataType } from 'common/utils/variables/types';
import { getAvailableVariables } from 'common/utils/variables/variables';
import css from './MentionPlugin.scss';

interface MentionComponentBody {
	mention: VariableDataType;
}
interface MentionComponentBodyEditable extends MentionComponentBody {
	className?: SubMentionComponentProps['className'];
	children: SubMentionComponentProps['children'];
}
type MentionComponentBodyProps = MentionComponentBody | MentionComponentBodyEditable;

// Returns JSX of both the edited variable and the one that will be saved in JSON
export const mentionComponentBody = (props: MentionComponentBodyProps) => {
	return 'children' in props && Array.isArray(props.children) ? (
		<span className={props.className} data-mention={props.mention.name}>
			<Tooltip
				title={props.mention.description}
				trigger="hover"
				mouseLeaveDelay={0.001}
				overlayClassName={css.tooltip}
				destroyTooltipOnHide
			>
				<span contentEditable={false}>
					&#xFEFF;
					{React.Children.map(props.children, child => {
						/* It's mandatory to display the "children" provided by the "@draft-js-plugins/mention" package.
						 * The child is "DraftEditorLeaf" component, which contains all the methods and props necessary
						 * for "draftJS" to be able to edit the content properly, not lose the cursor, etc.,
						 * but the "text" property can be changed to replace the displayed text content.
						 */
						return React.cloneElement(child, { text: props.mention.placeholder });
					})}
					&#xFEFF;
				</span>
			</Tooltip>
		</span>
	) : (
		<span data-mention={props.mention.name}>{props.mention.placeholder}</span>
	);
};

// must follow to pattern from link
// {@link https://github.com/draft-js-plugins/draft-js-plugins/blob/master/packages/mention/src/Mention.tsx#L47}
const MentionComponent: FC<SubMentionComponentProps> = ({ children, className, mention }) => {
	return mentionComponentBody({ className, mention: mention as VariableDataType, children });
};

export const mentionPlugin = createMentionPlugin({
	entityMutability: 'IMMUTABLE',
	theme: css,
	supportWhitespace: true,
	mentionTrigger: ['$'],
	popperOptions: { strategy: 'fixed' }, // Custom position
	mentionPrefix: '$',
	mentionComponent: MentionComponent,
});

const { MentionSuggestions } = mentionPlugin;

/**
 * This is a configuration of UI elements that should be added to the DOM according to a certain logic
 * Each time the MentionSuggestionsComponent is rendered, it is reconfigured.
 * This method is chosen because there is no easy way to affect the structure of the MentionSuggestionsComponent itself,
 * so categories and title are added inside the Entry component.
 */
const suggestionsUI = {
	renderedCategoriesSet: new Set<VariableDataType['category']>(),
	isTitleRendered: false,
};

const Entry: FC<EntryComponentProps> = ({ mention, theme, searchValue, isFocused, selectMention, ...parentProps }) => {
	const { title, description, category } = mention as VariableDataType;

	const shouldRenderCategory = !suggestionsUI.renderedCategoriesSet.has(category);
	if (shouldRenderCategory) suggestionsUI.renderedCategoriesSet.add(category);

	const shouldRenderTitle = !suggestionsUI.isTitleRendered;
	if (shouldRenderTitle) suggestionsUI.isTitleRendered = true;

	return (
		<>
			{shouldRenderTitle && (
				<div className={css.header} tabIndex={-1}>
					<div className={css.headerTitle}>Variables</div>
					<div className={css.headerSubtitle}>Type to filter by variable, card or component name</div>
				</div>
			)}
			{shouldRenderCategory && <div className={css.category}>{category}</div>}
			<div {...parentProps}>
				<div className={theme?.mentionSuggestionsEntryContainer}>
					<div className={theme?.mentionSuggestionsEntryContainerLeft}>
						<svg
							className={theme?.mentionSuggestionsEntryAvatar}
							role="presentation"
							fill="#FFF"
							fillRule="evenodd"
							viewBox="0 0 13.211 14.281"
						>
							<path
								d={`M8.907 6.78l1.435 2.385h.05l1.442-2.386h1.346l-2.008 3.258 2.04
 3.258h-1.369l-1.45-2.37h-.051l-1.451 2.37H7.529l2.058-3.258L7.554 6.78h1.353zM3.286 3.766l-.209.753h1.267l-1.25
 5.465c-.225.978-.626 3.607-1.379 3.607-.112 0-.16-.048-.16-.113 0-.336.32-.16.32-.753 0-.4-.224-.85-.913-.85-.657
 0-.962.61-.962 1.17 0 .61.465 1.235 1.507 1.235 1.378 0 2.244-1.058
 2.676-1.891.305-.61.513-1.235.61-1.667l1.41-6.203h1.266l.192-.753H6.38l.208-1.042C6.812 1.635 7.213.689 7.71.689c.113 0
 .16.048.16.112 0 .337-.32.225-.32.802 0 .4.224.785.914.785.657 0 .961-.609.961-1.154C9.424.625 8.96 0 7.918 0 6.539 0
 5.658 1.042 5.24 1.907c-.48.994-.529 1.86-1.122 1.86h-.833z`}
							/>
						</svg>
					</div>

					<div className={theme?.mentionSuggestionsEntryContainerRight}>
						<div className={theme?.mentionSuggestionsEntryText}>{title}</div>

						<div className={theme?.mentionSuggestionsEntryTitle}>{description}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export type AvailableVariablesArr = ReturnType<typeof getAvailableVariables>;

type Suggestions = AvailableVariablesArr | Record<string, AvailableVariablesArr>;

const suggestionsFilter = (searchValue: string, suggestions: Suggestions, trigger?: string): AvailableVariablesArr => {
	const triggerSuggestions: AvailableVariablesArr =
		trigger && !Array.isArray(suggestions) ? suggestions[trigger] : (suggestions as AvailableVariablesArr);
	const regex = new RegExp(searchValue, 'i');
	return triggerSuggestions.filter(suggestion => regex.test(suggestion.title) || regex.test(suggestion.description));
};

const MentionSuggestionsComponent = ({ variables = [] }: { variables?: AvailableVariablesArr }) => {
	const isOpen = useToggle(false);
	const [suggestions, setSuggestions] = useState(variables);

	const onSearchChange = useCallback<MentionSuggestionsPubProps['onSearchChange']>(
		({ trigger, value }) => {
			// @example remote mentions
			// const url = 'https://swapi.dev/api/people/10/';
			// const response = await fetch(url);
			// console.info('=== response', response);
			// const json = await response.json();
			// console.info('=== json', json);

			setSuggestions(suggestionsFilter(value, variables, trigger));
		},
		[variables]
	);

	suggestionsUI.renderedCategoriesSet.clear();
	suggestionsUI.isTitleRendered = false;

	return (
		<MentionSuggestions
			open={isOpen.value}
			onOpenChange={isOpen.set}
			suggestions={suggestions}
			onSearchChange={onSearchChange}
			entryComponent={Entry}
		/>
	);
};

export default MentionSuggestionsComponent;
