import { get } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import type { CardAction, CardData, StorySettingsType } from 'types/story';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import t from 'common/utils/translate';
import { CARD_TYPE, CountType } from 'common/constants';
import { StoryFacade } from 'common/utils/facades/story-facade';
import { selectEditableStory } from 'admin/reducers/story-editor/selectors';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import Text from 'admin/components/common/Text';
import { Modal } from 'admin/components/common/Modal';
import { updateEditableStory } from 'admin/actions/story/update-editable-story';
import { MODAL } from 'admin/constants/common';
import { VariableType } from 'src/common/utils/variables/types';
import { ModalType } from './modal-type';
import { Content as EditCardContent } from './Content';
import { Content as EditCardActionContent } from './ActionContent';
import EditCardModalForm, { Values as EditCardModalFormValues } from './Form';
import EditCardActionModalForm, { Values as EditCardActionModalFormValues } from './ActionForm';
import { createOutputVariable, markupToString } from './GptTextResponseAction/helpers';
import { MODEL } from './GptTextResponseAction/constants';
import css from './EditCardModal.scss';

export type EditCardModalData = {
	stepId: string;
	cardId: string;
	onSave: () => void;
	onDiscard: () => void;
};

const getEditType = (card: CardData | undefined, storySettings: StorySettingsType) => {
	if (!card) {
		return ModalType.default;
	}

	switch (card.type) {
		case CARD_TYPE.NAVIGATION:
			if (storySettings.cards?.[card._id]?.countType === CountType.character) {
				return ModalType.editCharacterNav;
			}
			if (storySettings.cards?.[card._id]?.countType === CountType.score) {
				return ModalType.editScoreNav;
			}
			if (storySettings.cards?.[card._id]?.countType === CountType.urlParam) {
				return ModalType.editUrlParamsNav;
			}
			if (storySettings.cards?.[card._id]?.countType === CountType.redirect) {
				return ModalType.editRedirectNav;
			}
			if (storySettings.cards?.[card._id]?.countType === CountType.gptTextResponse) {
				return ModalType.editGptTextResponseNav;
			}
			return ModalType.editRateNav;

		default:
			return ModalType.default;
	}
};

const getTitle = (editType: ModalType) => {
	switch (editType) {
		case ModalType.editCharacterNav:
			return 'Edit character events';
		case ModalType.editScoreNav:
			return 'Edit score events';
		default:
			return t('story.editCardModal.title');
	}
};

const getCardActionInititalValues = (card: CardData | undefined, storyFacade: StoryFacade) => {
	if (!card) {
		return undefined;
	}

	const action = storyFacade.settings?.actions?.[card._id];
	const outputs = Object.entries(action?.outputsRaw || {}).map(([output, definition]) =>
		createOutputVariable({ output, definition: definition || '' })
	);

	if (outputs.length === 0) {
		outputs.push(createOutputVariable());
	}

	return {
		_id: card._id,
		type: 'completions' as const,
		template: action?.templateRaw || '',
		system: action?.systemRaw || '',
		model: action?.model || MODEL.GPT_4,
		outputs,
	};
};

const EditCardModal: React.FC<ModalManagerProvidedProps<MODAL.EDIT_CARD>> = props => {
	const dispatch = useAdminDispatch();
	const version = useAdminSelector(state => state.version.current);
	const story = useAdminSelector(selectEditableStory)!;
	const { open, data, close } = props;
	const { onDiscard, onSave } = data;

	const storyFacade = useMemo(() => new StoryFacade(story, version), [story, version]);
	const cardPath = useMemo(() => {
		if (!data.stepId || !data.cardId) return '';
		const { card, step } = storyFacade.getDataByCardId(data.cardId);
		const stepIndex = step?.index;
		const cardIndex = card?.index;
		return `storyVersions.${version}.data.steps.${stepIndex}.cards.${cardIndex}`;
	}, [data.stepId, data.cardId, storyFacade, version]);

	const card: CardData | undefined = get(story, cardPath);
	const editType = getEditType(card, story.storyVersions[version].settings);
	const editCardInitialValues: EditCardModalFormValues | undefined = card;
	const editCardActionInitialValues: EditCardActionModalFormValues | undefined = useMemo(
		() =>
			editType === ModalType.editGptTextResponseNav ? getCardActionInititalValues(card, storyFacade) : undefined,
		[card, storyFacade, editType]
	);

	const onSaveHandler = useCallback(() => {
		onSave();
		close();
	}, [onSave, close]);

	const onDiscardHandler = useCallback(() => {
		onDiscard();
		close();
	}, [onDiscard, close]);

	const onEditCardSubmit = (values: EditCardModalFormValues) => {
		dispatch(
			updateEditableStory({
				data: values,
				path: cardPath,
				updateForm: true,
				isVersionPath: false,
			})
		);
	};
	const onEditCardActionSubmit = (values: EditCardActionModalFormValues) => {
		const actionId = values._id;
		const submitValues: Partial<CardAction> = {
			...values,
			templateRaw: values.template,
			template: markupToString(values.template),
			systemRaw: values.system,
			system: markupToString(values.system),
			outputs: values.outputs.reduce((acc, output) => {
				acc[output.output] = markupToString(output.definition);
				return acc;
			}, {}),
			outputsRaw: values.outputs.reduce((acc, output) => {
				acc[output.output] = output.definition;
				return acc;
			}, {}),
		};

		delete submitValues._id;

		const customVariables = [...(storyFacade.settings.variables?.custom ?? [])].filter(v => {
			if (v.type === VariableType.gptActionOutput) {
				return v.actionId !== actionId;
			}
			return true;
		});

		values.outputs.forEach(({ output, definition }) => {
			customVariables.push({
				type: VariableType.gptActionOutput,
				actionId: values._id,
				outputKey: output,
				value: definition,
			});
		});

		dispatch(
			updateEditableStory({
				data: customVariables,
				path: `storyVersions.${version}.settings.variables.custom`,
				updateForm: true,
				isVersionPath: false,
			})
		);

		dispatch(
			updateEditableStory({
				data: submitValues,
				path: `storyVersions.${version}.settings.actions.${values._id}`,
				updateForm: true,
				isVersionPath: false,
			})
		);
	};

	if (!card) {
		return null;
	}

	return (
		<Modal
			title={<Text className={css.title} size={Text.size.paragraph} text={getTitle(editType)} />}
			open={open}
			destroyOnClose
			width="600px"
			className={css.modal}
			maskColor="black"
			theme="dark"
			onCancel={data.onDiscard}
		>
			{editType === ModalType.editGptTextResponseNav ? (
				<EditCardActionModalForm
					initialValues={editCardActionInitialValues}
					onSubmit={onEditCardActionSubmit}
					onSubmitSuccess={onSaveHandler}
				>
					{form =>
						form?.formData?._id ? (
							<EditCardActionContent
								card={card}
								form={form}
								editType={editType}
								onDiscard={onDiscardHandler}
							/>
						) : null
					}
				</EditCardActionModalForm>
			) : (
				<EditCardModalForm
					initialValues={editCardInitialValues}
					onSubmit={onEditCardSubmit}
					onSubmitSuccess={onSaveHandler}
				>
					{form =>
						form?.formData?._id ? (
							<EditCardContent onDiscard={onDiscardHandler} editType={editType} form={form} />
						) : null
					}
				</EditCardModalForm>
			)}
		</Modal>
	);
};

export default EditCardModal;
