import React, { HTMLAttributes } from 'react';
import { addDataAttribute } from 'admin/components/common/Grid/utils';

// See Grid/_vars.scss -> $grid-column-template
export type GridColumn = '2' | '6' | '12' | '14' | 'auto';

// See Grid/_vars.scss -> $grid-gap
const GAP = ['small', 'medium', 'large'] as const;
export type Gap = (typeof GAP)[number];

type Props = {
	columns: GridColumn;
	gap?: Gap;
	rowGap?: Gap;
	columnGap?: Gap;
	children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Grid = ({ columns, gap, rowGap, columnGap, ...props }: Props) => {
	const dataAttributes = {};
	addDataAttribute('grid', '', dataAttributes);
	addDataAttribute('columns', columns, dataAttributes);
	addDataAttribute('gap', gap, dataAttributes);
	addDataAttribute('row-gap', rowGap, dataAttributes);
	addDataAttribute('column-gap', columnGap, dataAttributes);

	return <div {...dataAttributes} {...props} />;
};

Grid.gap = { small: GAP[0], medium: GAP[1], large: GAP[2] };

Grid.defaultProps = {
	gap: undefined,
	rowGap: undefined,
	columnGap: undefined,
};

export { Grid };
