import React from 'react';
import dayjs from 'dayjs';
import { MenuProps } from 'antd';
import cn from 'classnames';

import { getWebhookLogs, postRetryWebhookLog } from 'admin/resources';

import translate from 'src/common/utils/translate';
import Text from 'src/routes/admin/components/common/Text';
import Dropdown from 'src/routes/admin/components/common/Dropdown';
import { Icon } from 'src/routes/admin/components/common/Icon';
import Button from 'src/routes/admin/components/common/Button';
import SwitchButton from 'src/routes/admin/components/common/Form/SwitchButton';
import css from './WebhookLogs.scss';

const t = (path: string) => translate(`common.integrations.views.webhooks.historyLogs.${path}`);

interface Props {
	className?: string;
	webhookId: string;
}

interface LogData {
	_id: string;
	organizationId: string;
	webhookId: string;
	request: {
		body: string;
		date: string;
		headers: Record<string, string>;
		method: string;
		url: string;
	};
	response: {
		body: Record<string, string>;
		headers: Record<string, string[]>;
		status: number;
		statusText: string;
		time: number;
	};
	trigger: {
		id: string;
		date: string;
		event: string;
	};
}

const WebhookLogs: React.FC<Props> = props => {
	const { className, webhookId } = props;
	const [currentView, setCurrentView] = React.useState<'list' | 'details_req' | 'details_res'>('list');
	const [currentLogId, setCurrentLogId] = React.useState<string | null>(null);
	const [logs, setLogs] = React.useState<LogData[]>([]);
	const [newLogs, setNewLogs] = React.useState<LogData[]>([]);
	const [fetchStatus, setFetchStatus] = React.useState<'idle' | 'loading' | 'success' | 'error'>('idle');

	React.useEffect(() => {
		let isMounted = true;
		const fetchWebhookLogs = async () => {
			setFetchStatus('loading');
			const response = await getWebhookLogs.params({ webhookId }).send();
			const data = response.body as LogData[];

			if (!isMounted) {
				return;
			}

			if (response.ok) {
				setLogs(data);
				setFetchStatus('success');
			} else {
				setFetchStatus('error');
			}
		};

		fetchWebhookLogs();

		return () => {
			isMounted = false;
		};
	}, [webhookId]);

	const onLogItemDetailsClick = (logId: string) => {
		setNewLogs([]);
		setCurrentLogId(logId);
		setCurrentView('details_req');
	};

	const onReDeliverBtnClick = async (logId: string) => {
		setNewLogs([]);
		setFetchStatus('loading');
		setCurrentView('list');

		const response = await postRetryWebhookLog.params({ webhookId, webhookLogId: logId }).send();

		if (response.ok) {
			await new Promise(resolve => setTimeout(resolve, 3000));
			const res = await getWebhookLogs.params({ webhookId }).send();
			const data = res.body as LogData[];

			if (res.ok) {
				document.querySelector(`.${css.list}`)?.scrollTo(0, 0);

				setLogs(data);
				setNewLogs(data.filter(log => !logs.some(l => l._id === log._id)));
				setFetchStatus('success');
			} else {
				setFetchStatus('error');
			}
		} else {
			setFetchStatus('error');
		}
	};

	const renderLogData = (logData: LogData) => {
		const isSuccess = isResponseStatusCodeSuccess(logData.response.status);
		const listItemMenu: MenuProps['items'] = [
			{
				key: 'retry',
				title: t('reDeliver'),
				style: { alignItems: 'flex-start' },
				label: (
					<div className={css.dropdownBtn}>
						<Text size="description" color="white">
							{t('reDeliver')}
						</Text>
					</div>
				),
				onClick: () => onReDeliverBtnClick(logData._id),
			},
			{
				key: 'details',
				title: t('details'),
				style: { alignItems: 'flex-start' },
				label: (
					<div className={css.dropdownBtn}>
						<Text size="description" color="white">
							{t('details')}
						</Text>
					</div>
				),
				onClick: () => onLogItemDetailsClick(logData._id),
			},
		];
		return (
			<div
				className={cn(
					css.listItem,
					currentView === 'list' ? css.listView : css.detailsView,
					currentView === 'list' && newLogs.some(l => l._id === logData._id) && css.withBlink
				)}
				key={logData._id}
			>
				<div className={css.listItemInfo} onClick={() => onLogItemDetailsClick(logData._id)}>
					<div className={cn(css.listItemIcon, isSuccess ? css.success : css.fail)}>
						{isSuccess ? (
							<Icon width={10} height={8} type="sc-check" />
						) : (
							<Icon width={14} height={14} type="sc-alert" />
						)}
					</div>
					<Text className={css.listItemInfoId} size="description">
						{logData.webhookId}
					</Text>
					<Text className={css.listItemInfoDate} size="footnote">
						{dayjs(logData.trigger.date).format('YYYY.MM.DD HH:mm:ss')}
					</Text>
				</div>
				{currentView === 'list' ? (
					<Dropdown menu={{ items: listItemMenu }}>
						<div className={css.listItemBtn}>
							<i />
							<i />
							<i />
						</div>
					</Dropdown>
				) : isSuccess === false ? (
					<Button
						className={css.redeliverBtn}
						view="secondary-gray"
						size="small"
						theme="dark"
						onClick={() => onReDeliverBtnClick(logData._id)}
					>
						<Icon className={css.redeliverIcon} type="sc-rotate-right-thick" width={13} height={13} />
						<Text size="description">{t('reDeliver')}</Text>
					</Button>
				) : null}
			</div>
		);
	};

	const renderHeaders = (headers: Record<string, string | string[]>) => {
		return (
			<div className={css.detailsHeaders}>
				{Object.entries(headers).map(([key, value]) => (
					<div className={css.detailsHeadrItem} key={key}>
						<Text className={css.detailsHeaderKey} size="description" weight="semibold" tag="span">
							{key}:{' '}
						</Text>
						<Text className={css.detailsHeaderValue} size="description" tag="span">
							{JSON.stringify(value)}
						</Text>
					</div>
				))}
			</div>
		);
	};

	const renderPayload = (payload: string | Record<string, string>) => {
		let formattedPayload = payload;

		try {
			if (typeof formattedPayload === 'string') {
				formattedPayload = JSON.stringify(JSON.parse(formattedPayload), null, 2);
			} else {
				formattedPayload = JSON.stringify(formattedPayload, null, 2);
			}
		} catch {
			// Do nothing
		}

		return (
			<div className={css.detailsPayload}>
				<Text className={css.detailsPayloadValue} size="description">
					{formattedPayload}
				</Text>
			</div>
		);
	};

	const renderNoData = () => {
		switch (fetchStatus) {
			case 'loading':
				return t('loading');

			default:
				return t('noData');
		}
	};

	const renderCurrentView = () => {
		switch (currentView) {
			case 'list':
				return (
					<>
						<Text className={css.title} size="subtitle">
							{t('recentDeliveries')}
						</Text>
						<div className={cn(css.list, fetchStatus === 'loading' && css.loading)}>
							{logs.length ? (
								logs.map(renderLogData)
							) : (
								<Text className={css.noData} size="description">
									{renderNoData()}
								</Text>
							)}
						</div>
					</>
				);

			case 'details_req':
			case 'details_res': {
				const currentLogData = logs.find(log => log._id === currentLogId)!;
				const isSuccess = isResponseStatusCodeSuccess(currentLogData.response.status);

				return (
					<>
						<button className={css.backBtn} type="button" onClick={() => setCurrentView('list')}>
							<Icon type="chevron-left" width={20} height={20} />
							<Text size="paragraph">{t('recentDeliveries')}</Text>
						</button>
						<div className={css.detailsHeader}>{renderLogData(currentLogData)}</div>
						<div className={css.details}>
							<SwitchButton
								className={css.detailsSwitch}
								btnClassName={css.detailsSwitchBtn}
								btnActiveClassName={css.detailsSwitchBtnActive}
								leftButtonText={
									<Text className={css.detailsSwitchBtnText} size="label">
										{t('request')}
									</Text>
								}
								rightButtonText={
									<>
										<Text className={css.detailsSwitchBtnText} size="label">
											{t('response')}
										</Text>
										<div
											className={cn(
												css.detailsSwitchBtnStatus,
												isSuccess ? css.success : css.fail
											)}
										>
											{currentLogData.response.status}
										</div>
									</>
								}
								onSwitch={position => {
									setCurrentView(position === 'left' ? 'details_req' : 'details_res');
								}}
							/>
							<div className={css.detailsContent}>
								<Text className={css.detailsSubheading} size="label">
									{t('headers')}
								</Text>
								{renderHeaders(
									currentView === 'details_req'
										? currentLogData.request.headers
										: currentLogData.response.headers
								)}
								<Text className={css.detailsSubheading} size="label">
									{t('payload')}
								</Text>
								{renderPayload(
									currentView === 'details_req'
										? currentLogData.request.body
										: currentLogData.response.body
								)}
							</div>
						</div>
					</>
				);
			}
			default:
				return null;
		}
	};

	return <div className={cn(css.webhookLogs, className)}>{renderCurrentView()}</div>;
};

function isResponseStatusCodeSuccess(statusCode: number) {
	return statusCode >= 200 && statusCode < 300;
}

export default WebhookLogs;
