import React, { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { get, set } from 'lodash';

import t from 'common/utils/translate';
import { FORM_MODEL } from 'admin/constants/common';
import type { AdminReducerState } from 'admin/reducers';
import type { CardAction } from 'types/story';

export const fields = {
	// GPT Text Response Card:
	type: 'type',
	template: 'template', // user data
	system: 'system',
	model: 'model',
	outputsVariable: (index: number) => `outputs.${index}.output`,
	outputsDefinition: (index: number) => `outputs.${index}.definition`,
};

export type Values = Omit<CardAction, 'outputs' | 'outputsRaw' | 'templateRaw' | 'systemRaw'> & {
	outputs: { id: string; output: string; definition: string }[];
};

type PureProps = ConnectedProps<typeof connector> & {
	children: (form: ChildrenFormProp) => ReactNode;
	onSubmit: (values: Values) => void;
};
type FormProp = InjectedFormProps<Values, PureProps>;
type Props = PureProps & FormProp;
export type ChildrenFormProp = FormProp & Pick<PureProps, 'formData'>;

/**
 * Render children and pass all form props as form
 */
const Form = ({ children, onSubmit, ...form }: Props) => (
	<form onSubmit={form.handleSubmit(onSubmit)}>{children(form)}</form>
);

const EditCardActionModalForm = reduxForm<Values, PureProps>({
	validate: values => {
		const errors = {};

		const template = get(values, fields.template);
		const templateLength = template?.replace(/<[^>]+>/g, '').trim().length;
		if (!template || !templateLength) {
			set(errors, fields.template, t(`story.settings.actions.${fields.template}.required`));
		}

		values.outputs.forEach((data, i) => {
			if (!data.definition?.trim())
				set(errors, fields.outputsDefinition(i), t('story.settings.actions.outputs.variable.required'));
			if (!data.output?.trim())
				set(errors, fields.outputsVariable(i), t('story.settings.actions.outputs.definition.required'));
		});

		return errors;
	},
	form: FORM_MODEL.EDIT_CARD_ACTION,
})(Form);

const mapStateToProps = (state: AdminReducerState) => ({
	formData: get(state, `form.${FORM_MODEL.EDIT_CARD_ACTION}.values`) as Values,
});

const connector = connect(mapStateToProps);

export default connector(EditCardActionModalForm);
