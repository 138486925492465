import React, { forwardRef } from 'react';
import cn from 'classnames';
import { isFunction } from 'lodash';

import { THEME } from 'admin/components/common/Theme';
import { Errors } from 'admin/components/common/Form/Errors';
import { LabelProps, Label } from 'admin/components/common/Form/Label';
import type { ReduxFieldInputTypes, ReduxFieldMetaTypes } from 'admin/components/common/Form/utils';
import css from './TextArea.scss';

type TextAreaElementProps = React.JSX.IntrinsicElements['textarea'];

type TextAreaCommonProps = Pick<
	TextAreaElementProps,
	'rows' | 'className' | 'readOnly' | 'placeholder' | 'disabled' | 'autoComplete' | 'autoFocus'
> & {
	theme?: ValuesType<typeof THEME>;
	resize?: 'vertical' | 'none';
	label?: Omit<LabelProps, 'component'>;
};

type TextAreaPureProps = TextAreaCommonProps &
	Pick<TextAreaElementProps, 'value' | 'name' | 'id' | 'onChange' | 'onBlur' | 'onFocus'>;

export const TextAreaPure = forwardRef<HTMLTextAreaElement, TextAreaPureProps>(
	({ theme = 'light', rows = 5, readOnly = false, resize = 'none', ...props }, ref) => {
		return (
			<label
				className={cn(css.textareaField, css[theme], css[`resize-${resize}`], props.className, {
					[css.readOnly]: readOnly,
				})}
			>
				{props.label ? (
					<Label
						theme={props.label.theme ?? theme}
						className={cn(css.labelText, props.label.className)}
						weight={props.label.weight}
						component="div"
					>
						{props.label.children}
					</Label>
				) : null}
				<div className={css.fieldWrapper}>
					<textarea
						value={props.value}
						id={props.id}
						rows={rows}
						name={props.name}
						placeholder={props.placeholder}
						autoComplete={props.autoComplete}
						onChange={props.disabled ? undefined : props.onChange}
						onBlur={props.disabled ? undefined : props.onBlur}
						onFocus={props.disabled ? undefined : props.onFocus}
						disabled={props.disabled}
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus={props.autoFocus}
						ref={ref}
					/>
				</div>
			</label>
		);
	}
);

interface TextAreaProps extends TextAreaCommonProps {
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	showErrorsOn?: keyof ReduxFieldMetaTypes | ((meta: ReduxFieldMetaTypes, input: ReduxFieldInputTypes) => boolean);
}

/**
 * Redux form textarea field component
 */
export const TextArea: React.FC<TextAreaProps> = ({
	showErrorsOn = 'touched',
	meta,
	input,
	theme = 'light',
	className,
	...fieldProps
}) => {
	const id = `${meta.form}.${input.name}`;
	const showErrors = isFunction(showErrorsOn) ? showErrorsOn(meta, input) : meta.error && meta[showErrorsOn || ''];

	return (
		<div className={cn(css.textarea, css[theme], className, { [css.error]: showErrors })}>
			<TextAreaPure id={id} theme={theme} {...fieldProps} {...input} />
			<Errors show={showErrors}>{meta.error}</Errors>
		</div>
	);
};
