import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, Field, InjectedFormProps, SubmissionError } from 'redux-form';
import _ from 'lodash';

import t from 'utils/translate';
import { passwordRegExp } from 'utils/regexp';

import type { AdminReducerState } from 'admin/reducers';
import Text from 'admin/components/common/Text';
import { FORM_MODEL, MODAL } from 'admin/constants/common';
import { TextField } from 'admin/components/common/Form';
import Button from 'admin/components/common/Button';
import { Column, Grid } from 'admin/components/common/Grid';
import PasswordFieldChecker from 'admin/components/common/PasswordFieldChecker';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { updateMemberPassword } from 'admin/actions';

import css from './ChangeUserPasswordModal.scss';

const mapStateToProps = ({ form }: AdminReducerState) => ({
	formData: form[`${FORM_MODEL.CHANGE_USER_PASSWORD}`]?.values,
});

const mapDispatchToProps = {
	updateMemberPassword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Values = {
	oldPassword: '';
	newPassword: '';
	newPassword2: '';
};

type OwnProps = ModalManagerProvidedProps<MODAL.CHANGE_USER_PASSWORD>;

type Props = OwnProps & InjectedFormProps<Values, OwnProps> & ConnectedProps<typeof connector>;

class ChangeUserPasswordModal extends Component<Props> {
	renderTitle = () => (
		<Text
			size={Text.size.subtitle}
			weight={Text.weight.semibold}
			compact
			text={t('profile.changePassModal.title')}
		/>
	);

	onFormSubmit = async (values: Values) => {
		const res = await this.props.updateMemberPassword({
			oldPassword: values.oldPassword,
			newPassword: values.newPassword,
		});

		if (res.success) {
			this.props.close();
		} else if (res.errors) {
			const err = res.errors[0].message;

			if (_.toLower(err) === 'forbidden') {
				throw new SubmissionError({ oldPassword: t('profile.changePassModal.submissionErrors.wrongPassword') });
			}
		}
	};

	renderForm = () => {
		const { formData, close, handleSubmit, submitting, dirty } = this.props;

		return (
			<form className={css.form} onSubmit={handleSubmit(this.onFormSubmit)}>
				<ModalBody>
					<Grid columns="12" columnGap="large">
						<Column colSpan="7">
							<Field
								className={css.field}
								name="oldPassword"
								component={TextField}
								autoComplete="off"
								isLabelUppercase={false}
								type="password"
								label={t('profile.changePassModal.fields.oldPassword')}
								isRequired
							/>
							<Field
								className={css.field}
								name="newPassword"
								component={TextField}
								autoComplete="off"
								isLabelUppercase={false}
								type="password"
								label={t('profile.changePassModal.fields.newPassword')}
								isRequired
							/>
							<Field
								className={css.field}
								name="newPassword2"
								component={TextField}
								autoComplete="off"
								isLabelUppercase={false}
								type="password"
								label={t('profile.changePassModal.fields.newPasswordAgain')}
								isRequired
							/>
						</Column>
						<Column alignItems="flex-end" colSpan="5">
							<div className={css.checkPassWrap}>
								<Text className={css.checkPassTitle} weight={Text.weight.semibold}>
									{t('profile.changePassModal.checkPasswordTitle')}
								</Text>
								<PasswordFieldChecker view="vertical" value={_.get(formData, 'newPassword', '')} />
							</div>
						</Column>
					</Grid>
				</ModalBody>

				<ModalFooter>
					<Button disabled={!dirty || submitting} view="primary" type="submit">
						{t('profile.changePassModal.delete')}
					</Button>
					<Button disabled={submitting} view="secondary" onClick={close}>
						{t('profile.changePassModal.cancel')}
					</Button>
				</ModalFooter>
			</form>
		);
	};

	render() {
		return (
			<Modal title={this.renderTitle()} open={this.props.open} destroyOnClose width="864px">
				{this.renderForm()}
			</Modal>
		);
	}
}

const ChangePasswordForm = reduxForm({
	validate: (values: Values, props: any) => {
		const errors: Partial<Values> = {};

		if (!values.oldPassword) {
			_.set(errors, 'oldPassword', t('profile.changePassModal.errors.required'));
		}

		if (!values.newPassword) {
			_.set(errors, 'newPassword', t('profile.changePassModal.errors.required'));
		} else if (!passwordRegExp.test(values.newPassword)) {
			_.set(errors, 'newPassword', t('profile.changePassModal.errors.patternError'));
		}

		if (!values.newPassword2) {
			_.set(errors, 'newPassword2', t('profile.changePassModal.errors.required'));
		} else if (values.newPassword && values.newPassword2 && values.newPassword !== values.newPassword2) {
			_.set(errors, 'newPassword2', t('profile.changePassModal.errors.missmatchError'));
		}

		return errors;
	},
	form: FORM_MODEL.CHANGE_USER_PASSWORD,
})(ChangeUserPasswordModal);

export default connector(ChangePasswordForm);
