import React, { useState } from 'react';
import cms from 'common/utils/cms';
import type { ComponentTypes } from 'types/story';
import type { CollectionPaginationTriggersProp } from 'types/cms';
import { COMPONENT_TYPE } from 'common/constants';
import { useDidMount } from 'common/components/useDidMount';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { componentWalkFull } from 'common/utils/blocks/component-walk-full';
import { MODAL } from 'admin/constants/common';
import { useAdminSelector } from 'admin/reducers';
import Button from 'admin/components/common/Button';
import { Select } from 'admin/components/common/Form';
import { useTheme } from 'admin/components/common/Theme';
import { Column, Grid } from 'admin/components/common/Grid';
import { Modal, ModalBody, ModalFooter } from 'admin/components/common/Modal';
import { selectEditableElement } from 'admin/reducers/card-editor-extra/selectors';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import { getPFCompPropName } from 'admin/components/pages/Story/CardEditor/Inspector/PropField';
import type { InspectorContextType } from 'admin/components/pages/Story/CardEditor/Inspector/InspectorContext';
import {
	selectEditableCardElements,
	selectEditableStoryElements,
	selectEditableSymbols,
} from 'admin/reducers/card-editor/selectors';

export type EditCmsPaginationTriggersModalData = {
	onSave: InspectorContextType['onUpdate'];
};

type ModalProps = ModalManagerProvidedProps<MODAL.EDIT_CMS_PAGINATION_TRIGGERS>;

type triggerType = keyof CollectionPaginationTriggersProp;

type triggerValue = ValuesType<CollectionPaginationTriggersProp>;

const ModalContent: React.FC<ModalProps> = props => {
	const element = useAdminSelector(selectEditableElement);
	const currentTriggers = cms.getComponentProp(element, UNIFORM_PROPS.collectionPaginationTriggers);

	const { theme } = useTheme();
	const componentsList = useComponentsList();

	const [prev, setPrev] = useState(currentTriggers?.prev ?? cms.defaultProps.collectionPaginationTriggers.prev);
	const [next, setNext] = useState(currentTriggers?.next ?? cms.defaultProps.collectionPaginationTriggers.next);

	return (
		<>
			<ModalBody>
				<Grid columns="6" gap={Grid.gap.medium}>
					{renderFields()}
				</Grid>
			</ModalBody>
			<ModalFooter>
				<Button view="primary" onClick={handleSave}>
					Save
				</Button>
				<Button view="secondary-gray" onClick={props.close}>
					Discard
				</Button>
			</ModalFooter>
		</>
	);

	function onAddClick(type: triggerType, value: string) {
		const actions = { prev: setPrev, next: setNext };
		actions[type](value);
	}

	function renderFields() {
		const labels: Record<triggerType, string> = { prev: 'Previous page', next: 'Next page' };
		const items: Record<triggerType, triggerValue> = { prev, next };
		const options = Array.from(componentsList.values());

		const renderField = (type: triggerType) => {
			return (
				<Column colSpan="4">
					<Select
						value={items[type]}
						label={{ children: labels[type], theme }}
						options={options}
						theme={theme}
						eventListeners={{ onChange: _value => onAddClick(type, _value) }}
						menuPlacement="bottom"
						maxMenuHeight={200}
						isSearchable
					/>
				</Column>
			);
		};

		return (Object.keys(currentTriggers) as triggerType[]).map(renderField);
	}

	function handleSave() {
		// filter to drop triggers that refers to component that already does not exist
		const filter = (items: string[]) => items.filter(item => componentsList.has(item));

		(props.data satisfies EditCmsPaginationTriggersModalData).onSave({
			name: getPFCompPropName(UNIFORM_PROPS.collectionPaginationTriggers, 'uniform')({ path: element.path }),
			value: {
				prev: filter([prev])[0],
				next: filter([next])[0],
			} satisfies CollectionPaginationTriggersProp,
		});

		props.close();
	}
};

function useComponentsList() {
	const symbols = useAdminSelector(selectEditableSymbols);
	const elements = useAdminSelector(selectEditableCardElements)!;
	const storyElements = useAdminSelector(selectEditableStoryElements);
	const [components, setComponents] = useState<Map<string, { label: string; value: string }>>(new Map());

	useDidMount(() => {
		const map: typeof components = new Map();

		const whiteList = new Set<ComponentTypes>([
			// COMPONENT_TYPE.TEXT,
			// COMPONENT_TYPE.IMG,
			COMPONENT_TYPE.BUTTON,
			// COMPONENT_TYPE.BOX,
			// COMPONENT_TYPE.SHAPE,
			// COMPONENT_TYPE.VIDEO,
			// COMPONENT_TYPE.TIMER,
			// COMPONENT_TYPE.LOTTIE,
		]);

		componentWalkFull({
			symbols,
			elements,
			storyElements,
			callback: ({ component, path, stopCurrent }) => {
				if (whiteList.has(component.type)) {
					const isRepeatable = Boolean(component.uiConfig.componentProps.collectionId);

					if (isRepeatable) {
						// omit repeatable and its children
						stopCurrent();
					} else {
						map.set(component._id, { label: component.uiConfig.editorProps.name, value: component._id });
					}
				}
			},
		});

		setComponents(map);
	});

	return components;
}

const CmsPaginationTriggersModal: React.FC<ModalProps> = props => {
	return (
		<Modal theme="dark" maskColor="black" open={props.open} destroyOnClose width={640} transitionName="">
			<ModalContent {...props} />
		</Modal>
	);
};

export default CmsPaginationTriggersModal;
