import React from 'react';
import { MODAL } from 'admin/constants/common';
import Text from 'admin/components/common/Text';
import { Modal } from 'admin/components/common/Modal';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import type { ComponentBindableProps } from '../utils';
import ModalContent from './ModalContent';
import css from './CmsPropBindModalData.scss';

const titleMap: Record<CmsPropBindModalData['view'], string> = {
	add: 'Link property to collection',
	edit: 'Edit property link',
};

const viewMap: Record<CmsPropBindModalData['view'], typeof ModalContent> = {
	add: ModalContent,
	edit: ModalContent,
};

interface DataPropCommon {
	properties: ComponentBindableProps;
	onSave: (props: { collectionId: string; dataKey: string; dataId: string; property: string }) => void;
	// id of the collection to which the property is bound already or recommended to be bound
	collectionId?: string;
	// id of the collection that repeats current item
	repeatableCollectionId?: string;
	// id of the collection that current card is bound
	dynamicCardCollectionId?: string;
}

interface EditDataProp extends DataPropCommon {
	view: 'edit';
	dataId: string;
	dataKey: string;
}

interface AddDataProp extends DataPropCommon {
	view: 'add';
}

export type CmsPropBindModalData = EditDataProp | AddDataProp;

type Props = ModalManagerProvidedProps<MODAL.CMS_PROP_BIND_MODAL>;

const CmsPropBindModal: React.FC<Props> = props => {
	const ViewComponent = viewMap[props.data.view];
	const title = titleMap[props.data.view];

	return (
		<Modal
			theme="dark"
			maskColor="black"
			open={props.open}
			destroyOnClose
			width={740}
			className={css.modal}
			transitionName=""
			title={title ? <Text size={Text.size.subtitle} weight={Text.weight.semibold} compact text={title} /> : null}
		>
			<ViewComponent {...props} />
		</Modal>
	);
};

export default CmsPropBindModal;
