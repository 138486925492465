import { generatePath } from 'utils/route';
import urlHelper from 'utils/url-helper';
import { ADMIN_SESSION } from 'common/constants';
import { getAssetURL } from 'utils/assets';
import { postGallery as postGalleryEndpoint } from 'admin/resources';
import { createThunkAction, uploadFileHelper } from 'admin/actions/helpers';

type PostGalleryParams = {
	storyId: string;
	asset?: File;
	url?: string;
	dataUrl?: string;
};

export const postGallery = createThunkAction<{ asset: string }, PostGalleryParams>({
	payloadCreator: async ({ asset, url, dataUrl, storyId }) => {
		const targetUrl = `${urlHelper.adminBackend}${generatePath(postGalleryEndpoint.url, { storyId })}`;
		const sessionId = localStorage.getItem(ADMIN_SESSION) || '';
		const response: IBackendBody<{ filepath: string; hosting: string }> = await uploadFileHelper({
			asset,
			url,
			dataUrl,
			apiUrl: targetUrl,
			sessionId,
		});
		return { ...response, result: { asset: getAssetURL(response.result, true) } };
	},
});
