import { isFunction } from 'lodash';
import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Icon } from 'admin/components/common/Icon';
import { Label, LabelProps } from '../Label';
import { Errors } from '../Errors';
import { ReduxFieldInputTypes, ReduxFieldMetaTypes } from '../utils';
import css from './Radio.scss';

type PureRadioProps = {
	id?: string;
	label?: LabelProps;
	optionLabel?: ReactNode;
	className?: string;
	name?: string;
	value?: string | number | boolean | null;
	checked?: boolean;
	defaultChecked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	theme?: 'dark' | 'light';
};

type RadioProps = Omit<PureRadioProps, 'value'> & {
	propValue: PureRadioProps['value'];
	input: ReduxFieldInputTypes;
	meta: ReduxFieldMetaTypes;
	showErrorsOn?: string;
	fieldClassName?: string;
};

// independent field
export const RadioPure = ({ className, label, optionLabel, theme = 'light', ...props }: PureRadioProps) => (
	<label className={cn(css.radio, className)}>
		<Label theme={theme} htmlFor={props.id} {...label} className={cn(css.label, label?.className)} />
		<div className={cn(css.radioInner)}>
			<input {...props} type="radio" value={`${props.value}`} />
			<Icon
				className={css.radioBox}
				type={`${props.checked ? 'check-outline' : 'empty'}-rounded`}
				color={
					props.checked
						? 'var(--ra-color-brand-light)'
						: theme === 'light'
							? 'var(--ra-color-black-soft)'
							: 'var(--ra-color-white-soft)'
				}
			/>
			{optionLabel}
		</div>
	</label>
);

// redux field
export const Radio = ({ theme = 'light', showErrorsOn = 'touched', ...props }: RadioProps) => {
	const showErrors = isFunction(showErrorsOn)
		? showErrorsOn(props.meta, props.input)
		: props.meta[showErrorsOn || ''];
	const id = `${props.meta.form}.${props.input.name}`;
	const currentValue = props.input.value === '' ? null : props.input.value; // allow null as value

	return (
		<div className={cn(css.fieldRadio, css[theme], props.className, { [css.disabled]: props.disabled })}>
			<RadioPure
				id={id}
				label={props.label}
				optionLabel={props.optionLabel}
				name={props.input.name}
				disabled={props.disabled}
				value={props.propValue}
				defaultChecked={props.defaultChecked}
				checked={`${currentValue}` === `${props.propValue}`}
				onChange={props.input.onChange}
				onFocus={props.input.onFocus}
				onBlur={props.input.onBlur}
				className={props.fieldClassName}
				theme={theme}
			/>
			<Errors show={showErrors}>{props.meta.error}</Errors>
		</div>
	);
};

/*
 * Example
 * name - form values key
 * propValue - value to assign to form {key: value}

 <Field
  name="gender"
  propValue="male"
  component={Radio}
  optionLabel="Male"
 />
 */
