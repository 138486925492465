import { PARAMS } from 'common/constants';

export function getRouteByPath<T extends string>(path: string, routes: T[]): T | undefined {
	function routeToRegex(route: string): RegExp {
		const pattern = route.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/:(\w+)/g, '([^/]+)');
		return new RegExp(`^${pattern}/?$`);
	}

	const matches = routes.filter(route => routeToRegex(route).test(path));
	return matches[0];
}

type RouteParams = Partial<Record<ValuesType<typeof PARAMS>, string | number>>;

/**
 * Generates a URL path by replacing route parameters with their corresponding values.
 *
 * @example
 * ```typescript
 * generatePath('/:collectionId/:dataId', { collectionId: '123', dataId: '456' });
 * // Returns: '/123/456'
 *
 * generatePath('https://example.com/api/:collectionId/:dataId/:cardId', {
 *   collectionId: '123',
 *   dataId: '456',
 *   cardId: '789'
 * });
 * // Returns: 'https://example.com/api/123/456/789'
 *
 * generatePath('/:collectionId/:dataId', { collectionId: '', dataId: null });
 * // Returns: '/:collectionId/:dataId' (no replacement for empty string or null)
 * ```
 */
export function generatePath(route: string, params: RouteParams): string {
	let path = route;
	for (const [key, value] of Object.entries(params)) {
		if (value !== '' && value !== null && value !== undefined) {
			const regex = new RegExp(`:${key}\\b`, 'g');
			path = path.replace(regex, `${value}`);
		}
	}
	return path;
}
