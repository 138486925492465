import React from 'react';
import { EditorState, Modifier } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { VariableDataType } from 'common/utils/variables/types';
import { AvailableVariablesArr, mentionComponentBody } from './MentionPlugin';
import { customColorPrefix } from './ColorPickerPlugin';

const styles = ['BOLD', 'ITALIC', 'UNDERLINE'];

export const removeEntities = (editorState: EditorState) => {
	const contentState = editorState.getCurrentContent();
	const contentWithoutEntities = Modifier.applyEntity(contentState, editorState.getSelection(), null);
	return EditorState.push(editorState, contentWithoutEntities, 'apply-entity');
};

export const removeInlineStyles = (editorState: EditorState) => {
	const contentState = editorState.getCurrentContent();
	const contentWithoutStyles = styles.reduce(
		(newContentState, style) => Modifier.removeInlineStyle(newContentState, editorState.getSelection(), style),
		contentState
	);
	return EditorState.push(editorState, contentWithoutStyles, 'change-inline-style');
};

// create rich editor state from provided html string (IN ->)
export function getStateFromHtml(markup: string, extra?: { variables?: AvailableVariablesArr }) {
	const blocksFromHTML = convertFromHTML({
		htmlToStyle: (nodeName, node, currentStyle) => {
			if (nodeName === 'span' && node.style.color) {
				return currentStyle.add(`${customColorPrefix}${node.style.color}`);
			}

			return currentStyle;
		},
		htmlToEntity: (nodeName, node, createEntity) => {
			if (nodeName === 'a') {
				return createEntity('LINK', 'MUTABLE', { url: node.href });
			}

			if (nodeName === 'span' && 'mention' in node.dataset) {
				const name = node.dataset.mention as string;
				const mention = extra?.variables?.find(v => v.name === name);
				/* `const mention` can be equal to `undefined` in case of:
				     1. variable points to a component or card that does not already exist
				     2. variable.name has been changed */
				return mention ? createEntity('$mention', 'IMMUTABLE', { mention }) : undefined;
			}

			return undefined;
		},
	})(markup);

	return EditorState.createWithContent(blocksFromHTML);
}

// get html string from rich editor state (OUT <-)
export function getHtmlFromState(editorState: EditorState) {
	return convertToHTML({
		// Entity styling:
		entityToHTML: (entity, originalText) => {
			switch (entity.type) {
				case 'LINK':
					return <a href={entity.data.url} target="_blank" rel="noopener noreferrer" />;
				case '$mention': // see MentionPlugin
					return mentionComponentBody({ mention: entity.data.mention as VariableDataType });
				default:
					return null;
			}
		},
		// Inline styles:
		styleToHTML(style) {
			if (style.startsWith(customColorPrefix)) {
				return <span style={{ color: style.split(customColorPrefix)[1] }} />;
			}

			return null;
		},
		// Block styles:
		blockToHTML(block) {
			if (block.type === 'unstyled' && (block.text === ' ' || block.text === '')) {
				return <br />;
			}

			return null;
		},
	})(editorState.getCurrentContent());
}
