import React, { ReactNode, FC } from 'react';
import cn from 'classnames';
import css from './ContentLayout.scss';

enum VIEW {
	main = 'main',
	story = 'story',
}

type Props = {
	className?: string;
	view?: VIEW;
	children: ReactNode;
};

const ContentLayout: FC<Props> = ({ className = '', view = VIEW.main, ...props }) => (
	<div className={cn(css.layout, css[`layout-${view}`], className)} {...props} />
);

export default ContentLayout;
