import type { AdminReducerState } from 'admin/reducers';
import t from 'utils/translate';
import { StoryFacade } from 'utils/facades/story-facade';
import { APPLY_STORY_VOLUNTARY_UPDATES } from 'admin/constants/actions';
import { toast } from 'admin/components/common/MessageContainer/toast';
import AdminError from 'admin/components/common/ErrorBoundary/AdminError';
import { StoryMigrationAdapter } from 'admin/utils/story-migration-adapter';
import { updateEditableStory } from 'admin/actions/story/update-editable-story';
import { setModal } from 'admin/actions/set-modal';
import { selectEditableStory } from 'admin/reducers/story-editor/selectors';

/**
 * Action to update story with a list of voluntaryUpdates
 */
export function applyStoryVoluntaryUpdates() {
	return async (dispatch, getState: () => AdminReducerState) => {
		dispatch({ type: APPLY_STORY_VOLUNTARY_UPDATES.START });
		const story = selectEditableStory(getState());

		try {
			if (!story) {
				throw new Error('story is not defined');
			}
			const migration = new StoryMigrationAdapter({ story });
			migration.storyVoluntaryUpdateList.forEach(update => update.apply());

			await dispatch(
				updateEditableStory({
					data: new StoryFacade(migration.story).story,
					updateForm: true,
				})
			);

			dispatch(setModal());

			toast.success(t('story.updatesModal.empty'));

			dispatch({ type: APPLY_STORY_VOLUNTARY_UPDATES.SUCCESS });
		} catch {
			dispatch({ type: APPLY_STORY_VOLUNTARY_UPDATES.FAIL });

			throw new AdminError(t('story.updatesModal.failed'));
		}
	};
}
