/* eslint-disable no-useless-escape */
import { generateId } from 'common/utils/generate-id';
import { ChildrenFormProp } from '../ActionForm';

type OuputVariable = NonNullable<ChildrenFormProp['formData']['outputs']>[number];
export function createOutputVariable(
	{ output, definition }: { output: string; definition: string } = { output: '', definition: '' }
): OuputVariable {
	return {
		id: generateId(8), // used for react's key only
		output,
		definition,
	};
}

export function markupToString(input: string) {
	return input
		.replace(/<[^>]+data-mention="([^"]+)"[^>]*>.*?<\/[^>]+>/g, '$$$1') // Replace mention tags with $value
		.replace(/<[^>]+>/g, '') // Remove all remaining HTML tags
		.trim(); // Remove extra spaces
}

export function stringToMarkup(input: string) {
	return `<p>${input.replace(/\$([\w\/\-]+)/g, '<span data-mention="$1">$1</span>')}</p>`;
}
