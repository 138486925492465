/* eslint-disable react/button-has-type */

import React, { FC, isValidElement, Ref } from 'react';
import cn from 'classnames';
import Loader from 'common/components/Loader';
import { Icon } from '../Icon';
import Text from '../Text';
import css from './Button.scss';

export type Props = {
	className?: string;
	disabled?: boolean;
	loading?: boolean;
	type?: 'button' | 'submit';
	refCb?: Ref<HTMLButtonElement>;
	children?: React.ReactNode | React.ReactNode[];
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	smallText?: boolean;
	textSize?: (typeof Text.size)[keyof typeof Text.size];
	textWeight?: (typeof Text.weight)[keyof typeof Text.weight];
	theme?: 'dark' | 'light';
	size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge' | 'giant' | 'auto';
	// content color
	color?: 'primary' | 'danger' | 'danger-ds' | 'white' | 'black-mid';
	// DOM node shape
	shape?: 'round' | 'circle';
	form?: string;
	/**
	 * Style preset
	 *
	 * @example
	 *  primary, danger, danger-ds, grey, white     : fill ✅ border ❌ size padding ✅
	 *  secondary*                                  : fill ❌ border ✅ size padding ✅
	 *  empty                                       : fill ❌ border ❌ size padding ✅
	 *  label-only                                  : fill ❌ border ❌ size padding ❌
	 */
	view?:
		| 'empty'
		| 'label-only'
		| 'primary'
		| 'danger'
		| 'danger-ds'
		| 'grey'
		| 'white'
		| 'secondary'
		| 'secondary-gray'
		| 'secondary-danger'
		| 'secondary-white';
} & React.HTMLAttributes<HTMLButtonElement>;

export const Button: FC<Props> = ({
	className = '',
	size = 'large',
	smallText = false,
	view = 'primary',
	theme = 'light',
	type = 'button',
	shape = 'round',
	color,
	onClick,
	disabled = false,
	children,
	refCb,
	textSize: textSizeProp,
	textWeight = Text.weight.semibold,
	loading = false,
	...rest
}) => {
	const isChildrenIcon = isValidElement(children) && typeof children.type === 'function' && children.type === Icon;
	let textSize: Props['textSize'] = smallText || size === 'medium' ? Text.size.label : Text.size.paragraph;
	if (textSizeProp) textSize = textSizeProp;

	return (
		<button
			className={cn(css.button, css[theme], css[view], css[size], css[`color-${color}`], css[shape], className)}
			disabled={disabled || loading}
			onClick={onClick}
			type={type}
			ref={refCb}
			{...rest}
		>
			{loading && <Loader size={size === 'tiny' ? size : 'small'} className={css.loading} color="currentColor" />}
			{isChildrenIcon ? (
				loading ? null : (
					children
				)
			) : (
				<Text className={css.label} weight={textWeight} size={textSize}>
					{children}
				</Text>
			)}
		</button>
	);
};

export default Button;
