import React, { useCallback, useRef } from 'react';
import cn from 'classnames';

import { visualCompareDataSelector } from './constants';
import { getClientCoords } from './helpers';

import css from './VisualCompare.scss';

type Props = {
	isEditableMode: boolean;
	visualCompareSrcA: () => React.ReactNode;
	visualCompareSrcB: () => React.ReactNode;
};

export const FadeMode = (props: Props) => {
	const { visualCompareSrcA, isEditableMode, visualCompareSrcB } = props;

	const containerRef = useRef<HTMLDivElement>(null);
	const sliderPointRef = useRef<HTMLDivElement>(null);
	const sliderWrapperRef = useRef<HTMLDivElement>(null);
	const contentBWrapperRef = useRef<HTMLDivElement>(null);
	const contentBContainerRef = useRef<HTMLDivElement>(null);

	const onMouseDownEvent = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
			e.stopPropagation();

			const container = containerRef?.current;
			const contentBWrapper = contentBWrapperRef.current;
			const sliderPoint = sliderPointRef.current;
			const sliderWrapper = sliderWrapperRef.current;
			const { clientX } = getClientCoords(e);

			if (!container || !contentBWrapper || !sliderWrapper || !sliderPoint) return;

			const sliderWrapperRect = sliderWrapper.getBoundingClientRect();
			const sliderPointPosition = parseInt(sliderPoint.style.left, 10) || sliderWrapperRect.width / 2;
			const startX = clientX - sliderPointPosition;

			const onMouseMoveEvent = (event: MouseEvent | TouchEvent) => {
				if (!sliderWrapper) return;

				const { clientX: nativeClientX } = getClientCoords(event);
				let newX = nativeClientX - startX;

				newX = Math.max(0, Math.min(newX, sliderWrapperRect.width));

				contentBWrapper.style.opacity = `${Math.max(0, (newX * 100) / sliderWrapperRect.width / 100)}`;
				sliderPoint.style.left = `${newX}px`;
			};

			const onMouseUpEvent = () => {
				document.removeEventListener('mousemove', onMouseMoveEvent);
				document.removeEventListener('mouseup', onMouseUpEvent);

				document.removeEventListener('touchmove', onMouseMoveEvent);
				document.removeEventListener('touchend', onMouseUpEvent);
			};

			document.addEventListener('mousemove', onMouseMoveEvent);
			document.addEventListener('mouseup', onMouseUpEvent);

			document.addEventListener('touchmove', onMouseMoveEvent);
			document.addEventListener('touchend', onMouseUpEvent);
		},
		[]
	);

	return (
		<div className={cn(css.visualCompareInner, css.fade)} ref={containerRef}>
			<div className={css.contentWrapper}>
				<div className={css.contentAContainer}>{visualCompareSrcA()}</div>
				<div className={css.contentBWrapper} ref={contentBWrapperRef}>
					<div className={css.contentBContainer} ref={contentBContainerRef}>
						{visualCompareSrcB()}
					</div>
				</div>
			</div>
			<div className={css.sliderWrapper} ref={sliderWrapperRef}>
				<div
					className={cn(css.sliderPoint, isEditableMode && css.noInteractive)}
					onMouseDown={!isEditableMode ? onMouseDownEvent : undefined}
					onTouchStart={!isEditableMode ? onMouseDownEvent : undefined}
					data-selector={visualCompareDataSelector.handleSize}
					ref={sliderPointRef}
				>
					<div
						className={css.sliderPointBg}
						data-selector={visualCompareDataSelector.handleFill}
						style={{ position: 'absolute' }}
					/>
				</div>
			</div>
		</div>
	);
};
