import _ from 'lodash';
import produce from 'immer';
import type { Action } from 'redux';
import type { BBModel, StoryVersionType } from 'types/story';
import { isLayerType } from 'utils/blocks/is-layer-type';
import { StoryFacade } from 'utils/facades/story-facade';
import { COMPONENT_TYPE as TYPE, STORY_ROOT_ELEMENT_INDEX } from 'common/constants';
import { AdminReducerState, AdminThunkAction } from 'admin/reducers';
import { CARD_EDITOR_SYNC } from 'admin/constants/actions';
import { storyEditorChange, storyEditorFormChange } from 'admin/actions/story/update-editable-story';
import { updateLatestStory } from 'admin/actions/story/update-latest-story';

type UpdateGlobalElementsParams = {
	storyElements: BBModel[];
	steps: StoryVersionType['data']['steps'];
};
/**
 * Change current story object, to update|add|remove all
 * story.data.elements ("uiConfig.layer: LAYER_TYPE.FIXED_GLOBAL"), with the latest changes which made in cardEditor
 */
const updateGlobalElements = ({ storyElements, steps }: UpdateGlobalElementsParams) => {
	// Walk through each element in card to find story element reference
	function loopElement(element: BBModel, index, elementsArray) {
		if (element.type !== TYPE.FLOAT_ABOVE && element.type !== TYPE.FLOAT_BELOW) {
			return false;
		}

		const STORY_ELEMENT_INDEX = STORY_ROOT_ELEMENT_INDEX[element.type];
		const storyChildren = storyElements[STORY_ELEMENT_INDEX]?.children;
		const children = element.children ?? [];

		if (!Array.isArray(storyChildren) || !Array.isArray(children)) {
			return false;
		}

		/**
		 * Update|Delete children of current card
		 *
		 * Find story element references and delete or update with a data from cardEditor reducer.
		 */
		children.forEach((elementChild, elementChildIdx, childrenArray) => {
			// find story element associated with the current element
			const storyElement = _.find(storyChildren, o => o._id === elementChild._id);
			if (storyElement) {
				/**
				 * Update reference with an element from "story.elements"
				 *
				 * If found story element, then replace it with a one from cardEditor reducer, because probably
				 * it could be changed in the card editor.
				 */
				elementChild = storyElement; // eslint-disable-line no-param-reassign
			} else if (isLayerType(elementChild).global) {
				/**
				 * Remove reference from card
				 *
				 * If it is story element reference, but associated element in storyChildren was not found,
				 * then probably such element was deleted or already is not a story element.
				 */
				childrenArray.splice(elementChildIdx, 1);
			}
		});

		/**
		 * Add missed story element references into current card
		 */
		storyChildren.forEach((elementChild, elementChildIdx, array) => {
			const hasReference = !!_.find(children, o => o._id === elementChild._id);
			if (!hasReference) {
				children.push(elementChild);
			}
		});

		return false;
	}

	// Admin - CardEditor - delete global element references
	function loopCard(card, index) {
		card.elements.forEach(loopElement);
	}

	function loopStep(step, index) {
		const { cards } = step;
		cards.forEach(loopCard);
	}

	steps.forEach(loopStep);
};

export const getMergeOfEditableStoryWithCardEditor = (
	editableStory: Exclude<AdminReducerState['storyEditor']['story'], null>,
	cardEditor: AdminReducerState['cardEditor']['present'],
	version: string
) => {
	return produce(editableStory, draft => {
		if (!draft.storyVersions[version]) {
			console.error('No such version in story');
			return;
		}

		const { data: cardData, storySettings, storyElements, symbols } = cardEditor;
		const cardInfo = StoryFacade.findCardInSteps(cardData?._id ?? '', draft.storyVersions[version].data.steps);
		const cardIndex = cardInfo.card?.index ?? -1;
		const stepIndex = cardInfo.step?.index ?? -1;
		const cards = draft.storyVersions[version].data.steps[stepIndex]?.cards;

		if (cardData && cards[cardIndex]?._id === cardData._id) {
			cards[cardIndex] = cardData;
		}

		if (storySettings) draft.storyVersions[version].settings = storySettings;
		if (storyElements) draft.storyVersions[version].data.elements = storyElements;
		if (symbols) draft.storyVersions[version].data.symbols = symbols;

		updateGlobalElements({ storyElements, steps: draft.storyVersions[version].data.steps });
	});
};

export function cardEditorSync(
	props: { updateStoryEditor?: boolean; skipLatestStoryUpdate?: boolean } = {}
): AdminThunkAction<Action, void> {
	return async (dispatch, getState) => {
		dispatch({ type: CARD_EDITOR_SYNC.SYNC });

		let error;

		try {
			const state = getState();
			const version = state.version.current;

			if (!state.storyEditor.story) {
				throw new Error(`"storyEditor.story" is null`);
			}

			const nextStory = getMergeOfEditableStoryWithCardEditor(
				state.storyEditor.story,
				state.cardEditor.present,
				version
			);
			const Story = new StoryFacade(nextStory, version);

			if (!props.skipLatestStoryUpdate) {
				dispatch(updateLatestStory({ ...Story.requestData, getStoryOnComplete: false }));
			}

			if (props.updateStoryEditor) {
				const path = `storyVersions.${version}`;
				const data = Story.story;
				await Promise.all([
					// update form.storyEditor
					dispatch(storyEditorFormChange({ path, data })),
					// update storyEditor
					dispatch(storyEditorChange({ path, data })),
				]);
			}
		} catch (err) {
			error = err;
			dispatch({ type: CARD_EDITOR_SYNC.FAIL, payload: err });
			console.error(err);
		}

		if (!error) {
			dispatch({ type: CARD_EDITOR_SYNC.SUCCESS });
		}
	};
}
