import React, { RefObject, useMemo, useRef } from 'react';
import cn from 'classnames';
import type { BBCommonProps, BBModel } from 'types/story';
import cmsUtils from 'common/utils/cms';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import type { TreeComponentProps } from 'client/components/common/BuildingBlocks/utils/traverse-tree-types';
import { useClientSelector } from 'client/reducers';
import { selectStorySymbols, selectStoryLang } from 'client/reducers/story/selectors';
import { traverseTree } from 'client/components/common/BuildingBlocks/utils/traverse-tree';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { ChildrenWithParentState } from 'client/components/common/BuildingBlocks/ChildrenWithParentState';
import { processRepeatableComponent } from './process-repeatable-component';
import { useData } from './use-data';
import css from './CmsRepeater.scss';

type Props = BBCommonProps;
type ReplicatorTreeContext = {
	// eslint-disable-next-line react/no-unused-prop-types
	traverseContext: TreeComponentProps['traverseContext'];
};

/**
 * * Behaviour
 *
 * * Editor:
 * - repeater with children rendered as it is... skip all the logic except data fetching
 *
 * * Preview|Published:
 * - repeater rendered without children at traverse tree
 * - repeater fetches data from API
 * - repeater replicates itself n-times
 * - repeater return traverse tree with N-replicated repeaters
 * - this time, traverse tree should render repeater as it is with a children
 * - these repeaters should be marked as "nested" to avoid an infinite replication loop
 *
 * * Components:
 * - <CmsRepeaterEditable />   - render children as it is
 * - <CmsRepeaterReplicator /> - fetch, replicate, no own DOM node, render traverse tree with N-replicated repeaters
 * - <CmsRepeaterReplica />    - render itself with children as it is,
 * */

const translations = {
	en: {
		loading: 'Loading...',
		noResults: 'No results..',
	},
	he: {
		loading: 'טוען...',
		noResults: 'אין תוצאות..',
	},
};

/**
 * Replicates original `CmsRepeater` n-times based on fetched data
 * and renders traverse-tree with N-replicated repeaters
 */
const CmsRepeaterReplicator: React.FC<Props & ReplicatorTreeContext> = props => {
	const { collectionId, items, isLoading } = useData({
		collectionId: props.uiConfig.componentProps.collectionId ?? '',
		pageSize: cmsUtils.getComponentProp(props, UNIFORM_PROPS.collectionPageSize),
		paginationTriggers: cmsUtils.getComponentProp(props, UNIFORM_PROPS.collectionPaginationTriggers),
		collectionFilters: cmsUtils.getComponentProp(props, UNIFORM_PROPS.collectionFilters),
	});

	const symbols = useClientSelector(selectStorySymbols);
	const lang = useClientSelector(selectStoryLang);
	const text: typeof translations.en = translations[lang.toLocaleLowerCase()] ?? translations.en;

	const propsRef = useRef(props);
	propsRef.current = props;

	const replicatedTree = useMemo(() => {
		const { traverseContext, ...currentProps } = propsRef.current;
		if (!collectionId || !items?.total || !traverseContext) {
			return null;
		}

		const { children, ...parentTreeContext } = traverseContext.mapChildrenProps ?? {};

		const buildingBlockModel: BBModel = {
			_id: currentProps._id,
			type: currentProps.type,
			uiConfig: currentProps.uiConfig,
			...('symbol' in currentProps ? { symbol: currentProps.symbol } : {}),
			children,
		};

		const { components: replicas } = processRepeatableComponent({
			component: buildingBlockModel,
			items: { [collectionId]: cmsUtils.transformItemsArrayToObject(items.items) },
			symbols,
		});

		return traverseTree({
			tree: replicas,
			options: { ...traverseContext.options, isNested: true },
			parentTreeContext: {
				// drop last index from every parent context field to omit original logical Repeater parent
				path: parentTreeContext.path.split('.').slice(0, -2).join('.'), // "1.children.0{{.children.0}}" 👈
				idPath: parentTreeContext.idPath.split('.').slice(0, -1).join('.'),
				parent: parentTreeContext.parent.slice(0, -1),
				cssParent: parentTreeContext.cssParent,
				isFFC: parentTreeContext.isFFC,
				parentIndexOffset: parseInt(parentTreeContext.path.split('.').at(-1) ?? '', 10) || 0,
			},
		});
	}, [collectionId, items?.items, items?.total, symbols]);

	if (!replicatedTree) {
		// Consider to design a loader and "empty state" placeholder
		return isLoading ? (
			<span className={css.loading}>{text.loading}</span>
		) : (
			<span className={css.noResult}>{text.noResults}</span>
		);
	}

	return replicatedTree;
};

type RepeaterBodyProps = Pick<
	Props,
	'uiConfig' | 'stateAttrs' | 'containerRef' | 'parentStates' | 'children' | 'editableModeProps' | 'eventListeners'
>;

const RepeaterBody: React.FC<RepeaterBodyProps> = ({ editableModeProps, uiConfig, ...props }) => (
	<div
		{...uiConfig.nodeProps}
		{...props.stateAttrs}
		{...props.eventListeners}
		{...editableModeProps?.nodeProps}
		style={uiConfig.nodeProps?.style}
		className={cn(css.cmsRepeater, uiConfig.nodeProps.className, editableModeProps?.nodeProps?.className)}
		ref={props.containerRef as RefObject<HTMLDivElement>}
	>
		<ChildrenWithParentState states={props.parentStates}>{props.children}</ChildrenWithParentState>
	</div>
);

const CmsRepeaterEditable: React.FC<Props> = props => {
	return (
		<RepeaterBody
			uiConfig={props.uiConfig}
			stateAttrs={props.stateAttrs}
			containerRef={props.containerRef}
			parentStates={props.parentStates}
			eventListeners={props.eventListeners}
			editableModeProps={props.editableModeProps}
		>
			{props.children}
		</RepeaterBody>
	);
};

const CmsRepeaterReplica: React.FC<Props> = props => {
	return (
		<RepeaterBody
			uiConfig={props.uiConfig}
			stateAttrs={props.stateAttrs}
			containerRef={props.containerRef}
			parentStates={props.parentStates}
			editableModeProps={props.editableModeProps}
		>
			{props.children}
		</RepeaterBody>
	);
};

// fixme: noticed that it does not get parent states, but it's children does
const CmsRepeater: React.FC<Props & ReplicatorTreeContext> = ({ traverseContext, ...props }) => {
	if (props.isEditableMode) {
		// fetch data, render children as it is
		return <CmsRepeaterEditable {...props} />;
	}

	if (traverseContext) {
		// fetch data, replicate, render traverse tree with N-replicated repeaters
		return <CmsRepeaterReplicator {...props} traverseContext={traverseContext} />;
	}

	// render itself with children as it is
	return <CmsRepeaterReplica {...props} />;
};

// todo: consider to wrap with enhancer only CmsRepeaterReplica and CmsRepeaterEditable
export default withCardTransitionContext(CmsRepeater);
